import Cookies from 'universal-cookie';
import LocalStorage from 'lib/storage/LocalStorage';
import Authentication from 'lib/auth/authentication';
import Env from 'lib/env/Env';
import LogoutOptions from 'lib/auth/LogoutOptions';
const cookies = new Cookies();

class AuthService {
  /**
   * Session collection
   * @type {Collection}
   * @private
   */
  _collection = LocalStorage.collection('session');

  /**
   * The session cookie name
   * @type {string}
   * @private
   */
  _sessionCookieName = 'atelliosession';

  /**
   * Authenticate
   * @param {string} strategy
   * @param {{username: string, password: string}} credentials
   * @returns {Promise<{resetPassword: bool, token: Object}>}
   * @throws
   */
  authenticate = async (strategy, credentials) => {
    if (strategy !== 'local') {
      throw new Error(
        'this method only supports the local authentication strategy'
      );
    }

    const { tokenString, token, resetPassword } =
      await Authentication.localAuthentication(
        credentials.username,
        credentials.password
      );

    // Fetch the workspaces
    token.workspaces = await this.fetchAccountWorkspaces(tokenString);
    token.tokenString = tokenString;
    this.token(token);

    return {
      token,
      resetPassword
    };
  };

  /**
   * Fetch the account workspaces
   * @param {string} tokenString
   * @returns {Promise<[]Object>}
   * @throws
   */
  async fetchAccountWorkspaces(tokenString) {
    const { data: workspaces } =
      await Authentication.getWorkspaces(tokenString);
    if (workspaces.length === 0) {
      throw new Error('account does not belong to any workspace');
    }
    return workspaces;
  }

  /**
   * Get or set the user auth token. Token is null if the user is not authenticated.
   * @param {Object} [token]
   * @returns {Object|null}
   */
  token(token) {
    if (token) {
      this._collection.remove().insert(token);
      cookies.set(
        this._sessionCookieName,
        token.tokenString,
        this._getSessionCookieOptions({
          expires: new Date(this._unixToUnixMillis(token.exp))
        })
      );
    } else {
      const activeToken = this._collection.findOne();

      // Check that the session has not expired. If it has removed it and
      // return null.
      if (activeToken && this._isBeforeNow(activeToken.exp)) {
        this.removeToken();
        return null;
      }

      // Ensure that we have the cookie token. The cookie token is used to
      // access the files API.
      const cookieToken = cookies.get(
        this._sessionCookieName,
        this._getSessionCookieOptions()
      );
      if (!cookieToken) {
        this.removeToken();
        return null;
      }

      return activeToken ? activeToken : null;
    }
  }

  /**
   * Returns the timestamp at which the token expires. Null returned if there is no token.
   * @returns {number|null} unix milli timestamp
   */
  tokenExpiry() {
    const session = this.token();
    return session ? this._unixToUnixMillis(session.exp) : null;
  }

  /**
   * Returns true if the user is authenticated
   * @returns {boolean}
   */
  isAuthenticated() {
    return this.token() !== null;
  }

  /**
   * Remove any existing auth token
   */
  removeToken() {
    LocalStorage.collection('session').remove();
    cookies.remove(this._sessionCookieName, this._getSessionCookieOptions());
  }

  /**
   * Get the auth method used by the user to login. Empty string is returned if
   * the user is not authenticated.
   * @returns {string}
   */
  authMethod() {
    const token = this.token();
    return token ? token.authMethod : '';
  }

  /**
   * Clean up storage and cookies. Navigates to logout page.
   * @param {LogoutOptions} [options]
   */
  logout = async (options = new LogoutOptions()) => {
    const token = this.token();
    const authMethod = token ? token.authMethod : '';
    const tokenString = token ? token.tokenString : '';
    this.removeToken();

    // Clear localstorage
    this._clearSensitiveOfflineStorage();
    if (options.clearPreferences) {
      this._clearPreferencesOfflineStorage();
    }

    if (tokenString) {
      console.log(`logging out from method: ${authMethod}`);
      try {
        const data = await Authentication.logout(tokenString);
        if (typeof data === 'string') {
          // If the logout API call returns a redirect URL, honour it
          window.location = data;
          return;
        }
        console.log('logout URL not returned, using default');
      } catch (err) {
        console.error(err);
      }

      if (options.redirect) {
        window.location.replace(`/farewell`);
      }
    }
  };

  /**
   * Clear sensitive offline storage data
   * @private
   */
  _clearSensitiveOfflineStorage = () => {
    LocalStorage.collection('session').remove();
    LocalStorage.collection('account').remove();
    LocalStorage.collection('settings').remove();
  };

  /**
   * Clear user preference offline storage data
   * @private
   */
  _clearPreferencesOfflineStorage = () => {
    window.localStorage.removeItem('Stitch.Query.Productions');
    window.localStorage.removeItem('Stitch.Query.Calendar.Productions');
    window.localStorage.removeItem('Stitch.Query.Callouts');
    window.localStorage.removeItem('Stitch.Query.Shortlists');
    window.localStorage.removeItem('Stitch.Query.Roster');
    window.localStorage.removeItem('Stitch.Query.Bookings');
    window.localStorage.removeItem('Stitch.Query.Callsheets');
    window.localStorage.removeItem('Stitch.Calendar.Settings');
    window.localStorage.removeItem('Stitch.Query.Calendar.Locations');
    window.localStorage.removeItem('Stitch.Query.Calendar.Resources');
    window.localStorage.removeItem('Stitch.Query.Duplicates');
    window.localStorage.removeItem('Stitch.Query.Tags');
    window.localStorage.removeItem('Atellio.ApplicantList.Layout');
    window.localStorage.removeItem('Atellio.DefaultStatusID');
  };

  /**
   * Build the cookie options for the session cookie.
   * @param {Object} [options]
   * @returns {Object}
   * @private
   */
  _getSessionCookieOptions = options => {
    const { hostname } = window.location;
    return {
      domain: Env.isDev ? import.meta.env.VITE_COOKIE_DOMAIN : '.' + hostname,
      path: '/',
      ...options
    };
  };

  /**
   * Returns true if the unix timestamp is before now. Converts the unix
   * timestamp into a unix milli to compare against Date.now().
   * @param {number} timestamp
   * @return {boolean}
   * @private
   */
  _isBeforeNow(timestamp) {
    return this._unixToUnixMillis(timestamp) <= Date.now();
  }

  /**
   * Returns a unix milli timestamp from a unix timestamp
   * @param timestamp
   * @return {number}
   * @private
   */
  _unixToUnixMillis(timestamp) {
    return timestamp * 1000;
  }
}

const instance = new AuthService();

// Add global hook
if (Env.nodeEnv !== 'production') {
  window.AuthService = instance;
}

export default instance;
