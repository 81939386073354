import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './CalendarDeletePopover.module.scss';

import { Popover, PopoverContent, PopoverTrigger } from 'v1/components/shared';

const CalendarDeletePopover = ({ id, trigger, handleDelete, label }) => (
  <Popover>
    <PopoverTrigger>{trigger}</PopoverTrigger>
    <PopoverContent>
      <div
        className={classnames(
          styles['CalendarDeletePopover__deleteAvailability'],
          'clickable',
          'inset-S'
        )}
        onClick={() => handleDelete(id)}
      >
        {label}
      </div>
    </PopoverContent>
  </Popover>
);

CalendarDeletePopover.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  handleDelete: PropTypes.func
};

export default CalendarDeletePopover;
