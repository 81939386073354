import i18n from 'i18next';
import { unitFieldUtils } from '../../v4/shared/components/forms/input/unit-field-utils.js';

const SOCIAL_FIELDS_MAPPING = {
  social_instagram: {
    label: 'Instagram',
    type: 'SOCIAL',
    maps_to: 'social_instagram',
    key: 'social_instagram'
  },
  social_twitter: {
    label: 'Twitter',
    type: 'SOCIAL',
    maps_to: 'social_twitter',
    key: 'social_twitter'
  },
  social_behance: {
    label: 'Behance',
    type: 'SOCIAL',
    maps_to: 'social_behance',
    key: 'social_behance'
  },
  social_youtube: {
    label: 'Youtube',
    type: 'SOCIAL',
    maps_to: 'social_youtube',
    key: 'social_youtube'
  },
  social_vimeo: {
    label: 'Vimeo',
    type: 'SOCIAL',
    maps_to: 'social_vimeo',
    key: 'social_vimeo'
  }
};

const CORE_FIELDS_MAPPING = {
  profile_picture: {
    label: 'Profile picture',
    maps_to: 'profile_picture',
    type: 'PROFILE_PICTURE',
    key: 'profile_picture'
  },
  full_name: {
    label: 'Full name',
    maps_to: 'full_name',
    type: 'SHORT_TEXT',
    key: 'full_name'
  },
  files: {
    label: 'Media uploader',
    type: 'FILES',
    maps_to: 'files',
    key: 'files'
  },
  role: {
    label: 'Role',
    maps_to: 'role',
    type: 'SHORT_TEXT',
    key: 'role'
  },
  websites: {
    label: 'Website',
    type: 'URL',
    maps_to: 'websites',
    key: 'websites'
  },
  emails: {
    label: 'Email',
    type: 'EMAIL',
    maps_to: 'emails',
    key: 'emails'
  },
  phone_numbers: {
    label: 'Phone number',
    type: 'PHONE_NUMBER',
    maps_to: 'phone_numbers',
    key: 'phone_numbers'
  },
  locations: {
    label: 'Location',
    type: 'LOCATION',
    maps_to: 'locations',
    key: 'locations'
  },
  rates: {
    label: 'Rate',
    type: 'CURRENCY',
    maps_to: 'rates',
    key: 'rates'
  },
  tags: {
    label: 'Tags',
    type: 'MULTI_SELECT',
    maps_to: 'tags',
    key: 'tags'
  }
};

/**
 * @function generateCalloutFieldMapper
 * Creates an object used throughout callouts to list the resource fields and provide the correct field data necessary to build the form.
 * This uses CORE_FIELDS_MAPPINGS to grab correct field data related to the selected resources fields and custom_field_definitions to get
 * the appropriate custom field data and generated appropriate field objects for those custom fields.
 *
 * @param {Array} fields - The metastructure fields found within a selected resource_type
 * @param {Array} custom_field_definitions - The list of availability custom fields on the system to grab custom field data for custom fields found within the resource type
 */
export const generateCalloutFieldMapper = (
  fields = [],
  custom_field_definitions
) => {
  const fieldMappings = fields.reduce((result, field) => {
    let map;
    let key;

    if (field.type === 'CUSTOM_FIELD') {
      const definition =
        custom_field_definitions.find(
          d => d.id === field.custom_field_definition_id
        ) ?? {};
      key = `custom_${definition.id}`;
      map = {
        label: i18n.t(`callouts:fieldMappings.${key}`, {
          defaultValue: definition.name
        }),
        type: definition.data_type,
        maps_to: key,
        options: definition.options || null,
        key
      };
      if (definition.data_type === 'MEASUREMENT_NEW') {
        map.default_value = unitFieldUtils.getUnitValueFromDisplayValue(
          0,
          definition.measurement_default_unit
        );
      }
    } else {
      if (field.name === 'socials') {
        const translatedSocials = Object.keys(SOCIAL_FIELDS_MAPPING).reduce(
          (acc, socialKey) => {
            acc[socialKey] = {
              ...SOCIAL_FIELDS_MAPPING[socialKey],
              label: i18n.t(`callouts:fieldMappings.${socialKey}`, {
                defaultValue: SOCIAL_FIELDS_MAPPING[socialKey].label
              })
            };
            return acc;
          },
          {}
        );
        return { ...result, ...translatedSocials };
      } else {
        key = field.name;
        map = { ...CORE_FIELDS_MAPPING[field.name] };
        map.label = i18n.t(`core:core_fields.${key}`, {
          defaultValue: map.label
        });
      }
    }

    // TODO: Handle custom field groups.
    return { ...result, [key]: map };
  }, {});

  return {
    ...fieldMappings,
    files: CORE_FIELDS_MAPPING['files'],
    media: CORE_FIELDS_MAPPING['media']
  };
};
