import { maybe } from '@passionware/monads';
import { z } from 'zod';

export const globalSearch$ = z
  .array(
    z.object({
      id: z.number(),
      name: z.string(),
      description: z
        .string()
        .nullish()
        .optional()
        .transform(maybe.getOrUndefined),
      url: z.string(),
      badge: z
        .object({
          text: z.string(),
          color: z.string().nullish().optional().transform(maybe.getOrUndefined)
        })
        .nullish()
        .optional()
        .transform(maybe.getOrUndefined),
      avatar: z
        .object({
          url: z.string().nullish().optional().transform(maybe.getOrUndefined),
          fallback: z
            .string()
            .nullish()
            .optional()
            .transform(maybe.getOrUndefined),
          ascii: z.string().nullish().optional().transform(maybe.getOrUndefined)
        })
        .nullish()
        .optional()
        .transform(maybe.getOrUndefined),
      group: z.string().nullish().optional().transform(maybe.getOrUndefined),
      shortcut: z.string().nullish().optional().transform(maybe.getOrUndefined)
    })
  )
  .catch(e => {
    console.warn('Error parsing globalSearch$', e);
    return [];
  });
