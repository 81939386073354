import { TailwindRoot } from "v4/shared/TailwindRoot";

const KeyboardShortcut = ({ shortcut, ...rest }: { shortcut: string }) => {
  return (
    <TailwindRoot>
      <kbd className="px-2 py-1 rounded-md color-white bg-white/10 text-xs pointer-events-none" {...rest}>
        {shortcut}
      </kbd>
    </TailwindRoot>
  );
};

export default KeyboardShortcut;
