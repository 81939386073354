import { Combobox, ComboboxOptionProps } from '@headlessui/react';
import classNames from 'classnames';

export function SearchPanelSelectOptionBase<T>({
  className,
  ...rest
}: ComboboxOptionProps<'div', T>) {
  return (
    <Combobox.Option
      className={bag =>
        classNames(
          'border border-gray-200 border-1 p-3 flex gap-2 items-center align-middle cursor-pointer',
          'first-of-type:rounded-t-md last-of-type:rounded-b-md [&:not(:last-child)]:border-b-0',
          {
            'bg-gray-100': bag.active
          },
          typeof className === 'function' ? className(bag) : className
        )
      }
      {...rest}
    />
  );
}
