import { Spinner } from '@easle/ui/spinner';
import { CheckIcon } from '@heroicons/react/20/solid/index';
import { mt, MutationData } from '@passionware/monads';
import { ReactNode } from 'react';
import { cn } from '../platform/dom/cn';
import InlineToast from './InlineToast';
import { renderSmallError } from './renderError';

export function renderSpinnerMutation(
  mutation: MutationData<unknown, unknown>,
  idle: ReactNode = null,
  options?: {
    spinnerClass?: string;
    successClass?: ReactNode;
  }
) {
  return mt
    .journey(mutation)
    .initially(() => idle)
    .during(<Spinner className={options?.spinnerClass} />)
    .catch(renderSmallError('w-4'))
    .done(() => (
      <InlineToast
        contentAfter={idle}
        delay={2000}
        className="flex flex-row items-center"
      >
        <CheckIcon
          className={cn('h-5 text-primary', options?.successClass)}
        />
      </InlineToast>
    ));
}
