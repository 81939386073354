import { maybe, Maybe } from '@passionware/monads';

export const SCalendarFeatures = [
  'search.text',
  'search.tags',
  'search.production-type',
  'search.production-template',
  'search.budget',
  'search.location',
  'search.owner',
  'search.custom-fields',
  'search.status'
] as const;

export type SCalendarFeature = (typeof SCalendarFeatures)[number];

export function isFeatureEnabled(
  features: Maybe<SCalendarFeature[]>,
  feature: SCalendarFeature
): boolean {
  return maybe.mapOrElse(
    features,
    // if feature array is passed, we check if it includes the feature
    features => features.includes(feature),
    // if feature array is not passed as array, we fall back to all options (legacy code)
    true
  );
}
