import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyGeneric } from 'v1/components/shared';
import { useTranslation } from 'react-i18next';

import styles from './ContactDetailsRoute.module.scss';

const ContactDetailsRoute = () => {
  const { request } = useSelector(state => state.availabilities);
  const { companyName } = request;
  const { t } = useTranslation('resource-portal');

  return (
    <section className={styles['ContactDetailsRoute']}>
      <EmptyGeneric
        className={styles['ContactDetailsRoute__emptyGeneric']}
        title={t('ContactDetailsRoute.comingSoon')}
        icon="/images/pancake_construction.png"
        iconWidth="50px"
      />
      <h2 className={styles['ContactDetailsRoute__description']}>
        {t('ContactDetailsRoute.description', { companyName })}
      </h2>
      <h3 className={styles['ContactDetailsRoute__details']}>
        {t('ContactDetailsRoute.details')}
      </h3>
    </section>
  );
};

export default ContactDetailsRoute;
