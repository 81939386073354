import { rd } from '@passionware/monads';
import { ArrivalListService } from './ArrivalListService';

export function createMockArrivalListService(): ArrivalListService {
  return {
    useArrivalList: () =>
      rd.of([
        {
          studio: 'Studio 1',
          landscapeUrl: 'https://via.placeholder.com/150',
          portraitUrl: 'https://via.placeholder.com/150'
        },
        {
          studio: 'Studio 2',
          landscapeUrl: 'https://via.placeholder.com/150',
          portraitUrl: 'https://via.placeholder.com/150'
        }
      ])
  };
}
