import { useHotkeys } from 'react-hotkeys-hook';

export function useFormSubmitHotkeys<T extends HTMLElement>(
  onSubmit: () => void
) {
  return useHotkeys<T>(
    ['ctrl+enter', 'meta+enter'],
    e => {
      e.preventDefault();
      onSubmit();
    },
    { enableOnFormTags: true }
  );
}
