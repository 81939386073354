import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkPrimaryProps {
  children: React.ReactNode;
  icon: React.ReactNode;
  to: string;
}

const SidebarLinkPrimary = ({
  children,
  to,
  icon
}: SidebarLinkPrimaryProps) => {
  return (
    // @ts-expect-error upgrade react-router-dom so react types match
    <NavLink
      to={to}
      className={isActive =>
        classNames(
          'rounded-md w-full text-white/80 flex items-center text-left hover:text-white/100 p-2 block rounded-md hover:bg-eggplant-lighter/30 block focus:outline-none focus:text-white/100 focus:ring-2 focus:ring-eggplant-lightest/60',
          { 'bg-eggplant-lighter/60 text-white/100': isActive }
        )
      }
    >
      <span className="flex-0 mr-3">{icon}</span>
      <span className="flex-1 truncate">{children}</span>
    </NavLink>
  );
};

export default SidebarLinkPrimary;
