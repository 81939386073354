import { ReactNode } from 'react';
import { TailwindRoot } from 'v4/shared/TailwindRoot';
import { z, ZodError, ZodType } from 'zod';

export class SchemaError extends Error {
  innerError: ZodError;
  data: unknown;
  constructor(innerError: ZodError, data: unknown) {
    super(innerError.message);
    this.name = 'SchemaError';
    this.innerError = innerError;
    this.data = data;
  }
}

export function parseWithDecoratedError<T extends ZodType<any>>(
  schema: T,
  data: any
): z.infer<T> {
  try {
    return schema.parse(data);
  } catch (error) {
    if (error instanceof ZodError) {
      throw new SchemaError(error, data);
    }
    throw error;
  }
}

export interface ZodErrorWrapperProps<T> {
  error: T;
  children: (error: T) => ReactNode;
}

export function SchemaErrorWrapper<T>(props: ZodErrorWrapperProps<T>) {
  const { error, children } = props;

  if (!(error instanceof SchemaError)) {
    return children(error);
  }
  return (
    <TailwindRoot>
      <div className="p-3 flex gap-4 items-center justify-center">
        <div>Schema error</div>
        <button
          onClick={() => {
            console.error(
              'Schema validation error',
              error.data,
              error.innerError
            );
          }}
          className="font-bold transition-all rounded p-3 bg-red-400 hover:bg-red-700 focus-visible:ring text-white text-left text-md"
        >
          log schema error
        </button>
      </div>
    </TailwindRoot>
  );
}
