import classNames from 'classnames';
import { HTMLProps } from 'react';
import { useTranslation } from 'react-i18next';

export interface NoResultsProps extends HTMLProps<HTMLDivElement> {}

export function SelectOptionEmpty({ className, ...rest }: NoResultsProps) {
  const { t } = useTranslation('v4_shared');
  return (
    <div
      className={classNames(
        'relative cursor-default select-none py-2 pl-3 text-gray-600',
        className
      )}
      {...rest}
    >
      {t('SelectOptionEmpty.noResults')}
    </div>
  );
}
