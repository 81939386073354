import { Account, Capability, FeatureFlag, ResourceType } from '__types__';
import AuthService from 'lib/auth/AuthService';
import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import { useState } from 'react';
import { Icon } from 'shared';
import { SandboxIndicator } from 'v4/core/sidebar/components/SandboxIndicator';
import ScrollableContainer from 'v4/shared/components/ScrollableContainer/ScrollableContainer';
import { NO_CAPABILITIES } from '../../../lib/restrictedAccess/accessCheckers';
import Separated from '../../shared/components/Separated';
import { withinApp } from '../appRoutes';
import SidebarAccordion from './components/SidebarAccordion';
import SidebarAccountDropdown from './components/SidebarAccountDropdown';
import SidebarHelpMenu from './components/SidebarHelpMenu';
import SidebarLinkPrimary from './components/SidebarLinkPrimary';
import SidebarLinkSecondary from './components/SidebarLinkSecondary';
import SidebarRoot from './components/SidebarRoot';
import SidebarSection from './components/SidebarSection';
import SidebarSeparator from './components/SidebarSeparator';
import CommandbarButton from '../commandbarButton/commandbarButton';
import {
  createSidebarGlobalCreate,
  SidebarGlobalCreateConfig
} from './components/SidebarGlobalCreate';

export interface SidebarConfig {
  useRestrictedAccess: typeof useRestrictedAccessCallback;
  useAuth: () => typeof AuthService;
  useAppId: () => string;
  useAccount: () => Account | undefined;
  useBrandImage: () => string;
  useCompanyName: () => string;
  useResourceTypes: () => ResourceType[];
  useIsSandbox: () => boolean;
  useOnSandboxInfoShow: () => () => void;
  sidebarGlobalCreateConfig: SidebarGlobalCreateConfig;
}

export function createSidebar(config: SidebarConfig) {
  const SidebarGlobalCreate = createSidebarGlobalCreate(
    config.sidebarGlobalCreateConfig
  );
  return function Sidebar() {
    const [resourcesMenuExpanded, setResourcesMenuExpanded] = useState(false);
    const [productionsMenuExpanded, setProductionsMenuExpanded] =
      useState(false);
    const [shortlistsMenuExpanded, setShortlistsMenuExpanded] = useState(false);
    const [bookingsMenuExpanded, setBookingsMenuExpanded] = useState(false);

    const can = config.useRestrictedAccess();
    const auth = config.useAuth();
    const appId = config.useAppId();
    const account = config.useAccount();
    const brandImage = config.useBrandImage();
    const companyName = config.useCompanyName();
    const resourceTypes = config.useResourceTypes();
    const isSandbox = config.useIsSandbox();
    const onSandboxInfoShow = config.useOnSandboxInfoShow();

    const to = withinApp(appId);

    const canCreateAtLeastOne = can(null, null, [Capability.RESOURCE_CREATE, Capability.PRODUCTION_CREATE, Capability.SHORTLIST_CREATE, Capability.BOOKING_CREATE, Capability.CALLSHEET_CREATE, Capability.CALLOUT_CREATE, Capability.DOCUSIGN_SEND])

    return (
      <SidebarRoot>
        <SidebarSection>
          <SidebarAccountDropdown
            brandImage={brandImage}
            companyName={companyName}
            account={account}
            appId={appId}
            onLogout={auth.logout}
          />
        </SidebarSection>
        {isSandbox && (
          <SidebarSection>
            <SandboxIndicator onClick={onSandboxInfoShow} />
          </SidebarSection>
        )}
        <SidebarSection className="my-0">
          <CommandbarButton />
          {canCreateAtLeastOne && <SidebarGlobalCreate />}
        </SidebarSection>
        <SidebarSection>
          <SidebarLinkPrimary
            icon={<Icon name="v4_home" />}
            to={to.dashboard()}
          >
            Dashboard
          </SidebarLinkPrimary>
        </SidebarSection>
        <SidebarSeparator />
        <ScrollableContainer rgbColor="20, 13, 92" className="flex-1 p-4">
          <Separated divider={<SidebarSeparator className="my-4" />}>
            <Separated.Group>
              {can(FeatureFlag.RESOURCES, Capability.RESOURCE_READ) && (
                <>
                  <SidebarAccordion
                    onToggle={() => {
                      setResourcesMenuExpanded(prev => !prev);
                    }}
                    isExpanded={resourcesMenuExpanded}
                    icon={<Icon name="v4_resource" color="white" />}
                    label="Resources"
                  >
                    {resourceTypes.map(resourceType => (
                      <SidebarLinkSecondary
                        key={resourceType.id}
                        to={to.resources.item(resourceType.id)}
                      >
                        {resourceType.name}
                      </SidebarLinkSecondary>
                    ))}
                  </SidebarAccordion>
                  <SidebarLinkPrimary
                    icon={<Icon name="v4_calendar" />}
                    to={to.calendar.resources.home()}
                  >
                    Resource calendar
                  </SidebarLinkPrimary>
                </>
              )}
              {can(FeatureFlag.CALLOUTS, Capability.CALLOUT_READ) && (
                <SidebarLinkPrimary
                  icon={<Icon name="v4_callout" />}
                  to={to.callouts.home()}
                >
                  Callouts
                </SidebarLinkPrimary>
              )}
            </Separated.Group>
            <Separated.Group>
              {can(FeatureFlag.SHORTLISTS, Capability.SHORTLIST_READ) && (
                <>
                  <SidebarAccordion
                    onToggle={() => {
                      setShortlistsMenuExpanded(prev => !prev);
                    }}
                    isExpanded={shortlistsMenuExpanded}
                    icon={<Icon name="v4_shortlist" />}
                    label="Shortlists"
                  >
                    <SidebarLinkSecondary to={to.shortlists.home()}>
                      All
                    </SidebarLinkSecondary>
                  </SidebarAccordion>
                </>
              )}
            </Separated.Group>
            <Separated.Group>
              {can(FeatureFlag.PRODUCTIONS, Capability.PRODUCTION_READ) && (
                <>
                  <SidebarAccordion
                    onToggle={() => {
                      setProductionsMenuExpanded(prev => !prev);
                    }}
                    isExpanded={productionsMenuExpanded}
                    icon={<Icon name="v4_project" />}
                    label="Productions"
                  >
                    <SidebarLinkSecondary to={to.productions.home()}>
                      All
                    </SidebarLinkSecondary>
                  </SidebarAccordion>
                  <SidebarLinkPrimary
                    icon={<Icon name="v4_calendar" />}
                    to={to.calendar.productions.home()}
                  >
                    Production calendar
                  </SidebarLinkPrimary>
                </>
              )}
              {can(FeatureFlag.CALLSHEETS, Capability.CALLSHEET_READ) && (
                <SidebarLinkPrimary
                  icon={<Icon name="v4_callsheet" />}
                  to={to.callsheets.home()}
                >
                  Callsheets
                </SidebarLinkPrimary>
              )}
            </Separated.Group>
            <Separated.Group>
              {can(FeatureFlag.BOOKINGS, Capability.BOOKING_READ) && (
                <>
                  <SidebarAccordion
                    onToggle={() => {
                      setBookingsMenuExpanded(prev => !prev);
                    }}
                    isExpanded={bookingsMenuExpanded}
                    icon={<Icon name="v4_booking" />}
                    label="Bookings"
                  >
                    <SidebarLinkSecondary to={to.bookings.home()}>
                      All
                    </SidebarLinkSecondary>
                  </SidebarAccordion>
                </>
              )}
            </Separated.Group>
            <Separated.Group>
              {can(FeatureFlag.NIKECUSTOM_FRONT_DESK, NO_CAPABILITIES) && (
                <SidebarLinkPrimary
                  icon={<Icon name="v4_frontdesk" />}
                  to={to.frontdesk.home()}
                >
                  Front desk
                </SidebarLinkPrimary>
              )}
              {can(FeatureFlag.RECONCILIATIONS, NO_CAPABILITIES) && (
                <SidebarLinkPrimary
                  icon={<Icon name="v4_reconciliation" />}
                  to={to.reconciliations.home()}
                >
                  Reconciliation
                </SidebarLinkPrimary>
              )}
              {can(FeatureFlag.REPORTING, Capability.REPORT_DOWNLOAD) && (
                <SidebarLinkPrimary
                  icon={<Icon name="v4_report" />}
                  to={to.reporting.home()}
                >
                  Reporting
                </SidebarLinkPrimary>
              )}
            </Separated.Group>
          </Separated>
        </ScrollableContainer>
        <SidebarSeparator />
        <SidebarSection>
          <SidebarHelpMenu />
        </SidebarSection>
      </SidebarRoot>
    );
  };
}
