import classNames from 'classnames';

export function SelectOptionError({ message }: { message?: string }) {
  return (
    <div
      className={classNames(
        'relative cursor-default select-none py-2 pl-3 text-red-600'
      )}
    >
      {message ?? 'Error'}
    </div>
  );
}
