import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { get } from 'lodash';
import { Link, withRouter } from 'react-router-dom';
import { Form } from 'react-form';

import { getTeamAccounts } from 'store/accounts';
import { getTeamUserGroups } from 'store/userGroups';
import { getAccountTeams } from 'store/teams';

import { ErrorMessage, PressStud, TextInputField } from 'v1/components/shared';

import Account from 'lib/auth/Account';
import AuthService from 'lib/auth/AuthService';
import withAuth from 'v1/hocs/withAuth';
import { getIntegrations } from 'store/integrations';

import { IntercomAPI } from 'react-intercom/dist/lib/react-intercom';

const UsernamePassword = ({ actions, history, className }) => {
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = async form => {
    try {
      const { username, password } = form;

      // Validate form
      if (!username) {
        return setErrorMessage('Username cannot be empty');
      }
      if (!password) {
        return setErrorMessage('Password cannot be empty');
      }

      const credentials = {
        username,
        password
      };
      const { token, resetPassword } = await AuthService.authenticate(
        'local',
        credentials
      );
      if (!token) {
        console.error('expected token after login');
        setErrorMessage('Something went wrong');
        return;
      }

      const workspaces = Account.workspaces();
      if (workspaces.length === 0) {
        setErrorMessage('This account does not belong to a workspace');
        return;
      }

      actions.loadAuth(token, token.tokenString, null);
      dispatch(getTeamAccounts());
      dispatch(getTeamUserGroups());
      dispatch(getAccountTeams());
      dispatch(getIntegrations());

      // TODO: Allow the user to select which workspace to navigate to. For now, get the first workspace.
      const workspace = Account.resolveFirstWorkspace();
      if (resetPassword) {
        history.replace(`/app/${workspace.db_schema}/onboard`, {
          resetPassword: true
        });
      } else {
        history.replace(`/app/${workspace.db_schema}/dashboard`);
      }
    } catch (err) {
      console.error(err);
      const message =
        get(err, 'response.body.error.message') || 'Something went wrong';
      setErrorMessage(message);
    }
  };
  return (
    <div className={classNames('row LoginFormGroup', className)}>
      <div className="col-sm-12">
        <Form onSubmit={handleSubmit}>
          {formApi => (
            <form onSubmit={formApi.submitForm}>
              <div className="row">
                <div className="col-sm-12">
                  <div className="form-group">
                    <label className="LoginFormLabel">
                      Enter your email address and password:
                    </label>
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <TextInputField
                      field="username"
                      placeholder="you@example.com"
                      className="LoginFormInput"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <TextInputField
                      type="password"
                      field="password"
                      placeholder="password"
                      className="LoginFormInput"
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <p className="text-right">
                    <Link to="/recover_password">Forgotten password?</Link>
                  </p>
                </div>
                {errorMessage ? (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <ErrorMessage>
                        {errorMessage}
                        <br />
                        <br />
                        <div className="text-13-600 text-neutral">
                          Having trouble logging in?{' '}
                          <a
                            onClick={() => {
                              IntercomAPI('show');
                            }}
                            className=""
                          >
                            Chat to one of our team members
                          </a>
                        </div>
                      </ErrorMessage>
                    </div>
                  </div>
                ) : null}
                <div className="col-sm-12">
                  <div className="form-group">
                    <PressStud
                      label="Log into your account"
                      appearance="primary"
                      action="submit"
                    />
                  </div>
                </div>
              </div>
            </form>
          )}
        </Form>
      </div>
    </div>
  );
};

UsernamePassword.defaultProps = {};

UsernamePassword.propTypes = {
  actions: PropTypes.object,
  history: PropTypes.object,
  className: PropTypes.string
};

export default withRouter(withAuth(UsernamePassword));
