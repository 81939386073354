"use client";
import {
  Avatar as ArkAvatar,
  type AvatarRootProps as ArkAvatarProps
} from '@ark-ui/react/avatar';
import classNames from 'classnames';

export interface AvatarProps extends ArkAvatarProps {
  src?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  fallback?: string;
}

// These are the current sizings for the application which fall outide the default tailwind sizes hence they are fixed
const avatarSizes = {
  xs: 'w-[12px] h-[12px]',
  sm: 'w-[15px] h-[15px]',
  md: 'w-[30px] h-[30px]',
  lg: 'w-[38px] h-[38px]',
  xl: 'w-[50px] h-[50px]'
};

const Avatar = ({
  src,
  size = 'md',
  fallback,
  className,
  ...rest
}: AvatarProps) => {
  return (
    <ArkAvatar.Root
      className={classNames(
        'rounded-full overflow-hidden relative !block',
        avatarSizes[size],
        className
      )}
      {...rest}
    >
      <ArkAvatar.Fallback className="absolute w-full h-full flex font-bold items-center justify-center text-xs uppercase text-eggplant grid-none">
        {fallback}
      </ArkAvatar.Fallback>
      <ArkAvatar.Image className="absolute w-full h-full" src={src} alt="avatar" />
    </ArkAvatar.Root>
  );
};

export default Avatar;
