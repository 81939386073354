import store from '../../../../store';
import { displayAlert } from '../../../../store/v1/ui/ui.actions';
import { ToastService } from './ToastService';

export function createToastService(): ToastService {
  return {
    open: options => {
      store.dispatch(displayAlert(options.type, options.message));
    }
  };
}
