import React from 'react';
import KeyboardShortcut from 'v4/shared/components/KeyboardShortcut/KeyboardShortcut';

interface SidebarButtonProps {
  children: React.ReactNode;
  keyboardShortcut?: string;
  icon: React.ReactNode;
};

const SidebarButton = ({ icon, children, keyboardShortcut, ...rest }: SidebarButtonProps & React.AnchorHTMLAttributes<HTMLAnchorElement>) => {
  return (
    <a className="rounded-md w-full text-white/80 flex items-center text-left hover:text-white/100 p-2 block rounded-md hover:bg-eggplant-lighter/30 block focus:outline-none focus:text-white/100 focus:ring-2 focus:ring-eggplant-lightest/60 data-[highlighted]:ring-2 data-[highlighted]:ring-eggplant-lightest/60" {...rest}>
      <span className="flex-0 mr-3">{icon}</span>
      <span className="flex-1 truncate">{children}</span>
      {keyboardShortcut && (
        <span className="flex-0 ml-3"><KeyboardShortcut shortcut={keyboardShortcut} /></span>
      )}
    </a>
  );
};

export default SidebarButton;
