import { cf } from '@easle/component-factory';
import { cn } from '../platform/dom/cn';

export type Layout = 'fixed-size' | 'fit-content';

export const PopoverPrimitive = cf.div<{
  layout?: Layout;
}>(
  {
    className: ({ layout = 'fixed-size', className }) =>
      cn(
        'z-10 flex flex-col items-stretch overflow-auto shadow-md rounded-lg bg-white border border-border-muted',
        'dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400',
        'focus:outline-none focus:shadow-md',
        layout === 'fixed-size' && 'h-[400px] w-[250px]',
        className
      )
  },
  ['layout']
);
