import customHelper from 'v1/helpers/customFieldHelper';
import { displayCurrency, shortenInterval } from 'v1/helpers/currencyHelper';
import {
  displayDimension,
  displayHeight,
  displayMeasurement
} from 'v1/helpers/measurements/measurementsHelper';
import { displayDateRange } from 'v1/helpers/byType/dateHelper';
import { FeatureFlag } from '__types__';

import moment from 'moment-timezone';

import find from 'lodash/find';
import get from 'lodash/get';
import keys from 'lodash/keys';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';
import { has } from 'lodash';
import { CapabilityEnum } from 'dtos/constants/capability.enum';
import { unitFieldUtils } from '../../v4/shared/components/forms/input/unit-field-utils.js';
import { maybe } from '@passionware/monads';

/**
 *
 * @param {Object} contact the contact data
 * @param {Integer} definitionId the custom definition id to match against
 * @returns {Object}
 */
const getCustomField = (contact, definitionId) => {
  return find(
    contact.custom_fields,
    custom => custom.custom_field_definition_id === definitionId
  );
};

/**
 * Please note: this function is highly specific to the Nike SOW, which
 * is why the custom definition ids are hard-coded.
 * @param {Object} contact - The contact data
 * @returns {String} a formatted QR string specific for the Nike SOW
 */
export const formatNikeShortlistQRString = (
  contact,
  custom_field_definitions = []
) => {
  const getCustomQRStringValue = (c, definitionId) => {
    const field = find(custom_field_definitions, d => d.id === definitionId);
    const definition = field ? returnShortlistCustomDefinitionMap(field) : null;
    const value = definition
      ? definition.getPrettyValue(definition.getValue(c))
      : null;
    return value;
  };

  const sections = {
    first_name: c => c.first_name,
    last_name: c => c.last_name,
    groups: c => (c.groups || []).map(t => t.name).join(', '),
    gender: c => getCustomQRStringValue(c, 7),
    bust: c => getCustomQRStringValue(c, 13),
    waist: c => getCustomQRStringValue(c, 37),
    height: c => getCustomQRStringValue(c, 12),
    shoe: c => getCustomQRStringValue(c, 14),
    bra_size: c => getCustomQRStringValue(c, 63),
    guid: c => c.guid
  };

  const qrString = keys(sections)
    .map(s => `${sections[s](contact) || 'null'}`)
    .join('_');

  return qrString;
};

const FIXED_FIELDS_MAPPING = {
  social_instagram: {
    label: 'Instagram',
    type: 'URL',
    getValue: contact => {
      const social =
        contact.socials &&
        find(contact.socials, s => s.value_2 === 'instagram');
      return social ? social.value_1 : null;
    },
    getPrettyValue: value => `@${value}`,
    getUrl: value => `https://www.instagram.com/${value}`
  },
  social_twitter: {
    label: 'Twitter',
    type: 'URL',
    getValue: contact => {
      const social =
        contact.socials && find(contact.socials, s => s.value_2 === 'twitter');
      return social ? social.value_1 : null;
    },
    getPrettyValue: value => `@${value}`,
    getUrl: value => `https://www.twitter.com/${value}`
  },
  social_behance: {
    label: 'Behance',
    type: 'URL',
    getValue: contact => {
      const social =
        contact.socials && find(contact.socials, s => s.value_2 === 'behance');
      return social ? social.value_1 : null;
    },
    getUrl: value => `https://www.behance.net/${value}`
  },
  social_youtube: {
    label: 'Youtube',
    type: 'URL',
    getValue: contact => {
      const social =
        contact.socials &&
        find(contact.socials, s => s.value_2 === 'instagram');
      return social ? social.value_1 : null;
    },
    getUrl: value => `https://www.youtube.com/user/${value}`
  },
  social_vimeo: {
    label: 'Vimeo',
    type: 'URL',
    getValue: contact => {
      const social =
        contact.socials && find(contact.socials, s => s.value_2 === 'vimeo');
      return social ? social.value_1 : null;
    },
    getUrl: value => `https://www.vimeo.com/${value}`
  },
  websites: {
    label: 'Website',
    type: 'URL',
    getValue: contact =>
      contact.websites && contact.websites.length
        ? contact.websites[0].value_1
        : null,
    getUrl: value => value
  },
  emails: {
    label: 'Email',
    type: 'EMAIL',
    getValue: contact =>
      contact.emails && contact.emails.length ? contact.emails[0].value_1 : null
  },
  phone_numbers: {
    label: 'Phone number',
    type: 'TEXT',
    getValue: contact =>
      contact.phone_numbers && contact.phone_numbers.length
        ? contact.phone_numbers[0].value_1
        : null
  },
  locations: {
    label: 'Location',
    type: 'TEXT',
    getValue: contact =>
      contact.locations && contact.locations.length
        ? contact.locations[0].name
        : null
  },
  rates: {
    label: 'Rate',
    type: 'CURRENCY',
    getValue: (contact, rates = []) => {
      const rate = get(contact, ['rates', 0]);

      // FOR RATE_ID: First check if contact.rates object has an internal rate card object (which is returned from public shortlist) if not use the rates store for logged in users
      return rate && rate.rate_id
        ? get(rate, 'rate') || get(rates, ['data', rate.rate_id])
        : rate;
    },
    getPrettyValue: value =>
      value
        ? `${displayCurrency(value.amount, value.currency)}${
            value.interval ? ' / ' : ''
          }${shortenInterval(value.interval) || ''}`
        : ''
  },
  tags: {
    label: 'Tags',
    type: 'TEXT',
    getValue: contact => contact.tags,
    getPrettyValue: value => (value ? value.map(t => t.tag).join(', ') : '')
  },
  organisation: {
    label: 'Organization',
    type: 'RESOURCE',
    getValue: contact => contact.organisation_id || contact.organisation,
    getPrettyValue: contact => get(contact, 'organisation.full_name')
  },
  represented_by: {
    label: 'Agent',
    type: 'RESOURCE',
    getValue: contact => contact.represented_by_id || contact.represented_by,
    getPrettyValue: contact => get(contact, 'represented_by.full_name')
  },
  groups: {
    label: 'Groups',
    type: 'TEXT',
    getValue: contact => (!isEmpty(contact.groups) ? contact.groups : null),
    getPrettyValue: value => (value ? value.map(t => t.name).join(', ') : null)
  },
  qr_code: {
    label: 'QR Code',
    type: 'QR_CODE',
    getValue: contact => contact,
    getPrettyValue: (value, custom_field_definitions) =>
      formatNikeShortlistQRString(value, custom_field_definitions),
    flag: FeatureFlag.NIKECUSTOM_QR_CODE,
    capabilities: CapabilityEnum.CONFIGURE_CUSTOM_NIKE_QR_CODE
  }
};

const returnShortlistCustomDefinitionMap = definition => {
  if (!definition) return null;
  switch (definition.data_type) {
    case 'URL':
      return {
        label: definition.name,
        type: 'URL',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          return custom ? custom.string_value : null;
        },
        getUrl: value => value || ''
      };
    case 'DATE_RANGE':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          return custom ? custom.string_value : null;
        },
        getPrettyValue: value => {
          return displayDateRange(value);
        }
      };
    case 'SHORT_TEXT':
    case 'LONG_TEXT':
    case 'NUMBER':
    case 'HEIGHT':
    case 'PERCENTAGE':
    case 'CURRENCY':
    case 'DATE':
    case 'BOOLEAN':
    default:
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          return custom
            ? customHelper.getValue(custom, definition.data_type)
            : null;
        },
        getPrettyValue: value => {
          if (definition.data_type === 'CURRENCY')
            return displayCurrency(value.amount, value.currency);
          if (definition.data_type === 'DATE')
            return moment(value).format('Do MMM YYYY');
          if (definition.data_type === 'BOOLEAN') return value ? 'Yes' : 'No';
          if (definition.data_type === 'PERCENTAGE') return value; // TODO: removed the '%' for now because existing customers use this for values with decimal places
          if (definition.data_type === 'HEIGHT') return displayHeight(value);

          return value;
        }
      };
    case 'DIMENSION_2D':
    case 'DIMENSION_3D':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          const rawValue = custom
            ? customHelper.getValue(custom, definition.data_type)
            : null;

          const value = displayDimension(rawValue);
          return value && value !== '' ? value : null;
        },
        getPrettyValue: value => {
          return value;
        }
      };
    case 'MEASUREMENT':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          const rawValue = custom
            ? customHelper.getValue(custom, definition.data_type)
            : null;

          return rawValue && rawValue.amount ? rawValue : null;
        },
        getPrettyValue: value => {
          return displayMeasurement(value);
        }
      };
    case 'MEASUREMENT_NEW':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          const rawValue = custom
            ? customHelper.getValue(custom, definition.data_type)
            : null;

          return rawValue &&
            maybe.isPresent(rawValue.amount) &&
            maybe.isPresent(rawValue.unit)
            ? unitFieldUtils.formatDisplay(
                unitFieldUtils.getUnitValueFromImperial(
                  rawValue.amount,
                  rawValue.unit
                ),
                rawValue.unit
              )
            : null;
        },
        getPrettyValue: value => {
          return String(value);
        }
      };
    case 'SINGLE_SELECT':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          return custom ? custom.option_id : null;
        },
        getPrettyValue: value => {
          if (!value) return '';
          const option = find(definition.options, o => o.id === value);
          return option ? option.value : '';
        }
      };
    case 'MULTI_SELECT':
      return {
        label: definition.name,
        type: 'TEXT',
        getValue: contact => {
          const custom = getCustomField(contact, definition.id);
          return custom ? custom.multi_select_ids : null;
        },
        getPrettyValue: value => {
          if (!value || !value.length) return '';
          const options = value
            .map(id => {
              const opt = find(definition.options, o => o.id === id);
              return opt ? opt.value : null;
            })
            .filter(name => name);
          return options.length ? options.join(', ') : '';
        }
      };
  }
};

/**
 *
 * @param {Array} resourceTypes
 * @returns {Array} unique array of allowed fields based on the passed in resource types
 */
export const getAllResourceTypeFields = (resourceTypes = []) => {
  const availableTypes = flatten(
    resourceTypes
      .filter(type => type && type.metastructure && type.metastructure.fields)
      .map(type => get(type, 'metastructure.fields'))
  );
  return uniqBy(
    availableTypes,
    field => field.custom_field_definition_id || field.name
  );
};

export const generateShortlistsFieldMapper = (
  fields = [],
  custom_field_definitions
) => {
  return fields.reduce((result, key) => {
    let field;
    if (typeof key === 'string') {
      if (!fields[key]?.active) return result;
      field = key;
    } else {
      if (has(key, 'active') && !key?.active) return result; // Todo: we pass through the active flag from from the resourcesFields which is needed to display the correct fields within ShortlistFieldManager
      field = key.name || `custom_${key.custom_field_definition_id}`;
    }
    if (!key.custom_field_definition_id) return result;

    const definition = find(
      custom_field_definitions,
      d => d.id === key.custom_field_definition_id
    );
    if (!definition) return result;

    const map = returnShortlistCustomDefinitionMap(definition);
    return { ...result, [field]: map };
  }, FIXED_FIELDS_MAPPING);
};
