import { Combobox, ComboboxProps } from '@headlessui/react';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline/index';
import classNames from 'classnames';
import React, {
  forwardRef,
  ReactNode,
  useEffect,
  useRef,
  useState
} from 'react';
import { Modify } from 'v4/shared/components/forms/types'; // todo elevate

export interface SearchPanelConfig<T, Id, ExtraProps> {
  useOptions: (
    query: string,
    extraProps: ExtraProps
  ) => { children: ReactNode; summary: ReactNode };
  displayValue?: (value: T) => string;
  idValue: (value: T) => Id;
}

export type SearchPanelProps<T> = Omit<
  Modify<
    ComboboxProps<T, true, false, 'div'>,
    {
      placeholder?: string;
      className?: string;
    }
  >,
  'multiple' | 'defaultValue' | 'nullable'
> & { tmp_workaround_focus_after_animation?: boolean };

export function createSearchPanel<T, Id, ExtraProps = {}>(
  config: SearchPanelConfig<T, Id, ExtraProps>
) {
  return forwardRef<HTMLElement, SearchPanelProps<T> & ExtraProps>(
    function SearchPanel(props, ref) {
      const {
        value,
        onChange,
        onBlur,
        onFocus,
        className,
        placeholder = 'Search...',
        id,
        autoFocus
      } = props;

      const [query, setQuery] = useState('');
      const { children, summary } = config.useOptions(
        query,
        props as ExtraProps
      );

      // this temporary workaround, remove it after removing containing popover animation + focus + scroll issue
      const inputRef = useRef<HTMLInputElement>(null);
      useEffect(() => {
        if (props.tmp_workaround_focus_after_animation) {
          setTimeout(() => {
            inputRef.current?.focus();
          }, 350);
        }
      }, []);
      return (
        <Combobox
          ref={ref}
          as="div"
          value={value}
          onChange={onChange}
          className={classNames(className)}
          onFocus={onFocus}
          onBlur={onBlur}
          nullable
        >
          <div className="flex flex-col gap-4">
            <div className="relative rounded-md shadow-xs">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <MagnifyingGlassIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
              <Combobox.Input<T | undefined | null>
                autoFocus={autoFocus}
                ref={inputRef}
                autoComplete="off"
                className="block w-full rounded-md border border-gray-400 ring-1 ring-gray-300 focus:ring-gray-400 py-1.5 pl-10 text-gray-900  placeholder:text-gray-400 "
                onChange={event => setQuery(event.target.value)}
                displayValue={item =>
                  item !== null && item !== undefined
                    ? config.displayValue?.(item) ?? ''
                    : ''
                }
                placeholder={placeholder}
                id={id}
              />
            </div>
            {summary && <div className="">{summary}</div>}
            {children && (
              <Combobox.Options className="m-0" static>
                {children}
              </Combobox.Options>
            )}
          </div>
        </Combobox>
      );
    }
  );
}
