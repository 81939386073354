import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FileUploadingItem from './FileUploadingItem/FileUploadingItem';
import { MiniLoader } from 'v1/components/shared';
import Dropzone from 'react-dropzone';
import s3 from 'lib/s3/s3Helper';
import sanitize from 'sanitize-filename';
import classnames from 'classnames';
import { v4 } from 'uuid';
import { withTranslation } from 'react-i18next';

import './FileUploader.scss';

class FileUploader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      files: []
    };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { reset } = this.props;

    if (reset !== nextProps.reset) {
      this.setState({ files: [], uploading: false });
    }
  }

  componentDidUpdate() {
    const { files, batchCompleted } = this.state;

    if (
      files &&
      files.length &&
      files.every(f => f.complete) &&
      !batchCompleted
    ) {
      const files = this.state.files;
      this.setState(
        { uploading: false, batchCompleted: true },
        () => this.props.onComplete && this.props.onComplete(files)
      );
    }
  }

  sanitizeFilename = filename => {
    return sanitize(filename.trim().replace(/\s+/g, ''));
  };

  onUploadProgress = file => {
    return progress => {
      this.setState(
        prev => ({
          files: prev.files.map(f => {
            if (f.id !== file.id) return f;
            return { ...f, uploading: true, progress };
          })
        }),
        () =>
          this.props.onChange &&
          this.props.onChange(this.state.files, 'PROGRESS')
      );
    };
  };

  onUploadError = file => {
    return err => {
      this.setState(
        prev => ({
          files: prev.files.map(f => {
            if (f.id !== file.id) return f;
            return {
              ...f,
              uploading: false,
              complete: true,
              error: err,
              progress: 0
            };
          })
        }),
        () =>
          this.props.onChange && this.props.onChange(this.state.files, 'ERROR')
      );
    };
  };

  onUploadFinish = file => {
    return url => {
      this.setState(
        prev => ({
          files: prev.files.map(f => {
            if (f.id !== file.id) return f;
            const completedFile = {
              ...f,
              url,
              file_id: file.file_id,
              uploading: false,
              complete: true,
              success: true,
              progress: 100
            };

            this.props.onFileCompleted &&
              this.props.onFileCompleted(completedFile);
            return completedFile;
          })
        }),
        () => {
          this.props.onChange &&
            this.props.onChange(this.state.files, 'COMPLETE');
        }
      );
    };
  };

  onDrop = drops => {
    const files = drops.map(raw => ({
      id: v4(),
      raw,
      name: raw.name,
      uploading: true,
      progress: 10
    }));

    this.setState({ files, uploading: true, batchCompleted: false }, () => {
      this.props.onDrop && this.props.onDrop(files);

      files.map(file =>
        this.getS3UploadUrlForFile(file.raw, (err, result) => {
          if (err) {
            console.error(err);
            return;
          }

          const { url, fileId, accessUrl } = result.data;
          const file_to_submit = {
            ...file,
            file_id: fileId,
            url,
            name: this.sanitizeFilename(file.name)
          };
          s3.uploadFile({
            uploadTo: url,
            accessUrl: accessUrl,
            file: file_to_submit.raw,
            onComplete: this.onUploadFinish(file_to_submit),
            onError: this.onUploadError(file_to_submit),
            onProgress: this.onUploadProgress(file_to_submit),
            secure: this.props.secure
          });
        })
      );
    });
  };

  onDropRejected = (yo, e, err) => {
    alert(this.props.t('FileUploader.maxFileUploadSize'));
  };

  getS3UploadUrlForFile = (file, callback) => {
    if (!file) return;

    const fileName = this.sanitizeFilename(file.name);
    let extension = fileName.split('.').pop().toLowerCase();
    extension = extension === 'jpeg' ? 'jpg' : extension;

    s3.getUrl({
      content_type: file.type ? file.type : 'application/*',
      extension: extension,
      name: fileName,
      secure: this.props.secure
    })
      .then(result => {
        if (result && result.data) {
          callback(null, result);
        } else {
          callback({ message: 'URL FAILED TO RETURN.' }, null);
        }
      })
      .catch(error => callback(error, null));
  };

  renderLoadingState = () => {
    if (this.props.renderLoading) return this.props.renderLoading();

    return (
      <div className="FileUploader-uploading">
        <h5>{this.props.t('FileUploader.uploading')}</h5>
        <MiniLoader isLoading={this.state.uploading}>
          {this.props.t('FileUploader.complete')}
        </MiniLoader>
      </div>
    );
  };

  renderContent() {
    if (this.props.hideContent) return null;
    if (this.props.renderContent) return this.props.renderContent();

    return (
      <div className="FileUploader-defaultState">
        <img
          src="/images/icon_type_upload_cloud.svg"
          className="FileUploader-icon"
          alt=""
        />
        <span className="FileUploader-label">
          {this.props.t('FileUploader.dragFilesHereOr')}{' '}
          <span className="FileUploader-label-click">
            {this.props.t('FileUploader.clickToUpload')}
          </span>
        </span>
        {this.props.label && (
          <span className="text-neutral">{this.props.label}</span>
        )}
      </div>
    );
  }

  renderUploadingFiles = () => {
    const { action } = this.props;
    const { files } = this.state;

    if (!files) return null;

    return (
      <div className="FileUploader-uploadingList">
        {files.map((file, index) => (
          <FileUploadingItem key={index} file={file} action={action} />
        ))}
      </div>
    );
  };

  render() {
    const { uploaderClass, className } = this.props;

    return (
      <div
        className={classnames(
          'FileUploader',
          className,
          this.state.uploading && 'uploading'
        )}
      >
        {this.state.uploading ? (
          this.renderLoadingState()
        ) : (
          <Dropzone
            className={classnames('FileUploader-uploader', uploaderClass)}
            onDrop={this.onDrop}
            onDropRejected={this.onDropRejected}
            multiple={this.props.multiple}
            accept={this.props.accepts || null}
            maxSize={this.props.maxSize || Infinity}
          >
            {this.renderContent()}
          </Dropzone>
        )}
        {this.props.multiple ? this.renderUploadingFiles() : null}
      </div>
    );
  }
}

FileUploader.defaultProps = {
  secure: true
};

FileUploader.propTypes = {
  // Function
  secure: PropTypes.bool,
  multiple: PropTypes.bool,
  accepts: PropTypes.string, // File extension
  maxSize: PropTypes.number, // In bytes
  action: PropTypes.shape({
    className: PropTypes.string,
    text: PropTypes.string,
    onClick: PropTypes.func // TODO: arguments
  }), // TODO: type - passed to FileUploadingItem
  reset: PropTypes.bool,

  // Presentation
  className: PropTypes.string,
  uploaderClass: PropTypes.string,
  hideContent: PropTypes.bool,

  // Action
  onDrop: PropTypes.func,
  onChange: PropTypes.func,
  onComplete: PropTypes.func,
  onFileCompleted: PropTypes.func,
  renderLoading: PropTypes.func,
  renderContent: PropTypes.func
};

export default withTranslation('v1_shared')(FileUploader);
