import React from 'react';
import PropTypes from 'prop-types';
import SYSTEM_COLOURS from 'v1/helpers/consts/SYSTEM_COLOURS';

import './MiniLoader.scss';
import { LoadingSpinner } from '../../../../../v5/design-sytem/LoadingSpinner';

const MiniLoader = ({ isLoading, light, children }) =>
  isLoading ? (
    <LoadingSpinner
      className="MiniLoader"
      color={light ? SYSTEM_COLOURS.WHITE : SYSTEM_COLOURS.BLACK}
    />
  ) : (
    children
  );

MiniLoader.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  light: PropTypes.bool,
  children: PropTypes.any
};

export default MiniLoader;
