import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';

import Env from 'lib/env/Env';
import ApiClient from 'lib/api/ApiClient';
import clientMiddleware from './clientMiddleware';
import reducer from './reducer';
import { createQueryCacheReduxMiddleware } from '~/v4/migration/queryCacheReduxMiddleware';
import { queryClient } from '~/v4/migration/queryClientSingleton';
import { myActionAsEventMiddleware } from '../v4/migration/actionAsEventMiddleware.connected';

const client = new ApiClient();

const loggerMiddleware = createLogger({
  predicate: (_, action) => Env.isDev && action.type !== 'WIPE_EVENT',
  collapsed: true,
  diff: true,
  duration: true
});

// NOTE: we can't use the serializability middleware yet because we use promises in our action creators
// NOTE: we can't use the immutabiliity middleware yet because we have so many issues that it's hard to work

// TODO: remove this when we can use the serializability check middleware
// TODO: add this back in when we can use the immutabiliity check middleware
// const immutableStateInvariant = createImmutableStateInvariantMiddleware();

const createStore = (initialState = {}) =>
  configureStore({
    reducer,

    // TODO: add this back in when we can use the serializability check middleware
    // middleware: getDefaultMiddleware =>
    //   getDefaultMiddleware().concat(clientMiddleware(client), loggerMiddleware),

    // TODO: remove this when we can use the serializability check middleware
    middleware: [
      thunk,

      // TODO: add this back in when we can use the immutabiliity check middleware
      // immutableStateInvariant,

      clientMiddleware(client),
      createQueryCacheReduxMiddleware(queryClient), // todo v4 migration bridge
      loggerMiddleware,
      // after all v1 stuff is processed, we want to emit an event for the v4,v5 code
      myActionAsEventMiddleware
    ],
    preloadedState: initialState
  });

export default createStore;
