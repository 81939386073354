import { z } from 'zod';

// export const externalEvent$ = z.discriminatedUnion('type', [
//   z.object({
//     type: z.literal('CHECKIN_CREATED')
//   }),
//   z.object({
//     type: z.literal('CHECKIN_UPDATED')
//   })
// ]);

export const externalEvent$ = z.object({
  type: z.enum([
    // until we need to read event specific data, we keep it simple
    'CHECKIN_CREATED',
    'CHECKIN_UPDATED',
    'BOOKING_ARCHIVED',
    'BOOKING_CANCELLED',
    'BOOKING_UPDATED',
    'PRODUCTION_ARCHIVED',
    'PRODUCTION_CREATED',
    'PRODUCTION_UPDATED'
  ])
});
