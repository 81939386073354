import { createSimpleEvent } from '@passionware/simple-event';
import { SimpleStore } from '@passionware/simple-store';
import { Socket } from 'socket.io';
import { ExternalEvent, ExternalEventApi } from './external-event.api';
import { externalEvent$ } from './external-event.api.websocket.schema';

export function createExternalEventApi(
  socketInstanceStore: SimpleStore<Socket | null>
): ExternalEventApi {
  socketInstanceStore.addUpdateListener(socketInstance => {
    if (socketInstance === null) {
      return;
    }
    socketInstance.on('EVENT', (data: unknown) => {
      const parsed = externalEvent$.safeParse(data);
      if (parsed.success) {
        dataEvent.emit(parsed.data);
      } else {
        errorEvent.emit(parsed.error);
      }
    });
    socketInstance.on('error', (error: Error) => {
      errorEvent.emit(error);
    });
  });
  let dataEvent = createSimpleEvent<ExternalEvent>();
  let errorEvent = createSimpleEvent<Error>();
  return {
    data: dataEvent,
    error: errorEvent,
    // todo - adjust to websocket.io
    isAlive: () => socketInstanceStore.getCurrentValue()?.connected ?? false,
    ensureAlive: () => Promise.resolve() // todo - adjust to websocket.io
  };
}
