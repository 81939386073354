/**
 * Returns a list of callout templates, already translated to the language that the form is about to be displayed in.
 * Since the user can edit all labels, it's important that we translate the labels before allowing the user to edit them,
 * not when displaying the form, as we do with the rest of the app.
 */
const getCalloutTemplates = t => [
  {
    id: 0,
    name: t('templates.basicCallout.name'),
    description: t('templates.basicCallout.description'),
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: t('templates.basicCallout.mappings.full_name.label'),
          maps_to: 'full_name',
          required: true,
          description: t(
            'templates.basicCallout.mappings.full_name.description'
          ),
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: t('templates.basicCallout.mappings.emails.label'),
          maps_to: 'emails',
          required: true,
          description: t('templates.basicCallout.mappings.emails.description'),
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: t('templates.basicCallout.mappings.phone_number.label'),
          maps_to: 'phone_numbers',
          required: false,
          description: t(
            'templates.basicCallout.mappings.phone_number.description'
          ),
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: t('templates.basicCallout.mappings.websites.label'),
          maps_to: 'websites',
          required: true,
          description: t(
            'templates.basicCallout.mappings.websites.description'
          ),
          order: 3
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: t('templates.basicCallout.mappings.locations.label'),
          maps_to: 'locations',
          required: false,
          description: t(
            'templates.basicCallout.mappings.locations.description'
          ),
          order: 4
        }
      ]
    }
  },
  {
    id: 1,
    name: t('templates.portfolioCallout.name'),
    description: t('templates.portfolioCallout.description'),
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: t('templates.portfolioCallout.mappings.full_name.label'),
          maps_to: 'full_name',
          required: true,
          description: t(
            'templates.portfolioCallout.mappings.full_name.description'
          ),
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: t('templates.portfolioCallout.mappings.emails.label'),
          maps_to: 'emails',
          required: true,
          description: t(
            'templates.portfolioCallout.mappings.emails.description'
          ),
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: t('templates.portfolioCallout.mappings.phone_number.label'),
          maps_to: 'phone_numbers',
          required: false,
          description: t(
            'templates.portfolioCallout.mappings.phone_number.description'
          ),
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: t('templates.portfolioCallout.mappings.websites.label'),
          maps_to: 'websites',
          required: true,
          description: t(
            'templates.portfolioCallout.mappings.websites.description'
          ),
          order: 3
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: t('templates.portfolioCallout.mappings.locations.label'),
          maps_to: 'locations',
          required: false,
          description: t(
            'templates.portfolioCallout.mappings.locations.description'
          ),
          order: 4
        },
        {
          key: 'files',
          type: 'FILES',
          label: t('templates.portfolioCallout.mappings.files.label'),
          maps_to: 'files',
          required: false,
          description: t(
            'templates.portfolioCallout.mappings.files.description'
          ),
          order: 5
        }
      ]
    }
  },
  {
    id: 2,
    name: t('templates.modelCallout.name'),
    description: t('templates.modelCallout.description'),
    template_data: {
      mappings: [
        {
          key: 'full_name',
          type: 'SHORT_TEXT',
          label: t('templates.modelCallout.mappings.full_name.label'),
          maps_to: 'full_name',
          required: true,
          description: t(
            'templates.modelCallout.mappings.full_name.description'
          ),
          order: 0
        },
        {
          key: 'emails',
          type: 'EMAIL',
          label: t('templates.modelCallout.mappings.emails.label'),
          maps_to: 'emails',
          required: true,
          description: t('templates.modelCallout.mappings.emails.description'),
          order: 1
        },
        {
          key: 'phone_number',
          type: 'PHONE_NUMBER',
          label: t('templates.modelCallout.mappings.phone_number.label'),
          maps_to: 'phone_numbers',
          required: false,
          description: t(
            'templates.modelCallout.mappings.phone_number.description'
          ),
          order: 2
        },
        {
          key: 'websites',
          type: 'URL',
          label: t('templates.modelCallout.mappings.websites.label'),
          maps_to: 'websites',
          required: true,
          description: t(
            'templates.modelCallout.mappings.websites.description'
          ),
          order: 3
        },
        {
          key: 'social_instagram',
          type: 'SOCIAL',
          label: t('templates.modelCallout.mappings.social_instagram.label'),
          maps_to: 'social_instagram',
          required: false,
          description: t(
            'templates.modelCallout.mappings.social_instagram.description'
          ),
          order: 4
        },
        {
          key: 'locations',
          type: 'LOCATION',
          label: t('templates.modelCallout.mappings.locations.label'),
          maps_to: 'locations',
          required: false,
          description: t(
            'templates.modelCallout.mappings.locations.description'
          ),
          order: 5
        },
        {
          key: 'files',
          type: 'FILES',
          label: t('templates.modelCallout.mappings.files.label'),
          maps_to: 'files',
          required: false,
          description: t('templates.modelCallout.mappings.files.description'),
          order: 6
        }
      ]
    }
  }
];

export default getCalloutTemplates;
