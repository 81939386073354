import { createSimpleStore } from '@passionware/simple-store';
import i18n from 'i18next';
import { get } from 'lodash';
import { useSyncExternalStore } from 'react';
import store from '../../../store';
import { Locale, LocaleService } from './LocaleService';

export function createLocaleService(): LocaleService {
  const locale = createSimpleStore<Locale>(i18n.language as Locale);
  i18n.on('languageChanged', newLocale => {
    locale.setNewValue(newLocale as Locale);
  });

  return {
    useCurrentLocale: () =>
      useSyncExternalStore(locale.addUpdateListener, locale.getCurrentValue),
    changeCurrentLocale: newLocale => {
      i18n.changeLanguage(newLocale);
      const serverLanguage = get(
        store.getState(),
        'auth.settings.settings.defaults_locale.setting'
      );
      if (serverLanguage) {
        // server language is loaded, we can set local storage against it
        if (serverLanguage === newLocale) {
          // we are setting the same language as server language, remove from local storage, so it will now use server language all the time
          localStorage.removeItem('atl.language');
        } else {
          // user has changed the language, set it in local storage, so it will now use user selected language
          localStorage.setItem('atl.language', newLocale);
        }
      } else {
        // server language is not loaded, set it in local storage for future use
        localStorage.setItem('atl.language', newLocale);
      }
    }
  };
}
