import { maybe } from '@passionware/monads';
import { createSimpleEvent } from '@passionware/simple-event';
import { createSimpleStore, useSimpleStore } from '@passionware/simple-store';
import { QueryClient, useQuery } from '@tanstack/react-query';
import {
  GlobalSearchApi,
  GlobalSearchEntry
} from '../../api/global-search/global-search.api';
import { ensureIdleQuery } from '../../platform/ensureIdleQuery';
import { ReadWriteApi } from '../../platform/ts/readWriteApi';
import { GlobalSearchService } from './GlobalSearchService';

export function createGlobalSearchService(
  api: GlobalSearchApi,
  recentsApi: ReadWriteApi<GlobalSearchEntry[]>,
  queryClient: QueryClient,
  maxRecentSearches = 5
): GlobalSearchService {
  const openEvent = createSimpleEvent();
  const recents = createSimpleStore<GlobalSearchEntry[]>([]);
  async function boot() {
    const recentSearches = await recentsApi.load();
    recents.setNewValue(recentSearches);
  }

  boot();
  return {
    useSearch: query => {
      return ensureIdleQuery(
        query,
        useQuery(
          {
            enabled: maybe.isPresent(query),
            queryKey: ['global-search', query],
            queryFn: context => api.search(query!, context.signal)
          },
          queryClient
        )
      );
    },
    storeRecentSearchItem: item => {
      recents.setNewValue(prev => {
        const newRecents = [item, ...prev.filter(i => i.id !== item.id)].slice(
          0,
          maxRecentSearches
        );
        recentsApi.save(newRecents);
        return newRecents;
      });
    },
    useRecentSearches: () => {
      return useSimpleStore(recents);
    },
    openSearchEvent: openEvent
  };
}
