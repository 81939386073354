import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  DURATION_TYPES,
  DURATION_DEFAULTS
} from 'v1/helpers/byModel/EventHelper';

import styles from './CalendarInputPopover.module.scss';

import {
  EventHoursInputOptions,
  Label,
  Popover,
  PopoverContent,
  PopoverTrigger
} from 'v1/components/shared';

// v2 of the api uses camelCase
const keyToFieldMapper = {
  estimated_daily_hours: 'estimatedDailyHours',
  estimated_daily_minutes: 'estimatedDailyMinutes',
  estimated_start_time: 'estimatedStartTime',
  estimated_end_time: 'estimatedEndTime'
};

/* 
  Used to update a value for the first key 
  and retrieve and set the second key value from state or a default
*/
const genericSetTupleValues = (key1, key2, value, defaultValue, state) => {
  let obj = { [key1]: value };

  if (state) {
    obj = {
      ...obj,
      [key2]: key2 in state ? state[key2] : defaultValue
    };
  }

  return obj;
};

const CalendarInputPopover = ({
  id,
  title,
  handleChange,
  handleDelete,
  deleteLabel,
  durationType,
  estimatedDailyHours,
  estimatedDailyMinutes,
  estimatedStartTime,
  estimatedEndTime
}) => {
  const [currentDurationType, setCurrentDurationType] = useState(durationType);
  const [updatedDuration, setUpdatedDuration] = useState(undefined);
  const [onPopoverClosing, setOnPopoverClosing] = useState(false);

  const onEventChange = (field, value) => {
    let tempUpdate = {};
    // date_type means that the radiogroup's option has changed
    if (field === 'date_type') {
      // setCurrentDurationType sets the state of the radio button group
      setCurrentDurationType(value);

      // but default changes are also possible, so optimistically set the defaults
      if (value === DURATION_TYPES.RECURRING_SPECIFIC_HOURS) {
        tempUpdate = {
          dateType: value,
          estimatedStartTime:
            estimatedStartTime ||
            (updatedDuration && updatedDuration['estimatedStartTime']) ||
            DURATION_DEFAULTS.ESTIMATED_START_TIME,
          estimatedEndTime:
            estimatedEndTime ||
            (updatedDuration && updatedDuration['estimatedEndTime']) ||
            DURATION_DEFAULTS.ESTIMATED_END_TIME
        };
      } else if (value === DURATION_TYPES.RECURRING_HOURS) {
        tempUpdate = {
          dateType: value,
          estimatedDailyHours:
            estimatedDailyHours ||
            (updatedDuration && updatedDuration['estimatedDailyHours']) ||
            DURATION_DEFAULTS.ESTIMATED_DAILY_HOURS,
          estimatedDailyMinutes:
            estimatedDailyMinutes ||
            (updatedDuration && updatedDuration['estimatedDailyMinutes']) ||
            DURATION_DEFAULTS.ESTIMATED_DAILY_MINUTES
        };
      }
      setUpdatedDuration(tempUpdate);
    } else {
      /* the change belongs to one of the input fields.
        This means that the fields update individually so duration tuples,
        Recurring Specific Hours - { estimated start time, estimated end time}
        Recurring Hours - { estimated daily hours, estimated daily minutes }
        needs to pass along both values to ensure the event is updated correctly
      */
      let update = {};
      if (currentDurationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS) {
        switch (field) {
          case 'estimated_start_time':
            update = genericSetTupleValues(
              keyToFieldMapper[field],
              'estimatedEndTime',
              value,
              DURATION_DEFAULTS.ESTIMATED_END_TIME,
              updatedDuration
            );
            setUpdatedDuration(update);
            break;
          case 'estimated_end_time':
            update = genericSetTupleValues(
              keyToFieldMapper[field],
              'estimatedStartTime',
              value,
              DURATION_DEFAULTS.ESTIMATED_START_TIME,
              updatedDuration
            );
            setUpdatedDuration(update);
            break;
          default:
            break;
        }
      } else if (currentDurationType === DURATION_TYPES.RECURRING_HOURS) {
        switch (field) {
          case 'estimated_daily_hours':
            update = genericSetTupleValues(
              keyToFieldMapper[field],
              'estimatedDailyMinutes',
              value,
              DURATION_DEFAULTS.ESTIMATED_DAILY_MINUTES,
              updatedDuration
            );
            setUpdatedDuration(update);
            break;
          case 'estimated_daily_minutes':
            update = genericSetTupleValues(
              keyToFieldMapper[field],
              'estimatedDailyHours',
              value,
              DURATION_DEFAULTS.ESTIMATED_DAILY_HOURS,
              updatedDuration
            );
            setUpdatedDuration(update);
            break;
          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    let mounted = true;

    if (mounted && onPopoverClosing) {
      handleChange(id, currentDurationType, updatedDuration);
    }

    return () => (mounted = false);
  }, [onPopoverClosing]);

  const handleOnPopoverClose = () => {
    setOnPopoverClosing(true);
  };

  return (
    <Popover onPopoverClose={handleOnPopoverClose}>
      <PopoverTrigger>{title}</PopoverTrigger>
      <PopoverContent
        className={styles['CalendarInputPopover__popoverContent']}
      >
        <EventHoursInputOptions
          handleChange={onEventChange}
          durationType={currentDurationType}
          estimatedDailyHours={estimatedDailyHours}
          estimatedDailyMinutes={estimatedDailyMinutes}
          estimatedStartTime={estimatedStartTime}
          estimatedEndTime={estimatedEndTime}
        />
        <div
          className={classnames(
            styles['CalendarInputPopover__deleteAvailability'],
            'clickable',
            'inset-S'
          )}
          onClick={() => handleDelete(id)}
        >
          {deleteLabel}
        </div>
      </PopoverContent>
    </Popover>
  );
};

CalendarInputPopover.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string,
  handleChange: PropTypes.func,
  handleDelete: PropTypes.func,
  dateType: PropTypes.oneOf([
    DURATION_TYPES.RECURRING_DEFAULT,
    DURATION_TYPES.RECURRING_HOURS,
    DURATION_TYPES.RECURRING_SPECIFIC_HOURS
  ]),
  estimatedDailyHours: PropTypes.string,
  estimatedDailyMinutes: PropTypes.string,
  estimatedStartTime: PropTypes.string,
  estimatedEndTime: PropTypes.string
};

export default CalendarInputPopover;
