import { injectConfig } from '@passionware/platform-react';
import { ResourceAdvancedSearch } from 'v1/components/shared/search/ResourceAdvancedSearch/ResourceAdvancedSearch';
import { resourceFiltersConfig } from 'v1/components/shared/search/ResourceFilters/ResourceFilters.connected';

export const resourceAdvancedSearchConfig = {
  resourceFiltersConfig
};

export default injectConfig(ResourceAdvancedSearch).static(
  resourceAdvancedSearchConfig
);
