import React, { lazy, Suspense, useEffect } from 'react';

import { Redirect, Route, Switch } from 'react-router-dom';
import PrivateRoute from 'base/PrivateRoute/PrivateRoute';
import { Loading } from 'v1/components/shared';
import Authenticating from 'v1/containers/Routes/Auth/Authenticating/Authenticating';
import RecoverPassword from 'v1/containers/Routes/Auth/RecoverPassword';
import ErrorContainer from 'v1/containers/Errors/ErrorContainer/ErrorContainer';
import Farewell from 'v1/containers/Routes/Auth/Farewell/Farewell';
import ResourcePortal from 'features/ResourcePortal/ResourcePortalRoute';
import UserOnboardingRoute from 'features/UserOnboarding/UserOnboarding';
import { Capability, FeatureFlag } from '__types__';

import Login from 'v1/containers/Routes/Auth/Login/Login';
import AuthService from 'lib/auth/AuthService';
import { loadAuth } from 'store/v1/auth/auth.actions.js';
import { getTeamAccounts, selectAccountsAreFetched } from 'store/accounts';
import { getAccountTeams, selectTeamsAreFetched } from 'store/teams';
import {
  getTeamUserGroups,
  selectUserGroupsAreFetched
} from 'store/userGroups';
import { useSelector } from 'react-redux';
import DocuSignCallback from 'v1/containers/Routes/DocuSignCallback/DocuSignCallback';
import { getIntegrations } from 'store/integrations';
import { useNewComponents_released } from '~/v4/core/migration-flag';
import { withinSettings } from 'v4/core/appRoutes';
import { get } from 'lodash';

const Onboard = lazy(() => import('v1/containers/Routes/Auth/Onboard/Onboard'));
// prettier-ignore
const Account = lazy(() => import('v1/containers/Routes/Account/Account'));
//const OnboardCSV = lazy(() => import('v1/containers/Routes/Auth/OnboardCSV/OnboardCSV'));

// prettier-ignore
//const BatchUpload = lazy(() => import('v1/containers/Routes/ContactsUpload/BatchUpload/BatchUpload'));
// prettier-ignore
//const UploadComplete = lazy(() => import('v1/containers/Routes/ContactsUpload/UploadComplete/UploadComplete'));
const Roster = lazy(() => import('v1/containers/Routes/Roster/Roster'));
const Contact = lazy(() => import('v1/containers/Routes/Contact/Contact'));
const Shortlists = lazy(
  () => import('v1/containers/Routes/Shortlists/Shortlists')
);
const ShortlistsListPage = lazy(
  () => import('~/v4/pages/shortlists/list/ShortlistListPage.connected')
);
const ShortlistDetailPage = lazy(
  () => import('~/v4/pages/shortlists/detail/ShortlistDetailPage.connected')
);
const Shortlist = lazy(
  () => import('v1/containers/Routes/Shortlist/Shortlist')
);
// prettier-ignore
const ShortlistPublic = lazy(() => import('v1/containers/Routes/ShortlistPublic/ShortlistPublic'));
const ShortlistPublicDetailPage = lazy(
  () =>
    import(
      'v4/pages/shortlists/publicDetail/PublicShortlistDetailPage.connected'
    )
);
const Callouts = lazy(() => import('v1/containers/Routes/Callouts/Callouts'));
const Callout = lazy(() => import('v1/containers/Routes/Callout/Callout'));
const Callsheets = lazy(
  () => import('v1/containers/Routes/Callsheets/Callsheets')
);
const Callsheet = lazy(
  () => import('v1/containers/Routes/Callsheet/Callsheet')
);
// prettier-ignore
const CallsheetPublic = lazy(() => import('v1/containers/Routes/CallsheetPublic/CallsheetPublic'));
// prettier-ignore
const CalloutPublic = lazy(() => import('v1/containers/Routes/CalloutPublic/CalloutPublic'));

const ArrivalBoard = lazy(
  () => import('v1/containers/Routes/ArrivalBoard/ArrivalBoard')
);

const Dashboard = lazy(
  () => import('v1/containers/Routes/Dashboard/Dashboard')
);
// prettier-ignore
const ProductionCalendar = lazy(() => import('v1/containers/Routes/Calendars/ProductionCalendar/ProductionCalendar.feature-toggled'));
// prettier-ignore
const ResourceCalendar = lazy(() => import('v1/containers/Routes/Calendars/ResourceCalendar/ResourceCalendar'));
const Production = lazy(
  () => import('v1/containers/Routes/Production/Production')
);
const Productions = lazy(
  () => import('v1/containers/Routes/Productions/Productions')
);
const Bookings = lazy(() => import('v1/containers/Routes/Bookings/Bookings'));
const Reconciliations = lazy(
  () => import('v1/containers/Routes/Reconciliations/Reconciliations')
);
const Reporting = lazy(
  () => import('v1/containers/Routes/Reporting/Reporting')
);
const FrontDesk = lazy(
  () => import('v1/containers/Routes/FrontDesk/FrontDesk')
);

export default ({ store }) => {
  const teamsAreFetched = useSelector(selectTeamsAreFetched);
  const accountsAreFetched = useSelector(selectAccountsAreFetched);
  const userGroupsAreFetched = useSelector(selectUserGroupsAreFetched);
  const settingsRoute = withinSettings();

  useEffect(() => {
    if (AuthService.isAuthenticated()) {
      const token = AuthService.token();
      store.dispatch(loadAuth(token, token.tokenString, ''));
      store.dispatch(getAccountTeams());
      store.dispatch(getTeamAccounts());
      store.dispatch(getTeamUserGroups());
      store.dispatch(getIntegrations());
    }
  }, [store]);

  const serverLanguage = useSelector(state =>
    get(state, 'auth.settings.settings.defaults_locale.setting')
  );

  if (
    AuthService.isAuthenticated() &&
    (!teamsAreFetched || !accountsAreFetched || !userGroupsAreFetched)
  ) {
    return (
      <Loading
        options={{ disableText: !serverLanguage }}
        className="pageCenter"
      />
    );
  }

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/authenticating" component={Authenticating} />
      <Route exact path="/recover_password" component={RecoverPassword} />
      <Route exact path="/farewell" component={Farewell} />
      <Route
        exact
        path="/unauthorized"
        render={() => <ErrorContainer error={{ status: 403 }} />}
      />
      <Route
        exact
        path="/logindenied"
        render={() => <ErrorContainer error={{ status: 500 }} />}
      />
      <Route exact path="/" render={() => <Redirect to="/app" />} />
      <PrivateRoute
        exact
        path="/docusign_callback"
        component={DocuSignCallback}
      />
      <PrivateRoute exact path="/app" component={null} />
      <PrivateRoute path={settingsRoute.settings.home()} component={Account} />
      <PrivateRoute exact path="/app/:app_id" component={null} />
      <PrivateRoute exact path="/app/:app_id/dashboard" component={Dashboard} />
      <PrivateRoute
        exact
        path="/app/:app_id/resources"
        flag={FeatureFlag.RESOURCES}
        capabilities={Capability.RESOURCE_READ}
        component={Roster}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/resources/:id"
        flag={FeatureFlag.RESOURCES}
        capabilities={Capability.RESOURCE_READ}
        component={Roster}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/contacts/:id"
        component={Contact}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/contacts/:id/:tab"
        component={Contact}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/shortlists"
        flag={FeatureFlag.SHORTLISTS}
        component={useNewComponents_released ? ShortlistsListPage : Shortlists}
        capabilities={Capability.SHORTLIST_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/shortlists/:id"
        flag={FeatureFlag.SHORTLISTS}
        component={useNewComponents_released ? ShortlistDetailPage : Shortlist}
        capabilities={Capability.SHORTLIST_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/productions"
        flag={FeatureFlag.PRODUCTIONS}
        component={Productions}
        capabilities={Capability.PRODUCTION_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/productions/:id"
        flag={FeatureFlag.PRODUCTIONS}
        component={Production}
        capabilities={Capability.PRODUCTION_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/productions/:id/:section"
        flag={FeatureFlag.PRODUCTIONS}
        component={Production}
        capabilities={Capability.PRODUCTION_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/productions/:id/:section/:teamMember"
        flag={FeatureFlag.PRODUCTIONS}
        component={Production}
        capabilities={Capability.RESOURCE_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/callouts"
        flag={FeatureFlag.CALLOUTS}
        component={Callouts}
        capabilities={Capability.CALLOUT_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/callouts/:id"
        flag={FeatureFlag.CALLOUTS}
        component={Callout}
        capabilities={Capability.CALLOUT_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/callsheets"
        flag={FeatureFlag.CALLSHEETS}
        component={Callsheets}
        capabilities={Capability.CALLSHEET_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/callsheets/:id"
        flag={FeatureFlag.CALLSHEETS}
        component={Callsheet}
        capabilities={Capability.CALLSHEET_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/calendar/productions"
        flag={FeatureFlag.PRODUCTIONS}
        component={ProductionCalendar}
        capabilities={Capability.PRODUCTION_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/calendar/resources"
        flag={FeatureFlag.RESOURCES}
        component={ResourceCalendar}
        capabilities={Capability.RESOURCE_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/calendar"
        flag={FeatureFlag.CALENDAR}
        component={ResourceCalendar}
        capabilitiesAtLeastOne={[
          Capability.PRODUCTION_READ,
          Capability.RESOURCE_READ
        ]}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/bookings"
        flag={FeatureFlag.BOOKINGS}
        component={Bookings}
        capabilities={Capability.BOOKING_READ}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/frontdesk"
        flag={FeatureFlag.NIKECUSTOM_FRONT_DESK}
        component={FrontDesk}
        capabilities={Capability.CONFIGURE_CUSTOM_NIKE_FRONT_DESK}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/reconciliations"
        flag={FeatureFlag.RECONCILIATIONS}
        component={Reconciliations}
        capabilities={Capability.MANAGE_RECONCILIATION}
      />
      <PrivateRoute
        exact
        path="/app/:app_id/reporting"
        flag={FeatureFlag.REPORTING}
        component={Reporting}
        capabilities={Capability.REPORT_DOWNLOAD}
      />
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route
            exact
            path="/app/:app_id/onboard"
            component={Onboard}
            fullpage
          />
          <Route
            exact
            path="/app/:app_id/p/callouts/:id"
            component={CalloutPublic}
          />
          <Route
            exact
            path="/app/:app_id/p/callsheets/:id"
            component={CallsheetPublic}
          />
          <Route
            exact
            path="/app/:app_id/p/shortlists/:id"
            component={
              useNewComponents_released
                ? ShortlistPublicDetailPage
                : ShortlistPublic
            }
          />
          <Route
            exact
            path="/app/:app_id/arrivals"
            flag={FeatureFlag.NIKECUSTOM_ARRIVAL_BOARD}
            render={props => <ArrivalBoard orientation="portrait" />}
            capabilities={Capability.CONFIGURE_CUSTOM_NIKE_ARRIVAL_BOARD}
          />
          <Route
            exact
            path="/app/:app_id/arrivals/landscape"
            flag={FeatureFlag.NIKECUSTOM_ARRIVAL_BOARD}
            render={props => <ArrivalBoard orientation="landscape" />}
            capabilities={Capability.CONFIGURE_CUSTOM_NIKE_ARRIVAL_BOARD}
          />
          <Route
            path="/app/:team_schema/resource-portal" //app_id and team_schema (api v2) refer to the same thing.
            render={props => <ResourcePortal {...props} />}
          />
          <Route
            path="/app/:team_schema/onboarding"
            render={props => <UserOnboardingRoute />}
          />
          <Route render={() => <ErrorContainer error={{ status: 404 }} />} />
        </Switch>
      </Suspense>
      <Route render={() => <ErrorContainer error={{ status: 404 }} />} />
    </Switch>
  );
};
