import { myServices } from 'v5/services/services.connected.app';
import { CommandBarWidget } from './CommandBarWidget';
import { useSelector } from 'react-redux';

export function CommandBarWidgetConnected() {
  const loggedIn = useSelector((state: any) => state.auth.loggedIn);
  if (!loggedIn) {
    return null;
  }
  return <CommandBarWidget services={myServices} />;
}
