import React from 'react';
import PropTypes from 'prop-types';

import { Dropdown, MenuItem } from 'v1/components/shared';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import { useTranslation } from 'react-i18next';

const SelectResourceType = ({
  resourceTypes,
  activeResourceType,
  onSelect
}) => {
  const { t } = useTranslation('core');
  return (
    <div className="SelectResourceType stack-S">
      <div className="text-13-600 stack-XS">
        {t('core_fields.resource_type_id')}
      </div>
      <Dropdown
        buttonLabel={
          activeResourceType
            ? activeResourceType.name
            : t('core_fields.resource_type_id')
        }
        buttonIcon={
          activeResourceType ? (
            <img
              src={`/images/${
                RESOURCE_TYPE_ICONS[activeResourceType.icon].black
              }`}
              alt=""
            />
          ) : (
            <img
              src={`/images/${RESOURCE_TYPE_ICONS['default'].black}`}
              alt=""
            />
          )
        }
        buttonClass="btn btn-default btn-small btn-fill"
      >
        <MenuItem
          onSelect={() => {
            onSelect(null);
          }}
        >
          <span className="MenuItem-icon"></span>
          <span className="MenuItem-label">{t('core_fields.all')}</span>
        </MenuItem>
        {resourceTypes.map(resourceType => (
          <MenuItem
            key={resourceType.id}
            onSelect={() => onSelect(resourceType)}
          >
            <span className="MenuItem-icon">
              <img
                src={`/images/${RESOURCE_TYPE_ICONS[resourceType.icon].black}`}
                alt=""
              />
            </span>
            <span className="MenuItem-label">{resourceType.name}</span>
          </MenuItem>
        ))}
      </Dropdown>
    </div>
  );
};

SelectResourceType.propTypes = {
  resourceTypes: PropTypes.array,
  activeResourceType: PropTypes.object,
  onSelect: PropTypes.func // (resourceType)
};

export default SelectResourceType;
