import mirror from 'key-mirror';
import { mapValues } from 'lodash';

export const DATA_EXPORTS_ACTIONS = mapValues(
  mirror({
    GET_DATA_EXPORT_TEMPLATES: null,
    GET_DATA_EXPORT_TEMPLATES_SUCCESS: null,
    GET_DATA_EXPORT_TEMPLATES_FAILURE: null,

    CREATE_DATA_EXPORT_TEMPLATE: null,
    CREATE_DATA_EXPORT_TEMPLATE_SUCCESS: null,
    CREATE_DATA_EXPORT_TEMPLATE_FAILURE: null,

    UPDATE_DATA_EXPORT_TEMPLATE: null,
    UPDATE_DATA_EXPORT_TEMPLATE_SUCCESS: null,
    UPDATE_DATA_EXPORT_TEMPLATE_FAILURE: null,

    CREATE_DATA_EXPORT: null,
    CREATE_DATA_EXPORT_SUCCESS: null,
    CREATE_DATA_EXPORT_FAILURE: null
  }),
  value => value
);
