import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment-timezone';
import './SaveStatus.scss';

class SaveStatus extends Component {
  render() {
    const { loading, updated } = this.props;

    return (
      <div className={classnames({ loading: loading }, ['SaveStatus'])}>
        {loading ? (
          <span className="SaveStatus-saving">Saving...</span>
        ) : (
          <div
            data-tip={`Saved ${moment(updated).fromNow()}`}
            className="SaveStatus-saved"
          >
            Saved{' '}
            <img
              className="inline-block"
              src="/images/icon_tick_green.svg"
              alt=""
              width="11px"
            />
          </div>
        )}
      </div>
    );
  }
}

SaveStatus.propTypes = {
  loading: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  updated: PropTypes.any // TODO: type - date?
};

export default SaveStatus;
