import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';

export default function reducer(state = {}, action) {
  const key = action.event_id ? action.event_id : action.type;

  // Ignore internal redux actions
  if (action.type.includes('@@redux')) return state;

  // ignore migration actions
  if (action.type === 'PUBLIC_SETTINGS_READY') return state;

  // Clear events on OPEN_MODAL otherwise can have unaxpected behaviours
  if (action.type === 'OPEN_MODAL') return {};

  if (action.type === 'WIPE_EVENT') {
    const { [action.event_id]: item, ...data } = state;
    return data;
  }

  if (action.type.endsWith('/pending')) {
    return {
      ...state,
      [key.replace('/pending', '')]: EVENT_ACTIONS.PROCESSING
    };
  }
  if (action.type.endsWith('/fulfilled')) {
    return { ...state, [key.replace('/fulfilled', '')]: EVENT_ACTIONS.SUCCESS };
  }
  if (action.type.endsWith('/rejected')) {
    return {
      ...state,
      [key.replace('/rejected', '')]: action.error || EVENT_ACTIONS.FAILURE
    };
  }

  if (action.type.includes('_SUCCESS')) {
    return { ...state, [key.replace('_SUCCESS', '')]: EVENT_ACTIONS.SUCCESS };
  }
  if (action.type.includes('_FAILURE')) {
    return {
      ...state,
      [key.replace('_FAILURE', '')]: action.error || EVENT_ACTIONS.FAILURE
    };
  }

  return { ...state, [key]: EVENT_ACTIONS.PROCESSING };
}

/**
 * Quite dirty copy of the above, but it holds the action data so we can know the payload of event, not only that it succeeded or failed
 * @param state
 * @param action
 * @returns {{[p: number]: *}|{}|Omit<{}, never>}
 */
export function eventPayloadReducer(state = {}, action) {
  const key = action.event_id ? action.event_id : action.type;

  // Ignore internal redux actions
  if (action.type.includes('@@redux')) return state;

  // ignore migration actions
  if (action.type === 'PUBLIC_SETTINGS_READY') return state;

  // Clear events on OPEN_MODAL otherwise can have unaxpected behaviours
  if (action.type === 'OPEN_MODAL') return {};

  if (action.type === 'WIPE_EVENT') {
    const { [action.event_id]: item, ...data } = state;
    return data;
  }

  if (action.type.endsWith('/pending')) {
    return {
      ...state,
      [key.replace('/pending', '')]: action
    };
  }
  if (action.type.endsWith('/fulfilled')) {
    return { ...state, [key.replace('/fulfilled', '')]: action };
  }
  if (action.type.endsWith('/rejected')) {
    return {
      ...state,
      [key.replace('/rejected', '')]: action
    };
  }

  if (action.type.includes('_SUCCESS')) {
    return { ...state, [key.replace('_SUCCESS', '')]: action };
  }
  if (action.type.includes('_FAILURE')) {
    return {
      ...state,
      [key.replace('_FAILURE', '')]: action
    };
  }

  return { ...state, [key]: action };
}
