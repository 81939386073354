import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  Grid,
  GridCell,
  RadioGroup,
  Radio,
  TextInput,
  Label,
  TimeInput12h
} from 'v1/components/shared';

import { DURATION_TYPES } from 'v1/helpers/byModel/EventHelper';

import styles from './EventHoursInputOptions.module.scss';
import { useTranslation } from 'react-i18next';

const EventHoursInputOptions = ({
  handleChange,
  durationType,
  estimatedDailyHours,
  estimatedDailyMinutes,
  estimatedStartTime,
  estimatedEndTime
}) => {
  const { t } = useTranslation('v1_shared');
  return (
    <RadioGroup
      name="event-hours"
      onChange={({ target }) =>
        handleChange('date_type', target.value, durationType)
      }
      value={durationType}
    >
      <Radio
        className={classnames({
          [styles['EventHoursInputOptions--active']]:
            durationType === DURATION_TYPES.RECURRING_DEFAULT
        })}
        id="duration-type-default"
        name="duration-type-default"
        label={t('EventHoursInputOptions.allDay')}
        value={DURATION_TYPES.RECURRING_DEFAULT}
      />

      <Radio
        className={classnames({
          [styles['EventHoursInputOptions--active']]:
            durationType === DURATION_TYPES.RECURRING_HOURS
        })}
        id="duration-type-recurring-hours"
        name="duration-type-recurring-hours"
        label={t('EventHoursInputOptions.hoursPerDay')}
        value={DURATION_TYPES.RECURRING_HOURS}
      />
      <>
        {durationType === DURATION_TYPES.RECURRING_HOURS && (
          <div
            className={classnames([
              styles['EventHoursInputOptions__row'],
              'slimline',
              {
                [styles['EventHoursInputOptions--active']]:
                  durationType === DURATION_TYPES.RECURRING_HOURS
              }
            ])}
          >
            <Grid gutters="S">
              <GridCell>
                <TextInput
                  name="estimated_daily_hours"
                  type="number"
                  label={t('EventHoursInputOptions.hours')}
                  value={estimatedDailyHours || ''}
                  min={0}
                  max={24}
                  size="S"
                  placeholder={t('EventHoursInputOptions.hours')}
                  onChange={({ target }) =>
                    handleChange(
                      'estimated_daily_hours',
                      parseInt(target.value)
                    )
                  }
                />
              </GridCell>
              <GridCell>
                <TextInput
                  name="estimated_daily_minutes"
                  type="number"
                  label={t('EventHoursInputOptions.minutes')}
                  value={estimatedDailyMinutes || ''}
                  min={0}
                  max={59}
                  step={10}
                  size="S"
                  placeholder={t('EventHoursInputOptions.minutes')}
                  onChange={({ target }) =>
                    handleChange(
                      'estimated_daily_minutes',
                      parseInt(target.value)
                    )
                  }
                />
              </GridCell>
            </Grid>
          </div>
        )}
      </>

      <Radio
        className={classnames({
          [styles['EventHoursInputOptions--active']]:
            durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS
        })}
        id="duration-type-recurring-specific-hours"
        name="duration-type-recurring-specific-hours"
        label={t('EventHoursInputOptions.specificTimes')}
        value={DURATION_TYPES.RECURRING_SPECIFIC_HOURS}
      />
      <>
        {durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS && (
          <div
            className={classnames([
              styles['EventHoursInputOptions__row'],
              'slimline',
              {
                [styles['EventHoursInputOptions--active']]:
                  durationType === DURATION_TYPES.RECURRING_SPECIFIC_HOURS
              }
            ])}
          >
            <Grid gutters="S">
              <GridCell>
                <Label>{t('EventHoursInputOptions.from')}</Label>
                <TimeInput12h
                  size="S"
                  onChange={value =>
                    handleChange('estimated_start_time', `${value}:00`)
                  }
                  value={estimatedStartTime}
                />
              </GridCell>
              <GridCell>
                <Label>{t('EventHoursInputOptions.to')}</Label>
                <TimeInput12h
                  size="S"
                  onChange={value =>
                    handleChange('estimated_end_time', `${value}:00`)
                  }
                  value={estimatedEndTime}
                />
              </GridCell>
            </Grid>
          </div>
        )}
      </>
    </RadioGroup>
  );
};

EventHoursInputOptions.propTypes = {
  handleChange: PropTypes.func,
  dateType: PropTypes.oneOf([
    DURATION_TYPES.RECURRING_DEFAULT,
    DURATION_TYPES.RECURRING_HOURS,
    DURATION_TYPES.RECURRING_SPECIFIC_HOURS
  ]),
  estimatedDailyHours: PropTypes.string,
  estimatedDailyMinutes: PropTypes.string,
  estimatedStartTime: PropTypes.string,
  estimatedEndTime: PropTypes.string
};

export default EventHoursInputOptions;
