import classNames from 'classnames';
import { HTMLProps } from 'react';

export function DimmedContainer({
  className,
  shouldDim,
  ...rest
}: { shouldDim: boolean } & HTMLProps<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'transition duration-500 ease-out',
        {
          'blur-[1px] opacity-80 ease-in': shouldDim
        },
        className
      )}
      {...rest}
    />
  );
}
