import classNames from 'classnames';
import { PropsWithChildren } from 'react';

const sidebarWidth = "220px";

const SidebarRoot = ({ children, className, ...rest }: PropsWithChildren & { className?: string }) => {
  return (
    <div style={{ width: sidebarWidth }} className={classNames("fixed flex flex-col left-0 top-0 h-screen bg-eggplant text-white/80", className)} {...rest}>
      {children}
    </div>
  );
};

export default SidebarRoot;
