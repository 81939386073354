import { ExternalEventApi } from '../../../api/external-event/external-event.api';
import { EventService } from './EventService';

export function createEventService(api: ExternalEventApi): EventService {
  return {
    event: api.data,
    waitForMessage: async test => {
      await api.ensureAlive();
      return new Promise(resolve => {
        const unsubcribe = api.data.addListener(event => {
          if (test(event)) {
            resolve(event);
            unsubcribe();
          }
        });
      });
    }
  };
}
