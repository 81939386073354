import { maybe } from '@passionware/monads';
import moment from 'moment-timezone';
import { CustomFieldDefinition } from '../../../__types__';
import { FrontDeskEntry } from './front-desk.api';
import { Booking$ } from './front-desk.api.http.schema';

function resolveStudios(
  entry: Booking$,
  custom_field_definitions: CustomFieldDefinition[]
) {
  const studioFieldDefinition = custom_field_definitions.find(
    cfd => cfd.name === 'Studio'
  );

  if (!studioFieldDefinition)
    return { definedAt: 'booking' as const, labels: [] };

  const productionStudioFields =
    entry.production_custom_fields?.filter(
      cfd =>
        cfd.custom_field_definition_id === studioFieldDefinition.id &&
        cfd.option_id !== null
    ) ?? [];
  const bookingStudioFields =
    entry.booking_custom_fields?.filter(
      cfd =>
        // @ts-expect-error probably wrong v1  types
        cfd.custom_field_definition_id === studioFieldDefinition.id &&
        // @ts-expect-error probably wrong v1  types
        cfd.option_id !== null
    ) ?? [];

  const optionToOptionValue = (option: any) =>
    studioFieldDefinition.options.find(o => o.id === option.option_id)?.value ??
    'Unknown';

  if (productionStudioFields.length) {
    return {
      definedAt: 'production' as const,
      labels: productionStudioFields.map(optionToOptionValue)
    };
  }
  return {
    definedAt: 'booking' as const,
    labels: bookingStudioFields.map(optionToOptionValue)
  };
}

export function convertToFrontDeskEntry(
  booking: Booking$,
  custom_field_definitions: CustomFieldDefinition[]
): FrontDeskEntry {
  let resolvedStudios = resolveStudios(booking, custom_field_definitions);
  // Determine checkin time and set it in start_calculated
  let start_calculated = moment('8:30', 'LT');
  if (booking.date_type === 'RECURRING_SPECIFIC_HOURS') {
    start_calculated = moment(booking.estimated_start_time, 'LTS');
  }

  return {
    time: start_calculated,
    bookingId: booking.booking_id,
    resource: {
      id: booking.contact_id,
      firstName: booking.first_name,
      lastName: booking.last_name,
      profilePicture: booking.profile_picture ?? null
    },
    locations: booking.locations.filter(maybe.isPresent),
    studios: resolvedStudios.labels.map(label => ({
      definedAt: resolvedStudios.definedAt,
      name: label
    })),
    checkinDate: maybe.mapOrNull(booking.checkin?.checked_in_timestamp, moment),
    checkoutDate: maybe.mapOrNull(
      booking.checkin?.checked_out_timestamp,
      moment
    ),
    badgeNumber: maybe.getOrNull(booking.checkin?.badge_number),
    note: booking.note ?? '',
    status: maybe.getOrNull(booking.checkin?.status),
    checkinId: maybe.getOrNull(booking.checkin?.id)
  };
}
