import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import size from 'lodash/size';

import { getFormattedEventDates } from 'v1/helpers/byModel/EventHelper';
import { Status } from 'v1/components/shared';

// TODO: can be cleaned up
const ResourceStatusDisplay = ({
  booking = {},
  assignment,
  hideAssignments,
  trimDates
}) => {
  const renderDates = () => {
    return booking.events?.map(e => (
      <div key={e.id} className="text-13-600">
        {getFormattedEventDates(e, null, trimDates)}
      </div>
    ));
  };

  // TODO: this is highly inaccurate copy-wise as it treats every assignment as a different production
  // TODO: some of this can be helpers
  const renderAssignments = () => {
    if (hideAssignments) return;

    const isAssignedToSlot =
      get(assignment, 'booking_id') === get(booking, 'id');
    const assignmentsCount = size(get(booking, 'resource_slot_assignments'));
    const otherAssignmentsCount =
      assignmentsCount > 1 ? assignmentsCount - 1 : null;

    if (isAssignedToSlot) {
      return `Assigned to this production${
        otherAssignmentsCount
          ? ` and ${otherAssignmentsCount} other${
              otherAssignmentsCount > 1 ? 's' : ''
            }`
          : ''
      }`;
    }
    if (assignmentsCount) {
      return `Assigned to ${assignmentsCount} production${
        assignmentsCount > 1 ? 's' : ''
      }`;
    }
  };

  if (booking) {
    return (
      <div className="ResourceStatusDisplay">
        <div className="stack-XS">
          <Status statusId={get(booking, 'status_id')} />
        </div>
        {renderDates()}
        {renderAssignments()}
      </div>
    );
  }
  return <span />;
};

ResourceStatusDisplay.propTypes = {
  booking: PropTypes.object,
  assignment: PropTypes.object
};

export default ResourceStatusDisplay;
