import classnames from 'classnames';
import _debounce from 'lodash/debounce';
import get from 'lodash/get';

import './SearchInput.scss';
import React, { useCallback, useEffect, useState } from 'react';
import TextInput from 'v1/components/shared/byType/text/TextInput/TextInput';

interface SearchInputProps {
  className?: string;
  disabled?: boolean;
  inputClassName?: string;
  query?: { query?: string };
  placeholder?: string;
  name?: string;
  size?: 'S' | 'M' | 'L';
  appearance?: any; // TODO: Define a stricter type if possible
  initialFocus?: boolean;
  debounce?: number;
  onChange?: (value: string) => void;
  onSearch: (value: string) => void;
}

const defaultQuery = {};
const noop = () => {};

function SearchInput({
  className,
  inputClassName,
  disabled,
  query = defaultQuery,
  placeholder = 'Search...',
  name = 'query',
  size,
  appearance,
  initialFocus,
  onChange = noop,
  onSearch = noop,
  debounce = 0
}: SearchInputProps) {
  // STATE
  const [searchQuery, setSearchQuery] = useState<string>(
    get(query, 'query', '')
  );

  // EFFECTS
  useEffect(() => {
    setSearchQuery(query.query || '');
  }, [query.query]);

  // FUNCTIONS
  // This is required to update the debounce function if the query object changes in its parent component
  const onSearchDebounced = useCallback(_debounce(onSearch, debounce), [query]);

  const onInputChange = (value: string) => {
    setSearchQuery(value);
    onChange?.(value);
    debounce ? onSearchDebounced(value) : onSearch(value);
  };

  return (
    <div className={classnames('SearchInput', className)}>
      <img src="/images/icon_search.svg" className="SearchInput-icon" alt="" />
      <TextInput
        name={name}
        disabled={disabled}
        className={classnames('SearchInput-input', inputClassName)}
        appearance={appearance}
        size={size}
        // @ts-expect-error TODO: finish migration
        field="query"
        value={searchQuery}
        // @ts-expect-error TODO: finish migration
        onChange={e => onInputChange(e.target.value)}
        placeholder={placeholder}
        removeAutocomplete={true}
        initialFocus={initialFocus}
        highlightOnFocus
        tabIndex={1}
      />
    </div>
  );
}

export default SearchInput;
