import { Skeleton } from 'v5/design-sytem/Skeleton';

export function CreateFieldSkeleton() {
  return (
    <div className="">
      <div className="space-y-4">
        <div className="space-y-2">
          <Skeleton className="h-6 w-1/3" /> {/* label */}
          <Skeleton className="h-10 w-full rounded-md" /> {/* input */}
        </div>

        <Skeleton className="h-px" />
        <div className="space-y-2">
          <Skeleton className="h-6 w-1/3" /> {/* label */}
          <Skeleton className="h-10 w-full rounded-md" /> {/* select */}
        </div>

        <Skeleton className="h-px" />

        <div className="flex flex-col space-y-7">
          <div className="flex items-center space-x-2">
            <Skeleton className="h-4 w-4 rounded" /> {/* checkbox */}
            <Skeleton className="h-6 w-1/2" /> {/* label */}
          </div>
          <div className="flex items-center space-x-2">
            <Skeleton className="h-4 w-4 rounded" /> {/* checkbox */}
            <Skeleton className="h-6 w-1/2" /> {/* label */}
          </div>
        </div>
      </div>
    </div>
  );
}
