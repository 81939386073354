const system = /Mac|iPod|iPhone|iPad/.test(navigator.platform) ? 'mac' : 'win';

export function getSystem() {
  return system;
}

export function getModifierKey() {
  return system === 'mac' ? 'metaKey' : 'ctrlKey';
}

export function getModifierKeyLabel() {
  return system === 'mac' ? 'cmd' : 'ctrl';
}
