import { maybe } from '@passionware/monads';
import { CancelledError, QueryClient, useQuery } from '@tanstack/react-query';
import { isEqual } from 'lodash';
import { z } from 'zod';
import { FrontDeskApi } from '../../../api/front-desk/front-desk.api';
import { EventService } from '../EventService/EventService';
import { FrontDeskService } from './FrontDeskService';

export function createFrontDeskService(config: {
  api: FrontDeskApi;
  client: QueryClient;
  services: {
    eventService: EventService;
  };
}): FrontDeskService {
  const debouncedInvalidate = bufferPromises(
    () => invalidatePreserveCancellation(config.client, ['front-desk']),
    3000 // todo + random(0,27000)
  );

  config.services.eventService.event.addListener(event => {
    switch (event.type) {
      case 'CHECKIN_CREATED':
      case 'CHECKIN_UPDATED': {
        // first we cancel all queries now because they are going to be stale anyway and we want to save server resources
        void config.client.cancelQueries({ queryKey: ['front-desk'] });
        // we schedule a debounced invalidation so that we don't invalidate too often
        void debouncedInvalidate();
      }
    }
  });

  return {
    useFrontDesk: query => {
      return useQuery(
        {
          enabled: !!query,
          queryKey: ['front-desk', query],
          meta: {
            fetchTime: Date.now()
          },
          queryFn: context => config.api.getEntries(query!, context.signal)
        },
        config.client
      );
    },
    waitForInvalidation: async () => debouncedInvalidate()
  };
}

function bufferPromises<T extends (...args: any[]) => Promise<any>>(
  fn: T,
  delay: number
): (...args: Parameters<T>) => ReturnType<T> {
  let timer: ReturnType<typeof setTimeout> | null = null;
  let lastArgs: Parameters<T> | null = null;
  let lastPromise: Promise<ReturnType<T>> | null = null;
  let resolvers: ((value: any) => void)[] = [];
  let rejecters: ((reason: any) => void)[] = [];

  return (...args: Parameters<T>): ReturnType<T> => {
    lastArgs = args;

    const promise = new Promise<ReturnType<T>>((resolve, reject) => {
      resolvers.push(resolve);
      rejecters.push(reject);
    });

    if (!timer) {
      timer = setTimeout(async () => {
        timer = null;
        if (!lastArgs) return;

        try {
          lastPromise = fn(...lastArgs);
          const result = await lastPromise;
          resolvers.forEach(r => r(result));
        } catch (error) {
          rejecters.forEach(r => r(error));
        } finally {
          resolvers = [];
          rejecters = [];
          lastArgs = null;
        }
      }, delay);
    }

    return promise as ReturnType<T>;
  };
}

function invalidatePreserveCancellation(
  queryClient: QueryClient,
  queryKey: ReadonlyArray<unknown>
) {
  return new Promise<void>((resolve, reject) => {
    const queryIsSomewhatFresh = maybe.mapOrElse(
      queryClient.getQueryState(queryKey)?.dataUpdatedAt,
      queryUpdatedAt => queryUpdatedAt > Date.now() - 20_000,
      false
    );

    const promise = queryClient.invalidateQueries(
      { queryKey },
      {
        throwOnError: true,
        cancelRefetch: queryIsSomewhatFresh // let's not kill long running queries otherwise people will never see the new data
      }
    );

    promise.then(resolve, error => {
      if (error instanceof CancelledError) {
        const cancelTime = Date.now();
        const unsubscribe = queryClient.getQueryCache().subscribe(event => {
          if (
            // check if this is about our query
            isEqual(event.query.queryKey, queryKey) &&
            // check if this is successful write to the cache
            event.type === 'updated' &&
            event.action.type === 'success' &&
            // we must check if this was fetched after the cancel time, otherwise we may resolve too early - where the change was not yet reflected
            z.number().parse(event.query.options.meta?.fetchTime)! > cancelTime
          ) {
            resolve();
            unsubscribe();
          }
        });
      } else {
        reject(error);
      }
    });
  });
}
