import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useHistory } from 'react-router-dom';
import { Form, Field } from 'react-form';
import get from 'lodash/get';
import { v4 } from 'uuid';
import isEmpty from 'lodash/isEmpty';
import classnames from 'classnames';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';
import { CALLOUT_ACTIONS } from 'store/v1/callouts/callouts.constants.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import {
  createCallout,
  updateCallout
} from 'store/v1/callouts/callouts.actions.js';

import {
  PressStud,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation,
  Label,
  TextInputField,
  Grid,
  GridCell,
  ListSelect,
  MenuItem,
  Dropdown
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';
import useEvent from 'v1/helpers/hooks/useEvent';
import getCalloutTemplates from './CALLOUT_TEMPLATES.js';
import { useTranslation } from 'react-i18next';

export const CalloutCreateModal = ({ onRequestClose }) => {
  const { t } = useTranslation('callouts');
  const CALLOUT_TEMPLATES = getCalloutTemplates(t);
  const dispatch = useDispatch();
  const history = useHistory();

  // STORE
  const { app_id } = useSelector(state => state.auth);
  const {
    loading,
    new: newCallout,
    associations_data
  } = useSelector(state => state.callouts);
  const { callout = {}, method = 'create' } = useSelector(
    state => state.ui.data
  );
  const resourceTypes = useSelector(state => state.resource_types);
  const resourceTypesList = useSelector(state =>
    getResourceTypes(state, { model: null })
  );
  const { title, resource_type_id, mappings, public_description } = callout;
  const applicants = get(associations_data, [callout.id, 'applicants'], []);

  // STATE
  const [formApi, setFormApi] = useState();
  const [activeResourceType, setActiveResourceType] = useState(
    get(resourceTypes, ['data', resource_type_id], resourceTypesList[0])
  );
  const [selectedTemplate, setSelectedTemplateID] = useState(
    get(CALLOUT_TEMPLATES, [0, 'id'])
  );

  useEvent([CALLOUT_ACTIONS.CREATE_CALLOUT], {
    onSuccess: () => {
      const calloutId = get(newCallout, 'id');
      history.push(`/app/${app_id}/callouts/${calloutId}`);
      onRequestClose();
    },
    onFailure: () => {
      dispatch(
        displayAlert('error', t('CalloutCreateModal.couldNotCreateCallout'))
      );
    }
  });

  useEvent([CALLOUT_ACTIONS.UPDATE_CALLOUT], {
    onSuccess: () => {
      onRequestClose();
    },
    onFailure: () => {
      dispatch(
        displayAlert('error', t('CalloutCreateModal.couldNotUpdateCallout'))
      );
    }
  });

  function buildFieldsFromTemplate(selected) {
    const mappings = get(CALLOUT_TEMPLATES, [
      selected,
      'template_data',
      'mappings'
    ]);

    return mappings.map(map => ({ ...map, id: v4() }));
  }

  function handleSubmit(values) {
    if (method === 'edit' && callout.id) {
      return dispatch(updateCallout(callout.id, values));
    }
    return dispatch(createCallout(values));
  }

  function generateMappingsFromTemplate() {
    return get(CALLOUT_TEMPLATES, [0, 'template_data', 'mappings'], []).map(
      field => ({ ...field, id: v4() })
    );
  }

  function generateModalTitle() {
    switch (method) {
      case 'create':
        return t('CalloutCreateModal.createCallout');
      case 'edit':
        return t('CalloutCreateModal.editCallout');
      case 'duplicate':
        return t('CalloutCreateModal.duplicateCallout');
      default:
        return t('CalloutCreateModal.callout');
    }
  }

  function generateDefaultValues() {
    return {
      title: method === 'duplicate' ? `${title} (Copy)` : title,
      public_description,
      mappings: mappings || generateMappingsFromTemplate(),
      resource_type_id: resource_type_id || activeResourceType.id
    };
  }

  return (
    <ModalWrapper size="M">
      <ModalHeader title={generateModalTitle()} />
      <ModalContent>
        <ModalScrollable padding="L">
          <Form
            onSubmit={handleSubmit}
            defaultValues={generateDefaultValues()}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => (
              <form onSubmit={formApi.submitForm}>
                <Grid className="stack-S" gutters="S">
                  <GridCell width="3/12">
                    <Label size="L">{t('CalloutCreateModal.details')}</Label>
                  </GridCell>
                  <GridCell className="FormBuilder-FormGroup">
                    <Grid className="stack-M">
                      <GridCell>
                        <Label>{t('CalloutCreateModal.title')}</Label>
                        <TextInputField
                          field="title"
                          placeholder={t('CalloutCreateModal.titlePlaceholder')}
                          validate="required"
                          initialFocus
                          removeAutocomplete
                        />
                      </GridCell>
                    </Grid>
                    <Grid>
                      <GridCell>
                        <Label>
                          {t('CalloutCreateModal.defaultResourceType')}
                        </Label>
                        <div className="text-13-600-eggplant stack-S">
                          {t('CalloutCreateModal.selectResourceTypeInfo')}
                        </div>
                        <Field field="resource_type_id">
                          {fieldApi => (
                            <Dropdown
                              buttonLabel={
                                activeResourceType
                                  ? activeResourceType.name
                                  : t('CalloutCreateModal.resourceType')
                              }
                              buttonIcon={
                                activeResourceType ? (
                                  <img
                                    src={`/images/${
                                      RESOURCE_TYPE_ICONS[
                                        activeResourceType.icon
                                      ].black
                                    }`}
                                    alt=""
                                  />
                                ) : (
                                  <img
                                    src={`/images/${RESOURCE_TYPE_ICONS['default'].black}`}
                                    alt=""
                                  />
                                )
                              }
                              buttonClass="btn btn-default btn-fill"
                            >
                              {resourceTypesList.map(resourceType => (
                                <MenuItem
                                  key={resourceType.id}
                                  onSelect={() => {
                                    setActiveResourceType(resourceType);
                                    fieldApi.setValue(resourceType.id);
                                  }}
                                >
                                  <span className="MenuItem-icon">
                                    <img
                                      src={`/images/${
                                        RESOURCE_TYPE_ICONS[resourceType.icon]
                                          .black
                                      }`}
                                      alt=""
                                    />
                                  </span>
                                  <span className="MenuItem-label">
                                    {resourceType.name}
                                  </span>
                                </MenuItem>
                              ))}
                            </Dropdown>
                          )}
                        </Field>
                      </GridCell>
                    </Grid>
                  </GridCell>
                </Grid>
                {!callout.id && (
                  <Grid className="stack-S" gutters="S">
                    <GridCell width="3/12">
                      <Label size="L">{t('CalloutCreateModal.template')}</Label>
                      <div className="stack-M text-13-600-eggplant">
                        {t('CalloutCreateModal.templateInfo')}
                      </div>
                    </GridCell>
                    <GridCell
                      className={classnames(
                        { disabled: !isEmpty(applicants) },
                        'FormBuilder-FormGroup'
                      )}
                    >
                      <Field field="mappings">
                        {fieldApi => (
                          <ListSelect
                            list={CALLOUT_TEMPLATES}
                            mode="single"
                            onSelectChange={selected => {
                              setSelectedTemplateID(selected);
                              fieldApi.setValue(
                                buildFieldsFromTemplate(selected)
                              );
                            }}
                            selected={[selectedTemplate]}
                            hideCreate
                          />
                        )}
                      </Field>
                    </GridCell>
                  </Grid>
                )}
              </form>
            )}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label={t('CalloutCreateModal.cancel')}
            appearance="silent"
            action={onRequestClose}
          />
          <PressStud
            label={
              callout.id
                ? t('CalloutCreateModal.saveChanges')
                : t('CalloutCreateModal.createCallout')
            }
            appearance="primary"
            isLoading={
              loading === 'CREATE_CALLOUT' || loading === 'UPDATE_CALLOUT'
            }
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default CalloutCreateModal;
