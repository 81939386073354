"use client";
import {
  MenuRoot,
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuItemGroupLabel,
  MenuPositioner,
  MenuTrigger,
  Portal
} from '@ark-ui/react';
import { Icon } from 'shared';
import SidebarButton from './SidebarButton';
import SidebarSeparator from './SidebarSeparator';
import { Capability, FeatureFlag, ResourceType } from '__types__';
import { RESOURCE_TYPE_ICONS } from 'v1/helpers/iconSets';

import { useRestrictedAccessCallback } from 'lib/restrictedAccess/useRestrictedAccess';
import Separated from 'v4/shared/components/Separated';
import { SplashlightDocuSignModal } from 'modals';

export interface SidebarGlobalCreateConfig {
  useResourceTypes: () => ResourceType[];
  useV1Modal: () => (modal: string, data?: any) => void;
  useV2Modal: () => (modal: React.FC) => void;
  useRestrictedAccess: typeof useRestrictedAccessCallback;
  useDocusignIntegration: () => any;
}

export function createSidebarGlobalCreate(config: SidebarGlobalCreateConfig) {
  return function SidebarGlobalCreate() {
    const resourceTypes = config.useResourceTypes();
    const can = config.useRestrictedAccess();
    const openV1Modal = config.useV1Modal();
    const openV2Modal = config.useV2Modal();
    const docusignIntegration = config.useDocusignIntegration();

    const resolveActions = ({ value }) => {
      if (value.includes('resourceType')) {
        const resourceTypeId = parseInt(value.split('-')[1]);
        return openV1Modal('ResourceCreateModal', {
          resource_type_id: resourceTypeId
        });
      }

      switch (value) {
        case 'new-production':
          return openV1Modal('ProductionSetUpModal', { create: true });
        case 'new-shortlist':
          return openV1Modal('ShortlistCreateModal', { create: true });
        case 'new-booking':
          return openV1Modal('ResourceEventsModal');
        case 'new-callsheet':
          return openV1Modal('CallsheetCreateModal', { create: true });
        case 'new-callout':
          return openV1Modal('CalloutCreateModal', { create: true });
        case 'new-docusign-request':
          return openV2Modal(() => <SplashlightDocuSignModal />);
        default:
          return;
      }
    };
    const shouldShowCreateResourcesGroup =
      (resourceTypes?.length ?? 0) !== 0 &&
      can(FeatureFlag.RESOURCES, Capability.RESOURCE_CREATE);
    const shouldShowCreateEntitiesGroup =
      can(FeatureFlag.PRODUCTIONS, Capability.PRODUCTION_CREATE) ||
      can(FeatureFlag.SHORTLISTS, Capability.SHORTLIST_CREATE) ||
      can(FeatureFlag.BOOKINGS, Capability.BOOKING_CREATE) ||
      can(FeatureFlag.CALLSHEETS, Capability.CALLSHEET_CREATE) ||
      can(FeatureFlag.CALLOUTS, Capability.CALLOUT_CREATE);
    return (
      <MenuRoot
        closeOnSelect
        positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}
        onSelect={resolveActions}
      >
        <MenuTrigger className="text-left flex bg-gradient-to-r from-eggplant-lighter/40 shadow-xs to-eggplant w-full text-white/80 items-center block rounded-md border-eggplant-lightest/40 p-2 text-eggplant-lightest ring-1 hover:ring-eggplant-lightest/60 focus:ring-2 ring-eggplant-lightest/40">
          <span className="flex-0 mr-2">
            <Icon name="add" />
          </span>
          <span className="flex-1 truncate">Create</span>
        </MenuTrigger>
        <Portal
          container={{ current: document.getElementById('tailwind-portals')! }}
        >
          <MenuPositioner className="shadow-xl tailwind-root">
            <MenuContent className="z-10 bg-eggplant w-full w-[200px] max-h-[65vh] overflow-y-auto border-1 border-solid border-eggplant-lighter/60 text-white p-4 rounded-lg">
              <Separated divider={<SidebarSeparator className="my-2" />}>
                <Separated.Group>
                  {shouldShowCreateResourcesGroup && (
                    <MenuItemGroup id="create-resources" className="mb-2">
                      <MenuItemGroupLabel className="text-eggplant-lightest text-base w-full">
                        <div className="flex">
                          <div className="truncate flex-1">New resource</div>
                        </div>
                      </MenuItemGroupLabel>
                      {resourceTypes.map(resourceType => (
                        <MenuItem
                          asChild
                          value={`resourceType-${resourceType.id}`}
                        >
                          <SidebarButton
                            icon={
                              // todo: Icons need revisiting when we have a proper icon set for users to choose from
                              <img
                                src={`/images/${
                                  RESOURCE_TYPE_ICONS[resourceType.icon].white
                                }`}
                                alt={resourceType.name}
                                width="12px"
                                className="opacity-60"
                              />
                            }
                          >
                            {resourceType.name}
                          </SidebarButton>
                        </MenuItem>
                      ))}
                    </MenuItemGroup>
                  )}
                </Separated.Group>

                <Separated.Group>
                  {shouldShowCreateEntitiesGroup && (
                    <MenuItemGroup id="create-entities">
                      <MenuItemGroupLabel className="text-eggplant-lightest text-base w-full">
                        <div className="flex">
                          <div className="truncate flex-1">New</div>
                        </div>
                      </MenuItemGroupLabel>
                      {can(
                        FeatureFlag.PRODUCTIONS,
                        Capability.PRODUCTION_CREATE
                      ) && (
                        <MenuItem asChild value="new-production">
                          <SidebarButton
                            keyboardShortcut="P"
                            icon={<Icon name="v4_project" />}
                          >
                            Production
                          </SidebarButton>
                        </MenuItem>
                      )}
                      {can(
                        FeatureFlag.SHORTLISTS,
                        Capability.SHORTLIST_CREATE
                      ) && (
                        <MenuItem asChild value="new-shortlist">
                          <SidebarButton
                            keyboardShortcut="S"
                            icon={<Icon name="v4_shortlist" />}
                          >
                            Shortlist
                          </SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.BOOKINGS, Capability.BOOKING_CREATE) && (
                        <MenuItem asChild value="new-booking">
                          <SidebarButton
                            keyboardShortcut="B"
                            icon={<Icon name="v4_booking" />}
                          >
                            Booking
                          </SidebarButton>
                        </MenuItem>
                      )}
                      {can(
                        FeatureFlag.CALLSHEETS,
                        Capability.CALLSHEET_CREATE
                      ) && (
                        <MenuItem asChild value="new-callsheet">
                          <SidebarButton
                            keyboardShortcut="C"
                            icon={<Icon name="v4_callsheet" />}
                          >
                            Callsheet
                          </SidebarButton>
                        </MenuItem>
                      )}
                      {can(FeatureFlag.CALLOUTS, Capability.CALLOUT_CREATE) && (
                        <MenuItem asChild value="new-callout">
                          <SidebarButton
                            keyboardShortcut="O"
                            icon={<Icon name="v4_callout" />}
                          >
                            Callout
                          </SidebarButton>
                        </MenuItem>
                      )}
                      {can(
                        FeatureFlag.CUSTOM_SPLASHLIGHT,
                        Capability.DOCUSIGN_SEND,
                        null
                      ) &&
                        docusignIntegration?.enabled && (
                          <MenuItem asChild value="new-docusign-request">
                            <SidebarButton
                              keyboardShortcut="O"
                              icon={<Icon name="v4_callsheet" />}
                            >
                              Send DocuSign Request
                            </SidebarButton>
                          </MenuItem>
                        )}
                    </MenuItemGroup>
                  )}
                </Separated.Group>
              </Separated>
            </MenuContent>
          </MenuPositioner>
        </Portal>
      </MenuRoot>
    );
  };
}
