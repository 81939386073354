import { Channel } from './channel';

/**
 * Creates a paired duplex channel.
 * Both `channelA` and `channelB` process the same type of message.
 *
 * - `local` reads from `channelA` and writes to `channelB`
 * - `remote` reads from `channelB` and writes to `channelA`
 */
export function createPairedDuplexChannel<AMessage, BMessage>(
  channelA: Channel<AMessage, AMessage>,
  channelB: Channel<BMessage, BMessage>
) {
  return {
    local: createLinkedChannel(channelA, channelB),
    remote: createLinkedChannel(channelB, channelA)
  };
}

/**
 * Creates a linked channel that reads from one channel and writes to another.
 */
function createLinkedChannel<ReadMessage, WriteMessage>(
  readChannel: Channel<ReadMessage, ReadMessage>,
  writeChannel: Channel<WriteMessage, WriteMessage>
): Channel<ReadMessage, WriteMessage> {
  return {
    wait: readChannel.wait,
    emit: writeChannel.emit,
    signal: readChannel.signal,
    close: () => {
      readChannel.close();
      writeChannel.close();
    },
    addListener: readChannel.addListener,
    [Symbol.asyncIterator]: () => readChannel[Symbol.asyncIterator]()
  };
}
