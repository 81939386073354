import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { displayAlert } from 'store/v1/ui/ui.actions';
import { selectModalData } from 'store/v1/ui/ui.selectors';
import {
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper
} from 'v1/components/shared';
import { PressStud } from 'v1/components/shared/buttons';
import { callsheets } from '../../../../../store/v1/callsheets/callsheets.hooks.connected';
import { ShareCallsheetNote } from './ShareCallsheetNote';

interface ShareCallsheetModalProps {
  onRequestClose: Function;
}

interface ShareCallsheetModalOptions {
  action: string;
  title: string;
  text: string;
  button: string;
  callsheet_id: number;
  recipient_ids: number[];
}

export const ShareCallsheetModal = (props: ShareCallsheetModalProps) => {
  const dispatch = useDispatch();
  const options: ShareCallsheetModalOptions = useSelector(selectModalData);
  const [note, setNote] = useState<string | null>(null);

  const sendMutation = callsheets.useSendMutation(
    () => {
      dispatch(displayAlert('success', 'Callsheets sent'));
      props.onRequestClose();
    },
    () => {
      dispatch(displayAlert('error', 'Cannot send callsheets'));
    }
  );

  const handleSubmit = () => {
    sendMutation.mutate({
      id: options.callsheet_id,
      recipient_ids: options.recipient_ids,
      note
    });
  };

  const handleNoteChange = (value: string) => setNote(value);

  return (
    <ModalWrapper size="XS">
      <ModalContent>
        <ModalScrollable padding="L">
          <span className="text-11-700-eggplant">CONFIRM</span>
          <h2 className="stack-S">{options.title}</h2>
          <h3 className="stack-S">{options.text}</h3>
          <ShareCallsheetNote value={note} onChange={handleNoteChange} />
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            action={props.onRequestClose}
          />
          <PressStud
            label={options.button}
            appearance="primary"
            isLoading={sendMutation.isPending}
            action={handleSubmit}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};
