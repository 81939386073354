import { useFocusWithin } from '@react-aria/interactions';
import React, { ChangeEvent } from 'react';
import { InputProps } from '../../design-sytem/Input';
import { assert } from '../../platform/lang/assert';

import { MeasurementOperator, OperatorUnitValue } from './fieldTypes';
import { Select } from '@radix-ui/react-select';
import {
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../design-sytem/Select';
import {
  InputGroup,
  InputGroupInput,
  InputGroupSlot
} from '../../design-sytem/InputGroup';

export interface MeasurementsFilterFieldProps {
  value: OperatorUnitValue;
  onChange: (value: OperatorUnitValue) => void;
  onBlurWithin?: () => void;
  size: InputProps['size'];
  variant: InputProps['variant'];
}

const operatorOptions = [
  { label: 'Equals', value: 'eq' },
  { label: 'Greater than', value: 'gt' },
  { label: 'Less than', value: 'lt' }
];

const unitOptions = [
  { label: 'Metric (cm)', value: 'cm' },
  { label: 'Metric (m)', value: 'm' },
  { label: 'Imperial (ft)', value: 'ft' },
  { label: 'Imperial (in)', value: 'in' }
];

function tryRestoreAmount(value: OperatorUnitValue): string {
  switch (value.unit) {
    case 'cm':
    case 'm':
    case 'in':
      return value.amount;
    case 'ft':
      return value.feet;
    default:
      return '';
  }
}

export function MeasurementsFilterField({
  value,
  onChange,
  onBlurWithin,
  size,
  variant
}: MeasurementsFilterFieldProps) {
  const handleOperatorChange = (newOperator: string) => {
    onChange({ ...value, operator: newOperator as MeasurementOperator });
  };

  const handleUnitChange = (newUnit: string) => {
    if (newUnit === 'ft') {
      onChange({
        operator: value.operator,
        unit: 'ft',
        feet: tryRestoreAmount(value),
        inches: '0'
      });
    } else {
      onChange({
        operator: value.operator,
        unit: newUnit as 'cm' | 'm' | 'in',
        amount: tryRestoreAmount(value)
      });
    }
  };

  const handleAmountChange = (e: ChangeEvent<HTMLInputElement>) => {
    assert('amount' in value);
    onChange({ ...value, amount: e.target.value });
  };

  const handleFeetChange = (e: ChangeEvent<HTMLInputElement>) => {
    assert('feet' in value);
    onChange({ ...value, feet: e.target.value });
  };

  const handleInchesChange = (e: ChangeEvent<HTMLInputElement>) => {
    assert('inches' in value);
    onChange({ ...value, inches: e.target.value });
  };

  const { focusWithinProps } = useFocusWithin({ onBlurWithin });

  return (
    <div className="@container">
      <div
        {...focusWithinProps}
        className="flex w-full items-center gap-2 @max-[400px]:grid @max-[200px]:flex @max-[250px]:flex-col @max-[250px]:items-stretch"
      >
        <Select value={value.operator} onValueChange={handleOperatorChange}>
          <SelectTrigger
            className="shrink-0 w-auto min-w-[120px] col-span-3"
            size={size}
            variant={variant}
          >
            <SelectValue placeholder="Operator" />
          </SelectTrigger>
          <SelectContent>
            {operatorOptions.map(opt => (
              <SelectItem key={opt.value} value={opt.value}>
                {opt.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>

        {value.unit === 'ft' ? (
          <>
            <InputGroup className="flex-1" size={size} variant={variant}>
              <InputGroupInput value={value.feet} onChange={handleFeetChange} />
              <InputGroupSlot>ft</InputGroupSlot>
            </InputGroup>

            <InputGroup size={size} variant={variant} className="flex-1">
              <InputGroupInput
                value={value.inches}
                onChange={handleInchesChange}
              />
              <InputGroupSlot>in</InputGroupSlot>
            </InputGroup>
          </>
        ) : (
          <InputGroup
            size={size}
            variant={variant}
            className="flex-1 col-span-2"
          >
            <InputGroupInput
              value={value.amount}
              onChange={handleAmountChange}
            />
            <InputGroupSlot>{value.unit}</InputGroupSlot>
          </InputGroup>
        )}

        <Select value={value.unit} onValueChange={handleUnitChange}>
          <SelectTrigger
            className="shrink-0 w-auto min-w-[120px]"
            size={size}
            variant={variant}
          >
            <SelectValue placeholder="Unit" />
          </SelectTrigger>
          <SelectContent>
            {unitOptions.map(opt => (
              <SelectItem key={opt.value} value={opt.value}>
                {opt.label}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
