import { HTMLAttributes } from 'react';
import { cn } from 'v5/platform/dom/cn';

function Skeleton({ className, ...props }: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn(
        'animate-pulse rounded-md bg-bg-subtle',
        className
      )}
      {...props}
    />
  );
}

export { Skeleton };
