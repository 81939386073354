import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Form } from 'react-form';

import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { SHORTLISTS_ACTIONS } from 'store/v1/shortlists/shortlists.constants';
import {
  createShortlist,
  updateShortlist
} from 'store/v1/shortlists/shortlists.actions.js';

import {
  Label,
  ModalContent,
  ModalNavigation,
  ModalScrollable,
  ModalWrapper,
  PressStud,
  TextInputField
} from 'v1/components/shared';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

import useEvent from 'v1/helpers/hooks/useEvent';

import './ShortlistCreateModal.scss';
import { TagsField } from '~/v5/byModel/tags/TagsField';
import { myServices } from '../../../../../v5/services/services.connected.app';

const ShortlistCreateModal = ({ onRequestClose }) => {
  const dispatch = useDispatch();
  let history = useHistory();
  const { app_id } = useSelector(state => state.auth);
  const { data = {} } = useSelector(state => state.ui);
  const shortlists = useSelector(state => state.shortlists);

  const [formApi, setFormApi] = useState({});
  const isCreate = data.create;
  const [shortlist, setShortlist] = useState(
    data.shortlist || { title: '', tags: [] }
  );

  const { loading } = shortlists;
  const { title, tags } = shortlist;

  useEvent(
    [SHORTLISTS_ACTIONS.CREATE_SHORTLIST, SHORTLISTS_ACTIONS.UPDATE_SHORTLIST],
    {
      onSuccess: () => {
        formApi.resetAll();

        if (isCreate) {
          const id = shortlists.new.id;
          history.push(`/app/${app_id}/shortlists/${id}`);
        }
        onRequestClose();
      },
      onFailure: () => dispatch(displayAlert('error', shortlists.error.message))
    }
  );

  function handleSubmit(values) {
    isCreate
      ? dispatch(createShortlist(values))
      : dispatch(updateShortlist(shortlist.id, values));
  }

  return (
    <ModalWrapper size="S">
      <ModalHeader title={isCreate ? 'Create shortlist' : 'Edit shortlist'} />
      <ModalContent>
        <ModalScrollable padding="L">
          <Form
            onSubmit={handleSubmit}
            defaultValues={{
              title,
              tags,
              ...(isCreate ? shortlist : {})
            }}
            getApi={api => setFormApi(api)}
            validateOnSubmit
          >
            {formApi => {
              return (
                <form
                  onSubmit={formApi.submitForm}
                  id="shortlist-create-form"
                  className="tailwind-root"
                >
                  <TextInputField
                    label="Title"
                    field="title"
                    placeholder="Shortlist title"
                    validate="required"
                    initialFocus
                    removeAutocomplete
                  />
                  <Label className={'mt-5 mb-1.5'} htmlFor={'tags'}>
                    Tags
                  </Label>
                  <TagsField
                    services={myServices}
                    tagType={'shortlist'}
                    onAdd={x => {
                      formApi.setValue('tags', [...formApi.values.tags, x]);
                    }}
                    draggable={false}
                    data={formApi.values?.tags?.map(x => x.tag) || []}
                    mode={'edit'}
                    onRemove={a => {
                      formApi.setValue(
                        'tags',
                        formApi.values.tags.filter(x => x.id !== a.id)
                      );
                    }}
                    createButtonOnNewLine={true}
                  />
                </form>
              );
            }}
          </Form>
        </ModalScrollable>
        <ModalNavigation>
          <PressStud
            label="Cancel"
            appearance="silent"
            hasMarginRight
            action={onRequestClose}
          />
          <PressStud
            label={isCreate ? 'Create' : 'Save changes'}
            appearance="primary"
            isLoading={
              loading === 'CREATE_SHORTLIST' || loading === 'UPDATE_SHORTLIST'
            }
            dataCy="save"
            action={() => formApi.submitForm()}
          />
        </ModalNavigation>
      </ModalContent>
    </ModalWrapper>
  );
};

export default ShortlistCreateModal;
