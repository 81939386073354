'use client';
import {
  MenuRoot,
  MenuContent,
  MenuItem,
  MenuItemGroup,
  MenuItemGroupLabel,
  MenuPositioner,
  MenuTrigger,
  Portal
} from '@ark-ui/react';
import { Icon } from 'shared';
import Avatar from 'v4/shared/components/Avatar/Avatar';

import { Account } from '__types__';
import { parseInitials } from 'v1/helpers/misc';
import SidebarButton from './SidebarButton';
import { useHistory } from 'react-router-dom';
import { withinSettings } from 'v4/core/appRoutes';

interface SidebarAccountDropdownProps {
  brandImage?: string;
  companyName: string;
  appId: string;
  account?: Account;
  onLogout?: () => void;
}

const SidebarAccountDropdown = ({
  brandImage,
  companyName,
  account,
  onLogout
}: SidebarAccountDropdownProps) => {
  const history = useHistory();
  const to = withinSettings();

  const resolveActions = ({ value }) => {
    switch (value) {
      case 'settings':
        return history.push(to.settings.home());
      case 'account-settings':
        return history.push(to.settings.account.details());
      case 'logout':
        return onLogout && onLogout();
      default:
        return;
    }
  };

  return (
    <MenuRoot
      closeOnSelect
      positioning={{ gutter: 8, placement: 'right-end', sameWidth: true }}
      onSelect={resolveActions}
    >
      <MenuTrigger className="rounded-md w-full flex items-center text-left p-2  hover:bg-eggplant-lighter/30 hover:text-white/100  focus:outline-none focus:ring-2 ring-eggplant-lightest/60">
        <span className="flex-0 mr-3">
          {brandImage ? (
            <Avatar
              src={brandImage}
              fallback={parseInitials(companyName)}
              size="sm"
            />
          ) : (
            <Icon name="star" />
          )}
        </span>
        <span className="flex-1 truncate">{companyName}</span>
        <Icon name="chevronDown" size="m" />
      </MenuTrigger>
      <Portal
        container={{ current: document.getElementById('tailwind-portals')! }}
      >
        <MenuPositioner className="z-10! shadow-xl">
          <MenuContent className="bg-eggplant border-1 border-solid border-eggplant-lighter/60 text-white p-4 rounded-lg">
            <MenuItemGroup id="account-settings" className="mb-2">
              <MenuItemGroupLabel className="text-eggplant-lightest text-base">
                <div className="truncate">{companyName}</div>
              </MenuItemGroupLabel>
              <MenuItem asChild value="settings">
                <SidebarButton icon={<Icon name="v4_cog" />}>
                  Settings
                </SidebarButton>
              </MenuItem>
            </MenuItemGroup>
            {account && (
              <MenuItemGroup id="account-settings">
                <MenuItemGroupLabel className="text-eggplant-lightest text-base">
                  Your account
                </MenuItemGroupLabel>
                <MenuItem asChild value="account-settings">
                  <SidebarButton
                    icon={
                      <Avatar
                        src={account.profileImage}
                        fallback={parseInitials(
                          account?.firstName,
                          account?.lastName,
                          account?.email
                        )}
                        size="sm"
                        className="bg-white/40"
                      />
                    }
                  >
                    {account.displayName}
                  </SidebarButton>
                </MenuItem>
                <MenuItem asChild value="logout">
                  <SidebarButton
                    icon={<Icon name="v4_logout" />}
                    onClick={onLogout}
                  >
                    Log out
                  </SidebarButton>
                </MenuItem>
              </MenuItemGroup>
            )}
          </MenuContent>
        </MenuPositioner>
      </Portal>
    </MenuRoot>
  );
};

export default SidebarAccountDropdown;
