import {
  maybe,
  Maybe,
  mt,
  MutationData,
  rd,
  RemoteData
} from '@passionware/monads';
import { ensureError } from '@passionware/platform-js';
import { useSimpleEventSubscription } from '@passionware/simple-event';
import { SimpleStore, useSimpleStore } from '@passionware/simple-store';
import { AlertCircle, CornerDownLeft } from 'lucide-react';
import { AnimatePresence, motion } from 'motion/react';
import { ReactNode } from 'react';
import { useForm } from 'react-hook-form';
import { Alert, AlertDescription, AlertTitle } from 'v5/design-sytem/Alert';
import { CustomFieldType, FeatureFlag } from '../../../../__types__';
import { Button } from '../../../design-sytem/Button';
import { Checkbox } from '../../../design-sytem/Checkbox';
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle
} from '../../../design-sytem/Dialog';
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormRootElement
} from '../../../design-sytem/Form';
import { Input } from '../../../design-sytem/Input';
import { Kbd } from '../../../design-sytem/Kbd';
import { LoadingSpinner } from '../../../design-sytem/LoadingSpinner';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../design-sytem/Select';
import { Separator } from '../../../design-sytem/Separator';
import { ModifierKey } from '../../../primitives/ModifierKey';
import {
  FieldDefinition,
  FieldFormModel,
  formFieldModel
} from './field-definition';
import { fields } from './fields';
import { MeasurementFieldConfig } from './fields/MeasurementFieldConfig';
import { SingleSelectFieldConfig } from './fields/SingleSelectFieldConfig';
import { CreateFieldSkeleton } from './skeleton';

export interface CustomFieldConfigDialogProps {
  features: Maybe<FeatureFlag[]>;
  /**
   * Simple store - because we want some reactivity to initial value
   * RemoteData - because we want to understand that default data is being loaded, and we can show a skeleton or loading error
   * Maybe - to support creating empty field from scratch
   * FieldDefinition - because we want to work with field definition to initialize the form
   */
  resetStore: SimpleStore<RemoteData<Maybe<FieldDefinition>>>;
  onSubmit: (data: Maybe<FieldDefinition>) => void;
  onCancel: () => void;
  open: boolean;
  mode: 'create' | 'edit' | null;
  mutation: MutationData<unknown, unknown>;
  prompt: ReactNode;
  disabledFields?: (keyof FieldFormModel)[];
  hideDisabledFieldTypeOptions?: boolean;
}

const extraConfig = {
  [CustomFieldType.MEASUREMENT_NEW]: {
    key: 'measurement',
    Component: MeasurementFieldConfig
  },
  [CustomFieldType.SINGLE_SELECT]: {
    key: 'select',
    Component: SingleSelectFieldConfig
  },
  [CustomFieldType.MULTI_SELECT]: {
    key: 'select',
    Component: SingleSelectFieldConfig
  },
  [CustomFieldType.NETSUITE_SUBCLASS]: {
    key: 'select',
    Component: SingleSelectFieldConfig
  }
};

export function CustomFieldConfigDialog(props: CustomFieldConfigDialogProps) {
  const initialField = useSimpleStore(props.resetStore);
  const form = useForm({
    defaultValues: rd.tryMap(initialField, formFieldModel.fromDefinition)
  });

  useSimpleEventSubscription(props.resetStore.addUpdateListener, value =>
    rd.map(value, value => form.reset(formFieldModel.fromDefinition(value)))
  );

  const handleSubmit = (data: FieldFormModel) => {
    props.onSubmit(formFieldModel.toDefinition(data));
  };

  return (
    <Dialog
      open={props.open}
      onOpenChange={open => {
        if (!open) {
          props.onCancel();
        }
      }}
    >
      <DialogContent className="max-w-(--container-xl)">
        <DialogDescription className="sr-only">
          Custom field configuration
        </DialogDescription>
        <Form {...form}>
          <FormRootElement
            onSubmit={form.handleSubmit(handleSubmit)}
            className="contents"
          >
            <DialogHeader>
              <DialogTitle>
                {props.mode &&
                  { create: 'Create field', edit: 'Edit field' }[props.mode]}
              </DialogTitle>
            </DialogHeader>
            <DialogBody className="space-y-6">
              {rd
                .journey(initialField)
                .wait(<CreateFieldSkeleton />)
                .catch(e => (
                  <Alert variant="destructive">
                    <AlertCircle />
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription>
                      {ensureError(e).message}
                    </AlertDescription>
                  </Alert>
                ))
                .map(initialField => (
                  <>
                    <FormField
                      control={form.control}
                      name="name"
                      rules={{
                        required: 'Name is required'
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Name" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <Separator />
                    <FormField
                      control={form.control}
                      name="type"
                      rules={{
                        required: 'Field type is required'
                      }}
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Field type</FormLabel>
                          <Select
                            onValueChange={field.onChange}
                            defaultValue={maybe.getOrUndefined(field.value)}
                            disabled={props.disabledFields?.includes('type')}
                          >
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select a field type"></SelectValue>
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              {fields
                                .filter(field => {
                                  // we have to show the same field type if field is already chosen
                                  if (initialField?.type === field.type) {
                                    return true;
                                  }
                                  // now, when field is not selected, we want to filter out fields that are hidden by feature flag
                                  const isAllowedByFeatureFlag =
                                    !props.features ||
                                    !field.featureFlag ||
                                    props.features.includes(field.featureFlag);
                                  if (!isAllowedByFeatureFlag) {
                                    return false;
                                  }
                                  // now, if we should hide disabled fields, we should filter them out
                                  const shouldBeHiddenAsDisabled =
                                    props.hideDisabledFieldTypeOptions &&
                                    field.disabled;

                                  return !shouldBeHiddenAsDisabled;
                                })
                                .map(field => {
                                  return (
                                    <SelectItem
                                      key={field.type}
                                      value={field.type}
                                      className="max-w-(--radix-select-trigger-width)"
                                      disabled={field.disabled}
                                    >
                                      <div className="flex flex-row items-center gap-4 p-2">
                                        <img
                                          src={field.icon}
                                          alt={field.label}
                                          className="size-6 group-[.is-trigger]:size-4"
                                        />
                                        <div className="leading-none text-left">
                                          <div>{field.label}</div>
                                          <div className="text-fg-muted group-[.is-trigger]:hidden pt-1">
                                            {field.description}
                                          </div>
                                        </div>
                                      </div>
                                    </SelectItem>
                                  );
                                })}
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                    <AnimatePresence presenceAffectsLayout mode="wait">
                      {maybe.map(form.watch('type'), type => {
                        const config = extraConfig[type];
                        if (!config) return null;
                        return (
                          <motion.div
                            className="space-y-6"
                            key={config.key}
                            initial={{
                              opacity: 0,
                              height: 0,
                              overflow: 'hidden'
                            }}
                            animate={{
                              opacity: 1,
                              height: 'auto',
                              overflow: 'visible'
                            }}
                            exit={{
                              opacity: 0,
                              height: 0,
                              overflow: 'hidden',
                              transition: { ease: 'circIn', duration: 0.15 }
                            }}
                          >
                            <config.Component form={form} />
                          </motion.div>
                        );
                      })}
                    </AnimatePresence>
                    <Separator />
                    <FormField
                      control={form.control}
                      name="isRequired"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <div className="space-y-1 leading-none">
                            <FormLabel>Required field</FormLabel>
                            <FormDescription>
                              Entities with this field will show an error if it
                              is empty
                            </FormDescription>
                          </div>
                        </FormItem>
                      )}
                    />
                    <FormField
                      control={form.control}
                      name="isFilterable"
                      render={({ field }) => (
                        <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
                          <FormControl>
                            <Checkbox
                              checked={field.value}
                              onCheckedChange={field.onChange}
                            />
                          </FormControl>
                          <div className="space-y-1">
                            <FormLabel>Filterable</FormLabel>
                            <FormDescription>
                              Users can search against this field
                            </FormDescription>
                          </div>
                        </FormItem>
                      )}
                    />
                  </>
                ))}
              {props.prompt && (
                <div className="p-4 mt-8 text-sm rounded-md border border-border-accent bg-bg-accent">
                  {props.prompt}
                </div>
              )}
            </DialogBody>
            <DialogFooter>
              <Button
                disabled={[
                  rd.isPending(initialField),
                  !form.formState.isValid,
                  !form.formState.isDirty
                ].some(Boolean)} // "Eager or" needed for correct form state subscription
                type="submit"
              >
                {mt
                  .journey(props.mutation)
                  .initially(null)
                  .during(<LoadingSpinner />)
                  .catch(() => null)
                  .done(null)}
                Save
                <Kbd className="-mr-2">
                  <ModifierKey />
                  <CornerDownLeft />
                </Kbd>
              </Button>
            </DialogFooter>
          </FormRootElement>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
