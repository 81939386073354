import {
  keepPreviousData,
  QueryClient,
  useMutation,
  useMutationState,
  useQuery,
  useQueryClient,
  UseQueryOptions
} from '@tanstack/react-query';
import { Shortlist, Shortlist_normalized } from '__types__/index';
import { shortlistEffectReducer } from 'v4/entities/shortlist/shortlist.effect.reducer';
import { ListQuery, ListResponse } from '../common/common.crud.types';
import { ShortlistsApi } from './shortlist.http';
import { ShortlistListQuery } from './shortlist.types';

type MaybeShortlistId = Shortlist['id'] | undefined; // hooks should support pages that are not associated with any shortlist at given moment
const shortlistKeys = {
  all: () => ['shortlists'] as const,
  lists: () => [...shortlistKeys.all(), 'list'] as const,
  list: (query: unknown) => [...shortlistKeys.lists(), { query }] as const,
  details: () => [...shortlistKeys.all(), 'detail'] as const,
  detail: (id: MaybeShortlistId) =>
    [...shortlistKeys.details(), id === undefined ? id : String(id)] as const
};

type ItemPayloadBase = {
  id: Shortlist['id'];
};

type ItemUpdatePayload = Partial<Shortlist> & ItemPayloadBase;

const invalidateLists = (client: QueryClient) => {
  client.invalidateQueries({
    queryKey: shortlistKeys.lists(),
    type: 'active'
  });
  client.removeQueries({
    queryKey: shortlistKeys.lists(),
    type: 'inactive'
  });
  // todo create generic mechanism to precisely updaate all queries: lists, and all details that are relevant
};

const updateCacheWithItem = (
  client: QueryClient,
  item: Shortlist_normalized
) => {
  client.setQueriesData<
    ListResponse<Shortlist_normalized, ListQuery<Shortlist_normalized>>
  >(
    {
      queryKey: shortlistKeys.lists()
    },
    input => {
      return (
        input && {
          ...input,
          results: input.results.map(shortlist =>
            shortlist.id === item.id ? item : shortlist
          )
        }
      );
    }
  );
  client.invalidateQueries({
    // we need to invalidate because lists can be filtered by changed fields
    type: 'active',
    queryKey: shortlistKeys.lists()
  });
  client.removeQueries({
    type: 'inactive',
    queryKey: shortlistKeys.lists()
  });
  client.setQueryData(shortlistKeys.detail(item.id), item);
};

const invalidateDetails = (client: QueryClient, id: Shortlist['id']) => {
  client.invalidateQueries({
    queryKey: shortlistKeys.detail(id),
    type: 'active'
  });
  client.removeQueries({
    queryKey: shortlistKeys.detail(id),
    type: 'inactive'
  });
};

export const shortlistInternals = {
  keys: shortlistKeys,
  invalidateLists,
  invalidateDetails,
  updateCacheWithItem
};

export const createShortlistsHooks = (api: ShortlistsApi) => {
  const useList = (
    query: ShortlistListQuery,
    /**
     * @experimental - will likely be changed in future
     */
    options?: Pick<
      UseQueryOptions<
        Awaited<ReturnType<typeof api.list>>,
        Error,
        Awaited<ReturnType<typeof api.list>>
      >,
      'placeholderData'
    >
  ) =>
    useQuery({
      queryKey: shortlistKeys.list(query),
      queryFn: () => api.list(query),
      ...options
    });

  const useItem = (id: MaybeShortlistId) => {
    // const client = useQueryClient();
    return useQuery({
      enabled: id !== undefined,
      queryKey: shortlistKeys.detail(id),
      queryFn: () => api.detail(id!),
      retry: false,
      placeholderData: keepPreviousData
      // initialData: () => { // TODO we can't reuse this, it misses pins[] // TODO bring back one we have resource__shortlist association because we will not read blocks (with pins) from shortlist anyway
      //   const queriesData = client.getQueriesData<
      //     ListResponse<Shortlist_normalized, ShortlistListQuery> // todo migration: we are pretending that we don't have shortlist_blocks here, but we do
      //   >({ queryKey: shortlistKeys.lists() });
      //   return queriesData
      //     .find(
      //       ([key, list]) => list?.results.find(item => item.id === id)
      //     )?.[1]
      //     ?.results.find(item => item.id === id);
      // }
    });
  };

  const ensureItem = (client: QueryClient, id: MaybeShortlistId) => {
    return client.ensureQueryData({
      queryKey: shortlistKeys.detail(id),
      queryFn: () => api.detail(id!)
    });
  };

  const useEnsureItem = () => {
    const client = useQueryClient();
    return (id: MaybeShortlistId) => ensureItem(client, id);
  };

  const useItemUpdateMutation = () => {
    const client = useQueryClient();

    return useMutation({
      mutationFn: (payload: ItemUpdatePayload) =>
        api.detailUpdate(payload.id, payload),
      mutationKey: shortlistKeys.details(),
      onSuccess: (response, variables, context) => {
        updateCacheWithItem(client, response);
      },
      onMutate: ({ id, ...payload }) => {
        return {
          reducer: (oldData: Shortlist) => {
            return shortlistEffectReducer(oldData, {
              type: 'update',
              payload
            });
          }
        };
      }
    });
  };

  const useItemMutationState = (id: Shortlist['id'] | undefined) => {
    return useMutationState<ItemPayloadBase>({
      filters: {
        mutationKey: shortlistKeys.details(),
        status: 'pending',
        // @ts-expect-error todo check if this is correct against newer version of Tanstack
        predicate: mutation => mutation.state.variables?.id === id
      }
    });
  };

  return {
    useList,
    useItem,
    ensureItem,
    useEnsureItem,
    useItemUpdateMutation,
    useItemMutationState
  };
};

export type ShortlistHooks = ReturnType<typeof createShortlistsHooks>;
