import {
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Grip, Plus, X } from 'lucide-react';
import { ReactNode, useRef } from 'react';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { Button } from 'v5/design-sytem/Button';
import { Input, inputVariants } from 'v5/design-sytem/Input';
import {
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../../../design-sytem/Form';
import { cn } from '../../../../platform/dom/cn';

export interface MeasurementFieldConfig {
  form: UseFormReturn;
}

interface SortableItemProps {
  id: string;
  render: (
    props: ReturnType<typeof useSortable> & {
      style: object; // todo style
      dragRef: React.MutableRefObject<HTMLElement | null>;
    }
  ) => ReactNode;
}
function SortableItem({ id, render }: SortableItemProps) {
  const props = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(props.transform),
    transition: props.transition
  };

  const dragRef = useRef<HTMLElement | null>(null);

  return render({ ...props, style, dragRef });
}

export function SingleSelectFieldConfig(props: MeasurementFieldConfig) {
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control: props.form.control,
      name: 'select.options'
    }
  );

  const addButtonRef = useRef<HTMLButtonElement | null>(null);

  const handleRemove = (index: number) => {
    remove(index);
    requestAnimationFrame(() => {
      if (fields.length === 1) {
        // select add option input
        addButtonRef.current?.focus();
      } else if (index === fields.length - 1) {
        props.form.setFocus(`select.options.${index - 1}.value`);
      } else {
        props.form.setFocus(`select.options.${index}.value`);
      }
    });
  };
  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 3
      }
    }),
    useSensor(KeyboardSensor, {
      // todo: we need custom logic to keep swapping only adjacent elements
      coordinateGetter: sortableKeyboardCoordinates,
      keyboardCodes: {
        start: ['Space', 'ArrowUp', 'ArrowDown'],
        end: ['Space', 'Enter'],
        cancel: ['Escape']
      }
    })
  );

  return (
    <div className="space-y-3">
      <FormField
        control={props.form.control}
        name="select.options"
        rules={{ required: 'Field type is required' }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Options</FormLabel>
            <FormMessage />
          </FormItem>
        )}
      />
      <DndContext
        sensors={sensors}
        modifiers={[restrictToVerticalAxis]}
        onDragEnd={({ active, over }) => {
          if (active.id !== over?.id) {
            move(
              fields.findIndex(field => field.id === active.id),
              fields.findIndex(field => field.id === over?.id)
            );
          }
        }}
      >
        <SortableContext items={fields} strategy={verticalListSortingStrategy}>
          {fields.map((field, index) => {
            const registered = props.form.register(
              `select.options.${index}.value`
            );
            return (
              <SortableItem
                key={field.id}
                id={field.id}
                render={bag => (
                  <div
                    className={cn(
                      'flex flex-row gap-0 w-full group items-center'
                    )}
                    data-dragging={bag.isDragging}
                    key={field.id}
                    style={bag.style}
                    {...bag.attributes}
                    ref={bag.setNodeRef}
                    tabIndex={-1}
                  >
                    <div
                      className={cn(
                        inputVariants({
                          variant: 'inline',
                          className: 'w-auto p-0'
                        })
                      )}
                    >
                      <button
                        type="button"
                        data-drag-handle
                        className="w-full p-2 h-full self-center focus:text-fg-default text-border-default group-hocus:text-fg-muted transition-all focus-visible:bg-bg-accent"
                        ref={instance => {
                          bag.setActivatorNodeRef(instance);
                          bag.dragRef.current = instance;
                        }}
                        {...bag.listeners}
                        tabIndex={-1}
                      >
                        <Grip className="size-4" />
                      </button>
                    </div>
                    <Input
                      variant="inline"
                      className="w-full"
                      placeholder="Enter an option"
                      {...registered}
                      onKeyDown={e => {
                        if (
                          e.key === 'ArrowLeft' &&
                          e.currentTarget.selectionStart === 0 &&
                          e.currentTarget.selectionEnd === 0
                        ) {
                          e.preventDefault();
                          bag.dragRef.current?.focus();
                          return;
                        }
                        if (e.key === 'Enter') {
                          if (e.metaKey || e.ctrlKey) {
                            return;
                          }
                          if (e.currentTarget.value !== '') {
                            append({ value: '' });
                          }
                          e.preventDefault();
                        }
                        if (
                          e.key === 'Backspace' &&
                          (e.metaKey ||
                            e.ctrlKey ||
                            e.currentTarget.value === '')
                        ) {
                          handleRemove(index);
                        }
                      }}
                    />
                    <div
                      className={cn(
                        inputVariants({
                          variant: 'inline',
                          className: 'w-auto p-0'
                        })
                      )}
                    >
                      <Button
                        tabIndex={-1}
                        data-delete-button
                        variant="ghost"
                        shape="rounded"
                        layout="icon"
                        size="sm"
                        onClick={() => {
                          handleRemove(index);
                        }}
                        className="opacity-0 group-hocus:opacity-100"
                      >
                        <X />
                      </Button>
                    </div>
                  </div>
                )}
              />
            );
          })}
        </SortableContext>
      </DndContext>
      <Button
        ref={addButtonRef}
        size="sm"
        className="mt-6"
        variant="secondary"
        onClick={() => {
          append({ value: '' });
        }}
      >
        <Plus />
        Add option
      </Button>
    </div>
  );
}
