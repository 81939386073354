import { maybe, Maybe } from '@passionware/monads';
import { UnitValue } from './UnitField';

function formatNumber(num: number): string {
  return parseFloat(num.toFixed(2)).toString();
}

export const unitFieldUtils = {
  /**
   * Convert unit value from system (imperial) to given unit
   * @param value value in feet, should be converted to given unit
   * @param unit target unit to convert to
   */
  getUnitValueFromImperial(
    value: Maybe<number>,
    unit: UnitValue['unit']
  ): UnitValue {
    if (maybe.isAbsent(value)) {
      switch (unit) {
        case 'cm':
        case 'm':
        case 'in':
          return { unit, amount: '' };
        case 'ft':
          return { unit, feet: '', inches: '' };
      }
    }
    if (isNaN(value) || !isFinite(value)) {
      return unitFieldUtils.getUnitValueFromImperial(0, unit);
    }

    switch (unit) {
      case 'cm': {
        const amount = formatNumber(value * 30.48); // 1 ft = 30.48 cm
        return { unit: 'cm', amount };
      }
      case 'm': {
        const amount = formatNumber(value * 0.3048); // 1 ft = 0.3048 m
        return { unit: 'm', amount };
      }
      case 'in': {
        const amount = formatNumber(value * 12); // 1 ft = 12 in
        return { unit: 'in', amount };
      }
      case 'ft': {
        const feet = Math.floor(value);
        const inches = formatNumber((value - feet) * 12);
        return { unit: 'ft', feet: feet.toString(), inches };
      }
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  },
  /**
   * Convert unit value from a given metric unit to feet
   * @param value value in the given unit, should be converted to feet
   * @param unit source unit to convert from
   */
  getImperialValueFromUnit(value: number, unit: UnitValue['unit']): number {
    if (isNaN(value) || !isFinite(value)) {
      return 0;
    }
    switch (unit) {
      case 'cm': {
        return value / 30.48;
      }
      case 'm': {
        return value / 0.3048;
      }
      case 'in': {
        return value / 12;
      }
      case 'ft': {
        return value;
      }
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  },
  getImperialValueFromUnitValue(value: UnitValue) {
    switch (value.unit) {
      case 'cm':
      case 'm':
      case 'in': {
        return this.getImperialValueFromUnit(
          parseFloat(value.amount),
          value.unit
        );
      }
      case 'ft': {
        return parseFloat(value.feet) + parseFloat(value.inches) / 12;
      }
      default:
        throw new Error('Unsupported unit');
    }
  },
  formatDisplay: (value: UnitValue) => {
    switch (value.unit) {
      case 'ft': {
        const feetDisplay = value.feet || '';
        const inchesDisplay = value.inches || '';
        if (feetDisplay && inchesDisplay) {
          return `${feetDisplay}' ${inchesDisplay}"`;
        } else if (feetDisplay) {
          return `${feetDisplay}'`;
        } else if (inchesDisplay) {
          return `${inchesDisplay}"`;
        }
        return '';
      }
      case 'in':
        return value.amount ? `${value.amount}"` : '';
      case 'cm':
        return value.amount ? `${value.amount}cm` : '';
      case 'm':
        return value.amount ? `${value.amount}m` : '';
      default:
        return '--';
    }
  },
  getUnitValueFromDisplayValue: (
    value: number,
    unit: UnitValue['unit']
  ): UnitValue => {
    switch (unit) {
      case 'ft': {
        const feet = Math.floor(value);
        const inches = formatNumber((value - feet) * 12);
        return { unit: 'ft', feet: feet.toString(), inches };
      }
      case 'in': {
        return { unit: 'in', amount: formatNumber(value) };
      }
      case 'cm': {
        return { unit: 'cm', amount: formatNumber(value) };
      }
      case 'm': {
        return { unit: 'm', amount: formatNumber(value) };
      }
      default:
        throw new Error(`Unsupported unit: ${unit}`);
    }
  },
  isUnitValue: (value: unknown): value is UnitValue => {
    if (typeof value !== 'object' || value === null) {
      return false;
    }
    if (
      'unit' in value &&
      'amount' in value &&
      typeof value.unit === 'string' &&
      ['cm', 'm', 'in'].includes(value.unit)
    ) {
      return true;
    }
    return (
      'unit' in value &&
      'feet' in value &&
      'inches' in value &&
      value.unit === 'ft'
    );
  }
};
