import { MessageEventPayload } from '@passionware/messaging-react';
import { maybe, Maybe } from '@passionware/monads';
import { useEffect, useState } from 'react';
import { Channel, createChannel } from './channel';
import { createPairedDuplexChannel } from './duplexChannel';

export function useChannelMessage<
  InitialPayload,
  MessageFromEmitter,
  MessageFromSubscriber
>(
  subscribe: (
    listener: (
      payload: MessageEventPayload<
        InitialPayload,
        Channel<MessageFromSubscriber, MessageFromEmitter>
      >
    ) => void
  ) => () => void,
  options: {
    onConnect?: (
      initialPayload: InitialPayload,
      channel: Channel<MessageFromEmitter, MessageFromSubscriber>
    ) => void | (() => void);
    onMessage?: (
      message: MessageFromEmitter,
      channel: Channel<MessageFromEmitter, MessageFromSubscriber>
    ) => void;
  } = {}
) {
  const [state, setState] = useState<
    Maybe<{
      initialPayload: InitialPayload;
      channel: Channel<MessageFromEmitter, MessageFromSubscriber>;
    }>
  >(null);

  useEffect(() => {
    let duplex: ReturnType<
      typeof createPairedDuplexChannel<
        MessageFromEmitter,
        MessageFromSubscriber
      >
    >;
    let cleanupOnConnect: void | (() => void);
    let unsubscribeMessage: void | (() => void);

    const unsubscribe = subscribe(payload => {
      if (state) {
        console.error(
          `Received a new message while we're still processing the previous one.
          useChannelMessage doesn't support multiple concurrent messages.
          If you see this message, you likely did not finish exising channel communication before starting a new one.
          If you need to handle multiple messages concurrently, we have to implement stacking/queueing of messages.
          For example, if we use this channel messaging for capturing changes via form in a modal, this can mean that we have to stack modals and display/handle them one by one.
          At the moment, we use request-response messaging assuming that there is always a counterparty that is waiting for a request and ready to handle it.
          `
        );
        return;
      }
      duplex = createPairedDuplexChannel<
        MessageFromEmitter,
        MessageFromSubscriber
      >(createChannel(), createChannel());

      // todo: what if we already have pending channel communication? Should we ignore new payloads?

      // we respond with the channel that can be used to receive subsequent messages and send responses to it
      payload.sendResponse(duplex.remote);
      setState({ initialPayload: payload.request, channel: duplex.local });

      // we allow to perform some initial actions, like sending some initial data, or setting up listeners
      cleanupOnConnect = options.onConnect?.(payload.request, duplex.local);

      unsubscribeMessage = maybe.mapOrUndefined(options.onMessage, onMessage =>
        duplex.local.addListener(value => onMessage(value, duplex.local))
      );

      duplex?.local.signal.addEventListener(
        'abort',
        () => {
          setState(null);
          unsubscribeMessage?.();
          cleanupOnConnect?.();
        },
        { once: true }
      );
      return () => {
        // this will be called only when request-response is finalized, here it will be when we send the channel back
        // so our initial conversation is done, and we switched into channel communication
      };
    });

    return () => {
      duplex?.local.close();
      unsubscribeMessage?.();
      cleanupOnConnect?.();
      setState(null);
    };
  }, [subscribe]);

  return state;
}
