import { rd } from '@passionware/monads';
import { injectConfig } from '@passionware/platform-react';
import { Tag, TagType } from '../../../__types__';
import {
  CollectionField,
  CollectionFieldProps
} from '../../common-widgets/CollectionField';
import { useDefaultCreateContent } from '../../common-widgets/ItemSelector';
import { TagsService } from '../../services/io/TagsService/TagsService';

export type TagsFieldProps = Omit<CollectionFieldProps<Tag>, 'config'> & {
  services: {
    tagsService: TagsService;
  };
  tagType: TagType;
};

export const TagsField = injectConfig(CollectionField<Tag>)
  .fromProps<TagsFieldProps>(api => ({
    useOptions: query => {
      const props = api.useProps();
      const tagsMatchingQuery = props.services.tagsService.useTags({
        type: props.tagType,
        search: query
      });

      return rd.useMemoMap(tagsMatchingQuery, x =>
        x.map(x => ({ id: x.id.toString(), label: x.tag }))
      );
    },
    useGetGhostItem: () => {
      const props = api.useProps();
      return query => ({ id: -1, tag: query, tag_type: props.tagType });
    },
    getId: x => x.id.toString(),
    rowRenderer: x => <div>{x.tag}</div>,
    useCreateContent: (query, options) =>
      useDefaultCreateContent(
        query,
        options,
        <>
          Create tag named: <strong>{query}</strong>
        </>
      ),
    useCreatePromise: () => {
      const props = api.useProps();
      return query => {
        return props.services.tagsService.addTag(props.tagType, query);
      };
    },
    promptRenderer: data => (data.length === 0 ? 'Add tag' : 'Add'),
    useResolveById: () => {
      const props = api.useProps();
      return id => {
        return props.services.tagsService.resolveById(
          props.tagType,
          Number(id)
        );
      };
    },
    useSelectedItems: data => {
      const props = api.useProps();
      return props.services.tagsService.useSelectedTags(props.tagType, data);
    }
  }))
  .transformProps(x => x.skipFields('tagType', 'services'));
