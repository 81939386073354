'use client';

import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import * as React from 'react';
import { cn } from 'v5/platform/dom/cn';
import { focusRings } from './_common/focusRing';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      focusRings.focus.visible,
      'peer size-5 shrink-0 rounded-sm border border-border-default disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-accent-emphasis data-[state=checked]:text-fg-default',
      'inset-shadow-xs',
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      forceMount
      className={cn(
        'flex items-center justify-center text-current transition-all duration-100',
        'opacity-0 data-[state=checked]:opacity-100',
        'transform scale-0 data-[state=checked]:scale-100'
      )}
    >
      <Check className="size-5 stroke-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
