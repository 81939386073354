import { ApiClient } from '../../../lib/api/ApiClient.types';
import { parseWithDecoratedError } from '../../../v4/entities/common/parseWithDecoratedError';
import { GlobalSearchApi } from './global-search.api';
import { globalSearch$ } from './global-search.api.http.schema';

export function createGlobalSearchApi(apiClient: ApiClient): GlobalSearchApi {
  return {
    search: async (query, signal) => {
      const data = await apiClient.get('/search/global', {
        params: { query },
        token: true,
        signal
      });
      const parsed = parseWithDecoratedError(globalSearch$, data);

      return Object.values(parsed).flat();
    }
  };
}
