import { myServices } from '../../services/services.connected.app';
import { CustomFieldConfigDialogWidget } from '../custom-fields/config-dialog/CustomFieldConfigDialogWidget';

export function ModalContainerV5() {
  return (
    <>
      <CustomFieldConfigDialogWidget services={myServices} />
    </>
  );
}
