import { maybe, rd } from '@passionware/monads';
import { get } from 'lodash';
import { useSelector } from 'react-redux';
import { StudioService } from './StudioService';

export function createStudioService(): StudioService {
  const api: StudioService = {
    useStudioList: () => {
      const custom_field_definitions = useSelector(state =>
        // @ts-expect-error migrate state.auth to TS
        get(state.auth, 'settings.custom_field_definitions')
      );
      const studioFieldDefinition = custom_field_definitions.find(
        cfd => cfd.name === 'Studio'
      );
      if (!studioFieldDefinition) {
        return rd.ofError(new Error('Studio field definition not found'));
      }
      const studios = studioFieldDefinition.options;
      return rd.of(
        studios.map(studio => ({
          id: studio.id,
          name: studio.value
        }))
      );
    },
    useStudio: (id: number) => {
      const studios = api.useStudioList();
      return rd.map(studios, studios =>
        maybe.getOrThrow(
          studios.find(s => s.id === id),
          'Studio not found'
        )
      );
    }
  };
  return api;
}
