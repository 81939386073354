import React, { ComponentPropsWithRef } from 'react';
import { cn } from '../platform/dom/cn';

export function LoadingSpinner({
  className,
  color = 'currentColor',
  ...props
}: ComponentPropsWithRef<'svg'> & { color?: string }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 50 50"
      fill="none"
      stroke={color}
      stroke-width="5"
      stroke-linecap="round"
      stroke-linejoin="round"
      className={cn('size-5 animate-spin', className)}
      {...props}
    >
      <circle cx="25" cy="25" r="20" strokeOpacity="0.2" />
      <path d="M25 5a20 20 0 0 1 20 20" stroke={color} />
    </svg>
  );
}
