import { Maybe } from '@passionware/monads';
import { ensureError } from '@passionware/platform-js';
import { z } from 'zod';
import { CustomFieldDefinition, CustomFieldType } from '../../../../__types__';
import store from '../../../../store';
import {
  createField,
  updateField
} from '../../../../store/v1/auth/auth.actions';
import { CORE_STRUCTURES } from '../../../../v1/helpers/coreStructures';
import { FieldDefinition } from '../../../features/custom-fields/config-dialog/field-definition';
import { CreateCustomFieldScope, MutationService } from './MutationService';

export function createMutationService(): MutationService {
  return {
    createCustomField: async (payload, scope) => {
      const result = await store.dispatch(
        createField(legacyField.fromDefinition(payload))
      );

      if (result.error) {
        throw ensureError(result.error);
      }
      const customFieldId = z
        .object({
          result: z.object({ custom_field: z.object({ id: z.number() }) })
        })
        .parse(result).result.custom_field;

      if (scope) {
        await addFieldToCoreStructure(result.result.custom_field.id, scope);
      }

      return customFieldId;
    },
    updateCustomField: async (id, payload) => {
      const result = await store.dispatch(
        updateField(id, legacyField.fromDefinition(payload) as any)
      );
      if (result.error) {
        throw ensureError(result.error);
      }
    }
  };
}
type LegacyFieldDefinition = {
  name: string;
  data_type: CustomFieldType;
  filterable: boolean;
  required: boolean;
  options: Maybe<{ value: string; order: number }[]>;
  measurement_unit_lock: boolean;
  measurement_default_unit: string | null;
};

// todo hoist?
export const legacyField = {
  fromDefinition: (definition: FieldDefinition): LegacyFieldDefinition => {
    return {
      name: definition.name,
      data_type: definition.type,
      filterable: definition.isFilterable,
      required: definition.isRequired,
      options:
        `options` in definition
          ? definition.options.map((option, index) => ({
              value: option,
              order: index
            }))
          : undefined,
      measurement_default_unit:
        definition.type === CustomFieldType.MEASUREMENT_NEW
          ? definition.defaultUnit
          : null,
      measurement_unit_lock:
        definition.type === CustomFieldType.MEASUREMENT_NEW
          ? definition.lockUnit
          : false
    };
  },
  toDefinition: (definition: CustomFieldDefinition): FieldDefinition => {
    return {
      name: definition.name,
      type: definition.data_type,
      isFilterable: definition.filterable,
      isRequired: definition.required,
      options: definition.options?.map(option => option.value) ?? [],
      lockUnit: definition.measurement_unit_lock,
      defaultUnit: definition.measurement_default_unit ?? 'ft' // todo: decide where to default this
    };
  }
};

async function addFieldToCoreStructure(
  customFieldId: number,
  scope: CreateCustomFieldScope
) {
  if (scope) {
    const globalState = store.getState();
    const coreStructureType =
      globalState[scope.coreStructure]?.data[scope.coreStructureTypeId];
    if (!coreStructureType) return;
    const updatedStructureType = {
      ...coreStructureType,
      metastructure: {
        ...coreStructureType.metastructure,
        fields: [
          ...(coreStructureType.metastructure.fields || []),
          {
            active: true,
            custom_field_definition_id: customFieldId,
            default: '',
            locked: false,
            required: false,
            type: 'CUSTOM_FIELD'
          }
        ]
      }
    };
    const updateAction = CORE_STRUCTURES[scope.coreStructure].updateAction;
    const result = await store.dispatch(
      updateAction(scope.coreStructureTypeId, updatedStructureType)
    );
    if (result.error) {
      throw ensureError(result.error);
    }
  }
}
