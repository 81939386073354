import { CONTACTS_ACTIONS } from 'store/v1/contacts/contacts.constants.js';
import { pick, has, map, get, omit, mapValues } from 'lodash';

import submitHelper from 'v1/helpers/submitHelper';
import { unitFieldUtils } from '../../../v4/shared/components/forms/input/unit-field-utils.js';

const token = true;

export function searchContacts(query, p, event_id, localStore) {
  if (has(query, 'filters.tags')) {
    const method = query.filters.tags.match ? 'match' : 'any';
    const formattedTags = map(get(query, `filters.tags.${method}`), t =>
      typeof t === 'string' ? t : t.tag
    );
    query.filters.tags = { [method]: formattedTags };
  }
  query = {
    ...query,
    // We must convert all unit values to imperial values before sending to the API
    filters: mapValues(query.filters, value => {
      if (unitFieldUtils.isUnitValue(value.eq)) {
        return {
          ...value,
          eq: unitFieldUtils.getImperialValueFromUnitValue(value.eq)
        };
      } else if (unitFieldUtils.isUnitValue(value.gt)) {
        return {
          ...value,
          gt: unitFieldUtils.getImperialValueFromUnitValue(value.gt)
        };
      } else if (unitFieldUtils.isUnitValue(value.lt)) {
        return {
          ...value,
          lt: unitFieldUtils.getImperialValueFromUnitValue(value.lt)
        };
      }
      return value;
    })
  };
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACTS,
      CONTACTS_ACTIONS.GET_CONTACTS_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACTS_FAILURE
    ],
    promise: client => client.post('/search/contacts', { data: query, token }),
    p,
    query,
    event_id,
    localStore
  };
}

// DUPLICATED CONSTS
export function searchLocations(query, p, event_id) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACTS,
      CONTACTS_ACTIONS.GET_CONTACTS_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACTS_FAILURE
    ],
    promise: client => client.post('/search/contacts', { data: query, token }),
    p,
    query,
    event_id,
    use: 'locations'
  };
}

export function getBatchContacts(ids = [], event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS,
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS_SUCCESS,
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS_FAILURE
    ],
    promise: client =>
      client.post(`/batch/contacts/search`, {
        token,
        data: {
          ids
        }
      }),
    contact_ids: ids,
    event_id
  };
}

export function checkContactDuplicates(contact = {}, event_id = null) {
  const query = {
    query: `${contact.first_name}${
      contact.last_name ? ` ${contact.last_name}` : ''
    }`,
    filters: {
      resource_type_id: { eq: contact.resource_type_id }
    }
  };
  return {
    types: [
      CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES,
      CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES_SUCCESS,
      CONTACTS_ACTIONS.CHECK_CONTACT_DUPLICATES_FAILURE
    ],
    promise: client => client.post('/search/contacts', { data: query, token }),
    event_id
  };
}

export function clearContactDuplicates() {
  return {
    type: CONTACTS_ACTIONS.CLEAR_CONTACT_DUPLICATES
  };
}

// UNUSED
// export function quickSearchContacts(query, p, event_id = null) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.GET_CONTACTS_QUICK,
//       CONTACTS_ACTIONS.GET_CONTACTS_QUICK_SUCCESS,
//       CONTACTS_ACTIONS.GET_CONTACTS_QUICK_FAILURE
//     ],
//     promise: client => client.post('/search/contacts', { data: query, token }),
//     p,
//     event_id
//   };
// }

// UNUSED
export function getSearchSuggestions(query, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS,
      CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS_SUCCESS,
      CONTACTS_ACTIONS.GET_SEARCH_SUGGESTIONS_FAILURE
    ],
    promise: client =>
      client.post(`/search/suggest`, { data: { query }, token }),
    event_id,
    query
  };
}

export function getContact(contact_id) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACT,
      CONTACTS_ACTIONS.GET_CONTACT_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACT_FAILURE
    ],
    promise: client => client.get(`/contacts/${contact_id}`, { token })
  };
}

export function getContactAssociations(contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS,
      CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACT_ASSOCIATIONS_FAILURE
    ],
    promise: client =>
      client.get(`/contacts/${contact_id}/associations`, { token }),
    event_id,
    contact_id
  };
}

/*export function batchGetAvailability(contact_ids) {
  return {
    types: [
      CONTACTS_ACTIONS.BATCH_GET_AVAILABILITY,
      CONTACTS_ACTIONS.BATCH_GET_AVAILABILITY_SUCCESS,
      CONTACTS_ACTIONS.BATCH_GET_AVAILABILITY_FAILURE
    ],
    promise: client =>
      client.get(`/batch/availability`, { data: contact_ids, token }),
    contact_ids
  };
}*/

export function getContactsRepresentees(contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES,
      CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACT_REPRESENTEES_FAILURE
    ],
    promise: client =>
      client.get(`/contacts/${contact_id}/associations/representees`, {
        token
      }),
    event_id,
    contact_id
  };
}

// UNUSED
// export function getContactPins(contact_id, event_id = null) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.GET_CONTACT_PINS,
//       CONTACTS_ACTIONS.GET_CONTACT_PINS_SUCCESS,
//       CONTACTS_ACTIONS.GET_CONTACT_PINS_FAILURE
//     ],
//     promise: client =>
//       client.get(`/contacts/${contact_id}/associations/pins`, { token }),
//     event_id,
//     contact_id
//   };
// }

/*export function getContactAvailability(contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACT_CONTACT_AVAILABILITY,
      CONTACTS_ACTIONS.GET_CONTACT_CONTACT_AVAILABILITY_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACT_CONTACT_AVAILABILITY_FAILURE
    ],
    promise: client =>
      client.get(`/contacts/${contact_id}/associations/availability_requests`, {
        token
      }),
    event_id,
    contact_id
  };
}*/

export function createContactNote(data, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.CREATE_CONTACT_NOTE,
      CONTACTS_ACTIONS.CREATE_CONTACT_NOTE_SUCCESS,
      CONTACTS_ACTIONS.CREATE_CONTACT_NOTE_FAILURE
    ],
    promise: client =>
      client.post(`/contacts/${contact_id}/associations/notes`, {
        token,
        data
      }),
    event_id,
    contact_id
  };
}

export function deleteContactNote(id, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.DELETE_CONTACT_NOTE,
      CONTACTS_ACTIONS.DELETE_CONTACT_NOTE_SUCCESS,
      CONTACTS_ACTIONS.DELETE_CONTACT_NOTE_FAILURE
    ],
    promise: client =>
      client.del(`/contacts/${contact_id}/associations/notes/${id}`, {
        token
      }),
    event_id,
    contact_id,
    association_id: id
  };
}

export function createContactDocument(data, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.CREATE_CONTACT_DOCUMENT,
      CONTACTS_ACTIONS.CREATE_CONTACT_DOCUMENT_SUCCESS,
      CONTACTS_ACTIONS.CREATE_CONTACT_DOCUMENT_FAILURE
    ],
    promise: client =>
      client.post(`/contacts/${contact_id}/associations/documents`, {
        token,
        data
      }),
    event_id,
    contact_id
  };
}

export function deleteContactDocument(id, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT,
      CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT_SUCCESS,
      CONTACTS_ACTIONS.DELETE_CONTACT_DOCUMENT_FAILURE
    ],
    promise: client =>
      client.del(`/contacts/${contact_id}/associations/documents/${id}`, {
        token
      }),
    event_id,
    contact_id,
    association_id: id
  };
}

export function createContactPin(data, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.CREATE_CONTACT_PIN,
      CONTACTS_ACTIONS.CREATE_CONTACT_PIN_SUCCESS,
      CONTACTS_ACTIONS.CREATE_CONTACT_PIN_FAILURE
    ],
    promise: client =>
      client.post(`/contacts/${contact_id}/associations/pins`, {
        token,
        data
      }),
    event_id,
    contact_id
  };
}

export function updateContactPin(
  data,
  { contact_id, pin_id },
  event_id = null
) {
  let toSubmit = pick(data, [
    'id',
    'entity_id',
    'url',
    'pin_type',
    'tags',
    'file_id',
    'order',
    'archived'
  ]);
  let tags = data.tags.map(tag => {
    if (typeof tag.id === 'string' && tag.id.includes('temp')) {
      delete tag.id;
    }
    let formattedTag = pick(tag, ['id', 'tag']);
    return formattedTag;
  });
  toSubmit.tags = tags;
  return {
    types: [
      CONTACTS_ACTIONS.UPDATE_CONTACT_PIN,
      CONTACTS_ACTIONS.UPDATE_CONTACT_PIN_SUCCESS,
      CONTACTS_ACTIONS.UPDATE_CONTACT_PIN_FAILURE
    ],
    promise: client =>
      client.put(`/contacts/${contact_id}/associations/pins/${pin_id}`, {
        token,
        data: toSubmit
      }),
    event_id,
    contact_id,
    pin_id
  };
}

export function updateContactPins({ contact_id }, data, event_id = null) {
  let toSubmit = data.map(pin => pick(pin, ['id', 'order']));

  return {
    types: [
      CONTACTS_ACTIONS.UPDATE_CONTACT_PINS,
      CONTACTS_ACTIONS.UPDATE_CONTACT_PINS_SUCCESS,
      CONTACTS_ACTIONS.UPDATE_CONTACT_PINS_FAILURE
    ],
    promise: client =>
      client.put(`/reorder/pins`, {
        token,
        data: { reorder: toSubmit }
      }),
    event_id,
    contact_id
  };
}

export function deleteContactPin(id, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.DELETE_CONTACT_PIN,
      CONTACTS_ACTIONS.DELETE_CONTACT_PIN_SUCCESS,
      CONTACTS_ACTIONS.DELETE_CONTACT_PIN_FAILURE
    ],
    promise: client =>
      client.del(`/pins/${id}`, {
        token
      }),
    event_id,
    contact_id,
    association_id: id
  };
}

export function getSocialData(contactId, handle, provider, event_id = null) {
  if (!contactId) {
    console.error('expected non-empty contactId');
  }
  if (!handle) {
    console.error('expected non-empty live data handle');
  }
  if (!provider) {
    console.error('expected non-empty live data provider');
  }
  return {
    types: [
      CONTACTS_ACTIONS.GET_SOCIAL_DATA,
      CONTACTS_ACTIONS.GET_SOCIAL_DATA_SUCCESS,
      CONTACTS_ACTIONS.GET_SOCIAL_DATA_FAILURE
    ],
    promise: client =>
      client.put(`/contacts/${contactId}/live`, {
        data: { handle, provider },
        token
      }),
    event_id,
    handle,
    provider
  };
}

// UNUSED
// export function getContactScrape(contact_id, website_id, event_id = null) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.GET_CONTACT_SCRAPE,
//       CONTACTS_ACTIONS.GET_CONTACT_SCRAPE_SUCCESS,
//       CONTACTS_ACTIONS.GET_CONTACT_SCRAPE_FAILURE
//     ],
//     promise: client =>
//       client.get(`/live/contacts/${contact_id}/websites/${website_id}`, {
//         token
//       }),
//     event_id,
//     contact_id
//   };
// }

export function createContact(data, event_id = null, localStore) {
  data = submitHelper.onSubmit(data);
  return {
    types: [
      CONTACTS_ACTIONS.CREATE_CONTACT,
      CONTACTS_ACTIONS.CREATE_CONTACT_SUCCESS,
      CONTACTS_ACTIONS.CREATE_CONTACT_FAILURE
    ],
    promise: client =>
      client.post('/contacts', {
        data,
        token
      }),
    event_id,
    body: data,
    localStore
  };
}

export function clearUpdatePayload(data) {
  data = submitHelper.onSubmit(data);
  data.custom_fields &&
    data.custom_fields.forEach(
      custom_field => delete custom_field.custom_field_definition
    );

  data.groups = data.groups.map(group => {
    if (!('id' in group)) {
      // for new group, we need to preassign it to same resource type
      return { ...group, entity_id: data.resource_type?.id };
    }
    return group;
  });

  // Sanitise object before sending
  data = omit(data, [
    'resource_type',
    'organisation',
    'represented_by',
    'bookings'
  ]);

  // TODO: should be handled by API
  // Quick fix for stripping rate from ContactRate
  if (data.rates) {
    data = {
      ...data,
      rates: Array.isArray(data.rates)
        ? data.rates.map(r => {
            const { rate, ...rest } = r;
            return rest;
          })
        : []
    };
  }
  return data;
}

export function updateContact(id, data, event_id = null, forceContactHydrate) {
  data = clearUpdatePayload(data);

  return {
    types: [
      CONTACTS_ACTIONS.UPDATE_CONTACT,
      CONTACTS_ACTIONS.UPDATE_CONTACT_SUCCESS,
      CONTACTS_ACTIONS.UPDATE_CONTACT_FAILURE
    ],
    promise: client => client.put(`/contacts/${id}`, { data, token }),
    event_id,
    forceContactHydrate
  };
}

// UNUSED
// export function onboardCSVContacts(data, url) {
//   return function (dispatch) {
//     dispatch({
//       type: CONTACTS_ACTIONS.SET_ONBOARD_DATA,
//       data: data,
//       url: url
//     });
//   };
// }

export function archiveContact(contact_id, event_id) {
  return {
    types: [
      CONTACTS_ACTIONS.ARCHIVE_CONTACT,
      CONTACTS_ACTIONS.ARCHIVE_CONTACT_SUCCESS,
      CONTACTS_ACTIONS.ARCHIVE_CONTACT_FAILURE
    ],
    promise: client => client.del(`/contacts/${contact_id}`, { token }),
    event_id,
    contact_id
  };
}

export function archiveContactBatch(data, event_id) {
  return {
    types: [
      CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH,
      CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH_SUCCESS,
      CONTACTS_ACTIONS.ARCHIVE_CONTACT_BATCH_FAILURE
    ],
    promise: client => client.del('/batch/contacts', { data, token }),
    event_id,
    contact_ids: data.ids
  };
}

export function unarchiveContact(contact_id, event_id) {
  return {
    types: [
      CONTACTS_ACTIONS.UNARCHIVE_CONTACT,
      CONTACTS_ACTIONS.UNARCHIVE_CONTACT_SUCCESS,
      CONTACTS_ACTIONS.UNARCHIVE_CONTACT_FAILURE
    ],
    promise: client =>
      client.put(`/contacts/${contact_id}`, {
        data: { archived: false },
        token
      }),
    event_id,
    contact_id
  };
}

// UNUSED
// export function updateGDPRBasis(id, data, event_id) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.UPDATE_GDPR_BASIS,
//       CONTACTS_ACTIONS.UPDATE_GDPR_BASIS_SUCCESS,
//       CONTACTS_ACTIONS.UPDATE_GDPR_BASIS_FAILURE
//     ],
//     contact_id: id,
//     promise: client =>
//       client.put(`/gdpr/contacts/${id}`, {
//         data,
//         token
//       }),
//     event_id
//   };
// }

// UNUSED
// export function updateGDPRRequestedDates(id, data, event_id) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES,
//       CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES_SUCCESS,
//       CONTACTS_ACTIONS.UPDATE_GDPR_REQUESTED_DATES_FAILURE
//     ],
//     contact_id: id,
//     promise: client => client.put(`/gdpr/contacts/${id}`, { data, token }),
//     event_id
//   };
// }

// UNUSED
// export function downloadGDPRData(id, data, event_id) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA,
//       CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA_SUCCESS,
//       CONTACTS_ACTIONS.DOWNLOAD_GDPR_DATA_FAILURE
//     ],
//     promise: client =>
//       client.put(`/gdpr/contacts/${id}/request`, { data, token }),
//     event_id
//   };
// }

// UNUSED
// export function deleteGDPRContact(id, event_id) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.DELETE_GDPR_CONTACT,
//       CONTACTS_ACTIONS.DELETE_GDPR_CONTACT_SUCCESS,
//       CONTACTS_ACTIONS.DELETE_GDPR_CONTACT_FAILURE
//     ],
//     promise: client => client.get(`/gdpr/contacts/${id}`, { token }),
//     event_id
//   };
// }

// UNUSED
// export function getContactShortlists(contact_id, event_id = null) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.GET_CONTACT_SHORTLISTS,
//       CONTACTS_ACTIONS.GET_CONTACT_SHORTLISTS_SUCCESS,
//       CONTACTS_ACTIONS.GET_CONTACT_SHORTLISTS_FAILURE
//     ],
//     promise: client =>
//       client.get(`/contacts/${contact_id}/shortlists`, { token }),
//     contact_id,
//     event_id
//   };
// }

// UNUSED
// export function getContactProjects(contact_id, event_id = null) {
//   return {
//     types: [
//       CONTACTS_ACTIONS.GET_CONTACT_PROJECTS,
//       CONTACTS_ACTIONS.GET_CONTACT_PROJECTS_SUCCESS,
//       CONTACTS_ACTIONS.GET_CONTACT_PROJECTS_FAILURE
//     ],
//     promise: client =>
//       client.get(`/contacts/${contact_id}/projects`, { token }),
//     contact_id,
//     event_id
//   };
// }

/*
export function getContactEvents(
  contact_id,
  range,
  event_id = CONTACTS_ACTIONS.GET_CONTACT_EVENTS
) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_CONTACT_EVENTS,
      CONTACTS_ACTIONS.GET_CONTACT_EVENTS_SUCCESS,
      CONTACTS_ACTIONS.GET_CONTACT_EVENTS_FAILURE
    ],
    promise: client =>
      client.get(`/contacts/${contact_id}/associations/searchEvents`, {
        token,
        params: range
      }),
    contact_id,
    event_id
  };
}
*/

/*export function createContactEvent(data, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.CREATE_CONTACT_EVENT,
      CONTACTS_ACTIONS.CREATE_CONTACT_EVENT_SUCCESS,
      CONTACTS_ACTIONS.CREATE_CONTACT_EVENT_FAILURE
    ],
    promise: client =>
      client.post(`/contacts/${contact_id}/associations/events`, {
        token,
        data
      }),
    contact_id,
    event_id
  };
}*/

// UNUSED
// export function updateContactEvent(
//   id,
//   data,
//   contact_id,
//   event_id = CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT
// ) {
//   const toSubmit = omit(data, 'contact_id'); // TODO: find route to remove contact_id;
//   return {
//     types: [
//       CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT,
//       CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT_SUCCESS,
//       CONTACTS_ACTIONS.UPDATE_CONTACT_EVENT_FAILURE
//     ],
//     promise: client =>
//       client.put(`/contacts/${contact_id}/associations/events/${id}`, {
//         token,
//         data: toSubmit
//       }),
//     contact_id,
//     contact_event_id: id,
//     event_id
//   };
// }

/*export function deleteContactEvent(id, contact_id, event_id = null) {
  return {
    types: [
      CONTACTS_ACTIONS.DELETE_CONTACT_EVENT,
      CONTACTS_ACTIONS.DELETE_CONTACT_EVENT_SUCCESS,
      CONTACTS_ACTIONS.DELETE_CONTACT_EVENT_FAILURE
    ],
    promise: client =>
      client.del(`/contacts/${contact_id}/associations/events/${id}`, {
        token
      }),
    contact_id,
    contact_event_id: id,
    event_id
  };
}*/

export function getBatchContactEvents(start_date, end_date, ids) {
  return {
    types: [
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS,
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS_SUCCESS,
      CONTACTS_ACTIONS.GET_BATCH_CONTACTS_EVENTS_FAILURE
    ],
    promise: client =>
      client.post(`/resources/events`, {
        token,
        params: { start_date, end_date },
        data: {
          ids
        }
      }),
    contact_ids: ids
  };
}
