import { SimpleEvent } from '@passionware/simple-event';
import { Middleware } from 'redux';

export type V1Action =
  | {
      type:
        | 'CREATE_PRODUCTION_SUCCESS'
        | 'UPDATE_PRODUCTION_SUCCESS'
        | 'DELETE_PRODUCTION_SUCCESS';
    }
  | {
      type: 'UPDATE_RESOURCE_SLOT';
    }
  | {
      type: 'CREATE_BOOKING_SUCCESS';
    }
  | {
      type: 'UPDATE_BOOKING_SUCCESS';
    }
  | {
      type: 'DELETE_RESOURCE_SLOT_ASSIGNMENT_SUCCESS';
    }
  | {
      type: 'LOAD_AUTH_SUCCESS';
      result: {
        settings: {
          settings: {
            defaults_locale: {
              setting: string;
            };
          };
        };
      };
    }
  | {
      type: never;
    };

/**
 * Middleware that bridges V1 actions to a SimpleEvent to be consumed by other parts of the application.
 * @param event
 */
export function createActionAsEventMiddleware(
  event: SimpleEvent<V1Action>
): Middleware {
  return () => next => (action: V1Action) => {
    event.emit(action);
    return next(action);
  };
}
