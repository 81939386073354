import { apiClient } from '../../../../v4/core/apiClient';
import { queryClient } from '../../../../v4/migration/queryClientSingleton';
import { createFrontDeskApi } from '../../../api/front-desk/front-desk.api.http';
import { myEventService } from '../EventService/EventService.connected';
import { createFrontDeskService } from './FrontDeskService.impl';

export const myFrontDeskService = createFrontDeskService({
  api: createFrontDeskApi(apiClient),
  client: queryClient,
  services: {
    eventService: myEventService
  }
});
