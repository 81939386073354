import {
  cloneElement,
  ReactElement,
  ReactNode,
  RefObject,
  useLayoutEffect,
  useState
} from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDebouncedCallback } from 'use-debounce';

interface InlineFieldRenderProps<T> {
  value: T;
  onChange: (value: T) => void;
  onBlurWithin?: () => void;
  onBlur?: () => void;
}

export interface InlineFieldProps<T> {
  value: T;
  onChange: (value: T) => void;
  children:
    | ReactElement<InlineFieldRenderProps<T>>
    | ((props: InlineFieldRenderProps<T>) => ReactNode);
  ref?: RefObject<any>;
  changeOnDebounce?: boolean;
}

const noop = () => void 0;
/**
 * A component that wraps an input field and provides debounced updates to the parent component.
 * Generally it's discouraged to debounce onChangeCallback. (We should prefer debouncing the query that sources the data).
 * But this is created for existing use-cases where we need to debounce the input field.
 */
export function InlineField<T>({
  value,
  onChange,
  children,
  changeOnDebounce = true
}: InlineFieldProps<T>) {
  const [internalValue, setInternalValue] = useState(value);
  const onChangeDebounced = useDebouncedCallback(
    changeOnDebounce ? onChange : noop,
    500
  );

  useLayoutEffect(() => {
    setInternalValue(value);

    return () => {
      onChangeDebounced.cancel();
    };
  }, [value]);

  const ref = useHotkeys(
    'enter',
    () => {
      onChangeDebounced.flush();
      onChange(internalValue);
    },
    { enableOnFormTags: true }
  );

  const externalProps = {
    value: internalValue,
    onChange: (value: T) => {
      setInternalValue(value);
      onChangeDebounced(value);
    },
    onBlurWithin: () => {
      onChangeDebounced.cancel();
      onChange(internalValue);
    },
    onBlur: () => {
      onChangeDebounced.flush();
      onChange(internalValue);
    },
    ref
  };

  return typeof children === 'function'
    ? children(externalProps)
    : cloneElement(children, externalProps);
}
