import { Maybe, maybe, mt } from '@passionware/monads';
import { ensureError } from '@passionware/platform-js';
import { Info } from 'lucide-react';
import { useState } from 'react';
import store from '../../../../store';
import { CORE_STRUCTURES } from '../../../../v1/helpers/coreStructures';
import { MessageService } from '../../internal/MessageService/MessageService';
import { MutationService } from '../../internal/MutationService/MutationService';
import { ToastService } from '../ToastService/ToastService';
import { UserFlowService } from './UserFlowService';

export function createUserFlowService(config: {
  services: {
    toastService: ToastService;
    mutationService: MutationService;
    messageService: MessageService;
  };
}): UserFlowService {
  return {
    createCustomField: async request => {
      function getTargetInfo() {
        if (!request) {
          return null;
        }
        const coreStructureName =
          CORE_STRUCTURES[request.coreStructure]?.name ?? '???'; // todo - we can show a toast with some error instead of "???" here
        const entityName =
          store.getState()[request.coreStructure]?.data[
            request.coreStructureTypeId
          ]?.name ?? '???';
        return {
          coreStructure: coreStructureName,
          coreStructureType: entityName
        };
      }
      const targetInfo = getTargetInfo();
      const channel =
        await config.services.messageService.createCustomField.sendRequest({
          type: 'create',
          prompt: targetInfo ? (
            <>
              <Info className="size-5 mr-2 inline-block" />
              <span>This will be added to </span>
              <strong>
                {targetInfo.coreStructure}: {targetInfo.coreStructureType}
              </strong>
            </>
          ) : null
        });

      let result: Maybe<{ id: number }> = maybe.ofAbsent();

      for await (const payload of channel) {
        try {
          result = await config.services.mutationService.createCustomField(
            payload,
            request
          );
          config.services.toastService.open({
            type: 'success',
            message: 'Created custom field successfully'
          });
          channel.emit(mt.ofSuccess(void 0, void 0));
        } catch (e) {
          channel.emit(mt.ofError(void 0, ensureError(e)));
          config.services.toastService.open({
            type: 'error',
            message: ensureError(e).message
          });
        }
      }

      return maybe.mapOrElse(
        result,
        result => ({ action: 'created', id: result.id }),
        { action: 'canceled' }
      );
    },
    editCustomField: async (id, payload) => {
      const channel =
        await config.services.messageService.createCustomField.sendRequest({
          type: 'edit',
          customField: payload
        });

      let created = false;

      for await (const payload of channel) {
        try {
          await config.services.mutationService.updateCustomField(id, payload);
          config.services.toastService.open({
            type: 'success',
            message: 'Updated custom field successfully'
          });
          created = true;
          channel.emit(mt.ofSuccess(void 0, void 0));
        } catch (e) {
          channel.emit(mt.ofError(void 0, ensureError(e)));
          config.services.toastService.open({
            type: 'error',
            message: ensureError(e).message
          });
        }
      }

      return created ? { action: 'updated' } : { action: 'canceled' };
    }
  };
}
