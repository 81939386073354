import classNames from 'classnames';
import React from 'react';
import { NavLink } from 'react-router-dom';

interface SidebarLinkSecondaryProps {
  children: React.ReactNode;
  to: string;
}

const SidebarLinkSecondary = ({ children, to }: SidebarLinkSecondaryProps) => {
  return (
    // @ts-expect-error upgrade react-router-dom so react types match
    <NavLink
      to={to}
      className={isActive =>
        classNames(
          'rounded-md truncate text-eggplant-lightest w-full text-left text-eggplant-light p-2 block hover:bg-eggplant-lighter/60 hover:text-white/100 block focus:outline-none focus:text-white focus:ring-2 focus:ring-eggplant-lightest/60',
          { 'bg-eggplant-lighter/60 text-white/100': isActive }
        )
      }
    >
      {/* @ts-expect-error bump react-router */}
      {children}
    </NavLink>
  );
};

export default SidebarLinkSecondary;
