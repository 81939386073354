import moment from 'moment';
import { DATA_EXPORTS_ACTIONS } from 'store/v1/data_export_templates/data_export_templates.constants.js';

const token = true;

const formatData = data => ({
  ...data,
  fields: JSON.stringify(data.fields),
  query: JSON.stringify(data.query)
});

export function getDataExportTemplates(query) {
  return {
    types: [
      DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES,
      DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES_SUCCESS,
      DATA_EXPORTS_ACTIONS.GET_DATA_EXPORT_TEMPLATES_FAILURE
    ],
    promise: client => client.get('/data_exports', { data: query, token })
  };
}

export function createDataExportTemplate(data) {
  return {
    types: [
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE,
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE_SUCCESS,
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_TEMPLATE_FAILURE
    ],
    promise: client =>
      client.post('/data_exports', { data: formatData(data), token })
  };
}

export function updateDataExportTemplate(data) {
  return {
    types: [
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_SUCCESS,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_FAILURE
    ],
    promise: client =>
      client.put(`/data_exports/${data.id}`, { data: formatData(data), token })
  };
}

export function archiveDataExportTemplate(templateId) {
  return {
    types: [
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_SUCCESS,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_FAILURE
    ],
    promise: client =>
      client.put(`/data_exports/${templateId}`, {
        data: { archived: true },
        token
      }),
    templateId
  };
}

export function unarchiveDataExportTemplate(templateId) {
  return {
    types: [
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_SUCCESS,
      DATA_EXPORTS_ACTIONS.UPDATE_DATA_EXPORT_TEMPLATE_FAILURE
    ],
    promise: client =>
      client.put(`/data_exports/${templateId}`, {
        data: { archived: false },
        token
      }),
    templateId
  };
}

export function createDataExport(templateId) {
  const timezone = moment.tz.guess();
  return {
    types: [
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT,
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_SUCCESS,
      DATA_EXPORTS_ACTIONS.CREATE_DATA_EXPORT_FAILURE
    ],
    promise: client =>
      client.get(`/data_exports/${templateId}/export`, {
        token,
        params: {
          timezone
        }
      })
  };
}
