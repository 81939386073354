import { z } from 'zod';

export const location$ = z.object({
  id: z.number(),
  name: z.string()
});
export type Location$ = z.infer<typeof location$>;

export const productionCustomField$ = z.object({
  id: z.number(),
  custom_field_definition_id: z.number(),
  entity_type: z.number(),
  entity_id: z.number(),
  boolean_value: z.boolean().nullable(),
  string_value: z.string().nullable(),
  integer_value: z.number().nullable(),
  float_value: z.number().nullable(),
  option_id: z.number().nullable(),
  multi_select_ids: z.array(z.number()).nullable(),
  date_value: z.string().nullable(),
  archived: z.boolean(),
  legacy_user_id: z.number().nullable(),
  created_mechanism: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  user_id: z.string().nullable()
});
export type ProductionCustomField$ = z.infer<typeof productionCustomField$>;

export const checkin$ = z.object({
  id: z.number(),
  booking_id: z.number(),
  booking_date: z.string(),
  checked_in_timestamp: z.string().nullable(),
  checked_out_timestamp: z.string().nullable(),
  status: z
    .enum(['CHECKED_IN', 'CHECKED_OUT', 'CANCELLED', 'NOT_ARRIVED'])
    .nullable(),
  badge_number: z.string().nullable(),
  user_id: z.string(),
  created_mechanism: z.string().nullable(),
  created_at: z.string(),
  updated_at: z.string()
});
export type Checkin$ = z.infer<typeof checkin$>;

export const booking$ = z.object({
  booking_id: z.number(),
  note: z.string().nullable(),
  start_date: z.string(),
  start_timestamp: z.string().nullable(),
  date_type: z.enum([
    'RECURRING_DEFAULT',
    'RECURRING_SPECIFIC_HOURS',
    'RECURRING_HOURS',
    'INCLUSIVE_HOURS'
  ]),
  estimated_start_time: z.string().nullable(),
  contact_id: z.number(),
  first_name: z.string().catch(() => ''),
  last_name: z.string().catch(() => ''),
  nick_name: z.string().nullable(),
  profile_picture: z.string().nullable(),
  locations: z.array(
    location$.or(
      z.object({ name: z.null(), id: z.null() }).transform(() => null)
    )
  ),
  production_custom_fields: z.array(productionCustomField$),
  booking_custom_fields: z.array(z.unknown()).nullable(),
  checkin: checkin$.nullish()
});
export type Booking$ = z.infer<typeof booking$>;
