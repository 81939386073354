import { ResourceType, ResourceTypeModel } from '__types__/index';
import { get, size } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Grid, GridCell, ResourceFieldFilters } from 'v1/components/shared';
import { createResourceMetaFilters } from 'v1/components/shared/search/ResourceMetaFilters/ResourceMetaFilters';
import { UseGroups } from 'v1/components/shared/search/ResourceMetaFilters/SelectResourceGroup/SelectResourceGroup';
import { ListQuery } from 'v4/entities/common/common.crud.types';
import { TodoType } from 'v4/entities/common/TodoType';

interface ResourceFiltersProps {
  showMeta?: boolean;
  isClearable?: boolean;
  query: ListQuery<TodoType>;
  filterList?: string[];
  onChange: (query: ListQuery<TodoType>) => void;
  resourceTypesModel: TodoType; // todo probably dead code
  config: ResourceFiltersConfig;
}

export type UseResourceTypes = (model?: ResourceTypeModel) => ResourceType[];
export type UseResourceType = (id: ResourceType['id']) => ResourceType | null;

export interface ResourceFiltersConfig {
  useGroups: UseGroups;
  useResourceTypes: UseResourceTypes;
  useResourceType: UseResourceType;
}

export function ResourceFilters({
  showMeta = true,
  query = {},
  filterList,
  onChange,
  resourceTypesModel,
  isClearable,
  config
}: ResourceFiltersProps) {
  const ResourceMetaFilters = useMemo(
    () => createResourceMetaFilters(config.useGroups),
    [config.useGroups]
  );
  const resourceTypesList = config.useResourceTypes(resourceTypesModel);
  const defaultResourceType = config.useResourceType(
    get(query, 'filters.resource_type_id.eq')
  );

  const [activeResourceType, setActiveResourceType] =
    useState(defaultResourceType);

  const checkForFilters = () => {
    if (filterList) {
      return filterList.length > 0;
    } else {
      return (
        activeResourceType &&
        get(activeResourceType, ['metastructure', 'fields'], []).some(
          field =>
            // @ts-expect-error looks like MetastructureField doesn't have custom_field_definition_id property
            field.custom_field_definition_id ||
            // @ts-expect-error looks like MetastructureField doesn't have name property
            ['locations', 'tags', 'rates'].includes(field.name)
        )
      );
    }
  };

  const customFiltersExist = checkForFilters();

  function resetFilters() {
    onChange({});
    setActiveResourceType(null);
  }

  function filtersAreActive() {
    return query.filters && size(query.filters);
  }

  return (
    <div className="ResourceFilters">
      {isClearable && (
        <Grid className="stack-XS">
          <GridCell className="text-13-700">Filters</GridCell>
          {filtersAreActive() && (
            <GridCell width="auto">
              <span className="genericLink" onClick={resetFilters}>
                Clear all
              </span>
            </GridCell>
          )}
        </Grid>
      )}
      {showMeta && (
        <ResourceMetaFilters
          query={query}
          resourceTypes={resourceTypesList}
          // @ts-expect-error migration question - activeResourceType is required or not?
          activeResourceType={activeResourceType}
          setActiveResourceType={setActiveResourceType}
          onChange={onChange}
        />
      )}
      {customFiltersExist && (
        <ResourceFieldFilters
          resourceTypeId={activeResourceType ? activeResourceType.id : null}
          query={query}
          filterList={filterList}
          onFilterChange={onChange}
        />
      )}
      {!showMeta && !customFiltersExist && (
        <p className="inset-S stack-0">No filters available</p>
      )}
    </div>
  );
}
