import React, { Component } from 'react';

import {
  MultiResourceSelector,
  SuggestedTextInput,
  TagInput
} from 'v1/components/shared';

import Geosuggest from 'react-geosuggest';
import {
  convertToLowestDenomination,
  convertToWholeUnit
} from 'v1/helpers/currencyHelper';
import chain, { debounce, find, get, isEmpty } from 'lodash';
import { isValue } from 'v1/helpers/misc';
import { withTranslation } from 'react-i18next';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger
} from 'v5/design-sytem/Collapsible';
import { Checkbox } from 'v5/design-sytem/Checkbox';
import { Separator } from 'v5/design-sytem/Separator';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from 'v5/design-sytem/Select';
import { Input, inputVariants } from 'v5/design-sytem/Input';
import { MeasurementsFilterField } from 'v5/byModel/measurements/MeasurementsFilterField.js';
import { InlineField } from 'v5/platform/react/InlineField.js';
import { unitFieldUtils } from 'v4/shared/components/forms/input/unit-field-utils.js';

export const SEARCH_TYPES = {
  LONG_TEXT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  PERCENTAGE: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
    // Greater / Lower then does not play nice when allowing decimals on PERCENTAGE fields
    // Will remove ability for now as its unlikely customers use this.
    // {
    //   label: 'Greater than',
    //   value: 'gte',
    //   text: 'is greater than'
    // },
    // {
    //   label: 'Lower than',
    //   value: 'lte',
    //   text: 'is less than'
    // }
  ],
  SHORT_TEXT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Starts with',
      value: 's_with',
      text: 'starts with'
    },
    {
      label: 'Ends with',
      value: 'e_with',
      text: 'ends with'
    },
    {
      label: 'Contains',
      value: 'contains',
      text: 'contains',
      disabled: true
    }
  ],
  BOOLEAN: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  NUMBER: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  CURRENCY: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  MULTI_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    },
    {
      label: 'Any',
      value: 'any',
      text: 'has any of below'
    }
  ],
  TAG_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    },
    {
      label: 'Any',
      value: 'any',
      text: 'has any of below'
    }
  ],
  RESOURCE_SELECT: [
    {
      label: 'Match',
      value: 'match',
      text: 'has one of below'
    }
  ],
  SINGLE_SELECT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  DATE: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  DATE_RANGE: [],
  URL: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  MEASUREMENT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  MEASUREMENT_NEW: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gt',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lt',
      text: 'is less than'
    }
  ],
  DIMENSION_2D: [],
  DIMENSION_3D: [],
  NETSUITE_MASTER_PROJECT: [],
  NETSUITE_SUBCLASS: [],
  NETSUITE_VENDOR: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ],
  HEIGHT: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    },
    {
      label: 'Greater than',
      value: 'gte',
      text: 'is greater than'
    },
    {
      label: 'Lower than',
      value: 'lte',
      text: 'is less than'
    }
  ],
  NETSUITE_PO: [
    {
      label: 'Equals',
      value: 'eq',
      text: 'equals'
    }
  ]
};
const FIELD_TYPES = {
  type: { search_type: 'BOOLEAN', field_type: 'text' },
  skills: { search_type: 'MULTI_SELECT', field_type: 'match' },
  tags: { search_type: 'TAG_SELECT', field_type: 'match' },
  location: { search_type: 'RESOURCE_SELECT', field_type: 'match' },
  rates: { search_type: 'NUMBER', field_type: 'number' },
  list: { search_type: 'MULTI_SELECT', field_type: 'match' },
  groups: { search_type: 'MULTI_SELECT', field_type: 'match' },
  number: { search_type: 'NUMBER', field_type: 'number' },
  boolean: { search_type: 'BOOLEAN', field_type: 'boolean' }
};

const UNHANDLED_FIELD_TYPES = ['DIMENSION_2D', 'DIMENSION_3D'];

const MATCHING_TYPES = [
  {
    label: 'Match all',
    value: 'match'
  },
  {
    label: 'Match any',
    value: 'any'
  }
];

class SearchField extends Component {
  constructor(props) {
    super(props);
    const { option, value } = this.calculateValue(props);
    const isActive = Array.isArray(value) ? !!value.length : isValue(value);
    this.state = { option, value, expanded: isActive };
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      isValue(this.state.value) &&
      !isEmpty(this.props.query.filters) &&
      isEmpty(nextProps.query.filters)
    ) {
      this.resetValue();
    }
    const { option, value } = this.calculateValue(nextProps);
    this.setState({ option, value });
  }

  calculateValue = props => {
    // Search type options relative to this field
    const searchTypeOptions = this.getSearchTypeOptions(props);
    // Query object relative to this field
    const queryValue = get(props.query, `filters.${props.fieldKey}`);
    // Current field value, search option used for this field
    let fieldValue, searchTypeOption;
    // Check if there's a initial query to preload the values
    if (queryValue) {
      searchTypeOption = searchTypeOptions.find(option =>
        Object.keys(queryValue).includes(option.value)
      );
      if (searchTypeOption) fieldValue = queryValue[searchTypeOption.value];
    }
    searchTypeOption = searchTypeOption || searchTypeOptions?.[0];
    return { value: fieldValue, option: searchTypeOption };
  };

  getSearchTypeOptions = props => {
    if (props.isCustom) {
      return SEARCH_TYPES[
        props.field.custom_field_definition_type || 'SHORT_TEXT'
      ];
    } else {
      return SEARCH_TYPES[
        get(FIELD_TYPES[props.fieldKey], 'search_type') || 'SHORT_TEXT'
      ];
    }
  };

  resetValue = () => {
    this.setState({ value: '' }, () => {
      this.onChange();
      if (this._geoSuggest) return this._geoSuggest.clear();
    });
  };

  getSuggestions = () => {
    const suggestedField = {};
    suggestedField.key = this.props.fieldKey;
    if (this.props.isCustom) {
      suggestedField.custom_type = this.props.field.type;
    }
    this.props.getSuggestions(suggestedField);
  };

  onChange = () => {
    const fieldType = this.props.field.custom_field_definition_type;
    const key = this.props.fieldKey;
    const method = this.state.option.value;
    const value = this.state.value;
    this.props.onUpdate(key, method, value, fieldType);
  };

  renderMatchingType = () => (
    <>
      <Select
        value={
          MATCHING_TYPES.find(m => m.value === this.state.option.value)
            ? this.state.option.value
            : MATCHING_TYPES[0].value
        }
        onValueChange={value =>
          this.setState(
            { option: { ...this.state.option, ...{ value } } },
            this.state.value ? this.onChange : undefined
          )
        }
      >
        <SelectTrigger size="sm" className="mt-2">
          <SelectValue placeholder="Matching type" />
        </SelectTrigger>
        <SelectContent>
          {MATCHING_TYPES.map(({ label, value }) => (
            <SelectItem key={value} value={value}>
              {this.props.t(`SearchField.matchingTypes.${value}`, label)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );

  renderFieldType = (field, key, label) => {
    const { placeholder, isCustom } = this.props;
    const isCustomField = isCustom || (field.key || '').includes('custom');

    if (field.label === 'Location' && field.data_type !== 'RESOURCE_SELECT') {
      return (
        <Geosuggest
          placeholder={this.props.t('SearchField.searchLocation')}
          ref={el => (this._geoSuggest = el)}
          initialValue={this.state.value}
          onSuggestSelect={debounce(geo => {
            const label = geo ? geo.label : '';
            this.setState({ value: label }, this.onChange);
          }, 300)}
          inputClassName={inputVariants({ size: 'sm' })}
        />
      );
    }

    switch (field.data_type) {
      case 'SHORT_TEXT':
        if (field.suggestable) {
          return (
            <SuggestedTextInput
              field={key}
              suggestions={this.props.tags.suggestions[key] || []}
              fetching={this.props.tags.fetching}
              className="form-control small-input"
              placeholder={`Search ${label}`}
              getSuggestions={this.getSuggestions}
              onEnter={this.props.onEnter}
              value={this.state.value}
              onChange={debounce(
                (name, value) => this.setState({ value }, this.onChange),
                300
              )}
            />
          );
        }
        return (
          <Input
            key={key}
            size="sm"
            placeholder={`Search ${label}`}
            value={this.state.value}
            onChange={e =>
              this.setState({ value: e.target.value }, this.onChange)
            }
          />
        );
      case 'CURRENCY':
        return (
          <div className="flex gap-2">
            <Select
              onChange={e => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === e.target.value
                );
                this.setState(
                  { option, value: this.state.value || 10000 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <SelectTrigger size="sm">
                <SelectValue
                  placeholder={this.props.t('SearchField.currency.select')}
                />
              </SelectTrigger>
              <SelectContent>
                {SEARCH_TYPES[field.data_type].map(i => (
                  <SelectItem key={i.value} value={i.value}>
                    {this.props.t(
                      `SearchField.searchTypes.${field['data_type']}.${i.value}`,
                      i.label
                    )}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Input
              size="sm"
              value={convertToWholeUnit(this.state.value)}
              type="number"
              placeholder={this.props.t(
                'SearchField.currency.searchPlaceholder',
                {
                  label: this.props.t(`core_fields.${key}`, { ns: 'core' })
                }
              )}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.props.onEnter();
                }
              }}
              onChange={e => {
                const value = parseInt(
                  convertToLowestDenomination(e.target.value)
                );
                this.setState({ value }, this.onChange);
              }}
              highlightOnFocus
            />
          </div>
        );
      case 'PERCENTAGE':
        return (
          <div className="flex gap-2">
            <Select
              className="form-control DropdownInput small-input"
              onValueChange={value => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === value
                );
                this.setState(
                  { option, value: this.state.value || 100 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <SelectTrigger size="sm" className="w-fit whitespace-nowrap">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {SEARCH_TYPES[field.data_type].map(i => (
                  <SelectItem key={i.value} value={i.value}>
                    {i.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Input
              value={this.state.value}
              type="text"
              placeholder={`Search ${label}`}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.props.onEnter();
                }
              }}
              onChange={e =>
                this.setState({ value: e.target.value || null }, this.onChange)
              }
              highlightOnFocus
            />
          </div>
        );
      case 'MEASUREMENT':
      case 'HEIGHT':
      case 'NUMBER':
        return (
          <div className="flex gap-2">
            <Select
              onValueChange={value => {
                const option = find(
                  SEARCH_TYPES[field.data_type],
                  s => s.value === value
                );
                this.setState(
                  { option, value: this.state.value || 100 },
                  this.onChange
                );
              }}
              value={this.state.option.value}
            >
              <SelectTrigger size="sm" className="w-fit whitespace-nowrap">
                <SelectValue placeholder="Select" />
              </SelectTrigger>
              <SelectContent>
                {SEARCH_TYPES[field.data_type].map(i => (
                  <SelectItem key={i.value} value={i.value}>
                    {i.label}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <Input
              size="sm"
              value={this.state.value}
              type="number"
              placeholder={`Search ${label}`}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  this.props.onEnter();
                }
              }}
              onChange={e => {
                this.setState(
                  { value: parseInt(e.target.value) || null },
                  this.onChange
                );
              }}
              highlightOnFocus
            />
          </div>
        );
      case 'MEASUREMENT_NEW': {
        const operator = this.state.option?.value ?? 'eq';
        const unitValue =
          this.state.value ??
          unitFieldUtils.getUnitValueFromDisplayValue(
            0,
            field.custom_field_definition_object?.measurement_default_unit ??
              'ft'
          );

        const searchValue = { operator, ...unitValue };

        return (
          <div className="space-y-2">
            <InlineField
              value={searchValue}
              onChange={value => {
                const { operator, ...unitValue } = value;
                const option = SEARCH_TYPES.MEASUREMENT_NEW.find(
                  s => s.value === operator
                );
                this.setState({ value: unitValue, option }, this.onChange);
              }}
            >
              <MeasurementsFilterField size="sm" variant="regular" />
            </InlineField>
            <div className="text-fg-muted">
              Filtering will search across all units
            </div>
          </div>
        );
      }
      case 'BOOLEAN':
        return (
          <label className="flex gap-2">
            <Checkbox
              checked={this.state.value}
              onCheckedChange={checked =>
                this.setState({ value: checked }, this.onChange)
              }
            />
            <div>Should be {this.state.value ? '"Yes"' : '"No"'}</div>
          </label>
        );
      case 'TAG_SELECT':
        return (
          <div className="contents tailwind-root">
            <TagInput
              label={null}
              tags={this.state.value}
              field={key}
              placeholder={
                placeholder ||
                this.props.t('SearchField.tagSelect.defaultPlaceholder')
              }
              inputClassname="form-control small-input"
              onChange={value => {
                const formattedValue =
                  value &&
                  value.map(val => {
                    if (typeof val === 'string') {
                      // If just single string in array format back into correct system tag
                      const systemTag = find(
                        field.tagSuggestions,
                        t => t['tag'] === val
                      );
                      return systemTag;
                    } else {
                      return val;
                    }
                  });
                this.setState({ value: formattedValue }, this.onChange);
              }}
              suggestions={field.tagSuggestions}
              sortAlphabetically
            />
            {this.renderMatchingType()}
          </div>
        );
      case 'RESOURCE_SELECT':
        return (
          <MultiResourceSelector
            disabled={false}
            initialValue={this.state.value}
            onSelect={value => {
              this.setState({ value }, this.onChange);
            }}
            query={{
              filters: {
                resource_type_model: { eq: 'LOCATION' }
              },
              order_by: { direction: 'asc', field: 'full_name' }
            }}
            inputStyle={{
              placeholder: 'Select location resource'
            }}
            allowMultiple
          />
        );
      case 'MULTI_SELECT':
        let suggestions = [];
        let values = [];
        if (isCustomField) {
          suggestions = chain(field.items || [])
            .orderBy(['order', 'asc'])
            .map(i => ({ name: i.value, value: i.value }))
            .value();
          values = this.state.value;
        } else {
          suggestions = (field.items || []).map(i => ({
            id: i.value,
            name: i.name
          }));
          values = (this.state.value || [])
            .map(id => suggestions.find(s => s.id === id))
            .filter(i => i);
        }
        return (
          <div className="contents tailwind-root">
            <TagInput
              label={null}
              tags={values}
              field={key}
              placeholder={
                placeholder ||
                this.props.t('SearchField.multiSelect.defaultPlaceholder')
              }
              inputClassname="form-control small-input"
              suggestions={suggestions}
              onChange={(items = []) => {
                let value = [];
                if (isCustomField) {
                  value = items.map(i => i.tag || i);
                } else {
                  value = items.map(i => i.id);
                }
                this.setState({ value }, this.onChange);
              }}
            />
            {
              this.props.fieldKey !== 'production_type_id' &&
                this.renderMatchingType() // production_type_id does not allow type matching
            }
          </div>
        );
      case 'SINGLE_SELECT':
        const items = isCustomField
          ? chain(field.items || [])
              .orderBy(['order', 'asc'])
              .map(i => ({ name: i.value, value: i.value }))
              .value()
          : field.items || []; // Format: { value: 4, name: "Shoe" }

        return (
          <div className="contents tailwind-root">
            <TagInput
              label={null}
              tags={this.state.value}
              field={key}
              placeholder={
                placeholder ||
                this.props.t('SearchField.singleSelect.defaultPlaceholder')
              }
              inputClassname="form-control small-input"
              suggestions={items}
              onChange={(items = []) => {
                let value = [];
                value = items.map(i => i.tag || i);
                this.setState({ value }, this.onChange);
              }}
              disableAdd
            />
          </div>
        );

      default:
        return (
          <Input
            value={this.state.value}
            size="sm"
            placeholder={`Search ${label}`}
            onChange={e =>
              this.setState({ value: e.target.value }, this.onChange)
            }
          />
        );
    }
  };

  render() {
    const { field, fieldKey } = this.props;
    const { value } = this.state;
    const label = field.label;
    const isActive = Array.isArray(value) ? !!value.length : isValue(value);

    if (UNHANDLED_FIELD_TYPES.includes(field.data_type)) {
      return null;
    }

    return (
      <div className="tailwind-root contents group/collapsible">
        <Collapsible
          open={this.state.expanded}
          onOpenChange={expanded => this.setState({ expanded })}
          className="-mx-2 w-[calc(100%+var(--spacing)*4)]"
        >
          <CollapsibleTrigger
            onKeyDown={e => {
              if (e.key === 'Backspace') {
                this.resetValue();
              }
            }}
            className="px-2"
          >
            <div className="flex items-center gap-2 w-full">
              {isActive && (
                <>
                  <span className="rounded-full size-3 bg-brand-500" />
                </>
              )}
              {label ||
                this.props.t(`core_fields.${fieldKey}`, label, { ns: 'core' })}
              {isValue(value) && (
                <span
                  className="ml-auto mr-2 underline text-fg-default font-semibold"
                  onClick={this.resetValue}
                >
                  Clear
                </span>
              )}
            </div>
          </CollapsibleTrigger>
          <CollapsibleContent className="flex flex-row gap-2 px-2 -my-1 py-1">
            {this.renderFieldType(field, fieldKey, label)}
          </CollapsibleContent>
        </Collapsible>
        <Separator className="mt-0 group-last/collapsible:hidden" />
      </div>
    );
  }
}

export default withTranslation(['v1_shared', 'core'])(SearchField);
