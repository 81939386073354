import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { getTags } from 'store/v1/tags';
import { TagInput } from 'v1/components/shared';
import { isEqual, isEmpty, keys, debounce } from 'lodash';

export class TagFormBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: props.value || []
    };
  }
  componentDidMount() {
    if (!this.props.tags.fetched) {
      this.props.getTags();
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps.value, this.props.value)) {
      this.setState({ value: nextProps.value || [] });
    }
  }

  updateChange = debounce((method, value) => {
    this.props.onChange(this.props.field, value, method, true);
  }, 200);

  render() {
    const { value } = this.state;

    const suggestions = keys(this.props.tags.data).map(
      id => this.props.tags.data[id]
    );

    return (
      <div className="TagFormBlock tailwind-root">
        {this.props.label && (
          <label className="form-label">{this.props.label}</label>
        )}
        <TagInput
          label={null}
          isReadOnly={this.props.disabled}
          disableAdd={this.props.disableAdd}
          tags={value || []}
          placeholder={
            this.props.disableAdd
              ? 'Select tags from list'
              : 'Select or add new tags'
          }
          suggestions={suggestions}
          onChange={v => {
            this.setState(
              prev => ({ value: v }),
              () => this.updateChange(null, v)
            );
          }}
          hideLabel
        />
        {!isEmpty(this.state.inputValue) && (
          <p className="small">Press enter or add a comma to add</p>
        )}
      </div>
    );
  }
}

function mapStateToProps({ tags }) {
  return { tags };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      getTags
    },
    dispatch
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TagFormBlock);
