import React from 'react';
import { useSelector } from 'react-redux';
import { EmptyGeneric } from 'v1/components/shared';
import styles from './BookingsRoute.module.scss';
import { useTranslation } from 'react-i18next';

const BookingsRoute = () => {
  const { request } = useSelector(state => state.availabilities);
  const { companyName } = request;
  const { t } = useTranslation('resource-portal');

  return (
    <section className={styles['BookingsRoute']}>
      <EmptyGeneric
        className={styles['BookingsRoute__emptyGeneric']}
        title={t('BookingsRoute.comingSoon')}
        icon="/images/pancake_construction.png"
        iconWidth="50px"
      />
      <h2 className={styles['BookingsRoute__description']}>
        {t('BookingsRoute.description', { companyName })}
      </h2>
      <h3 className={styles['BookingsRoute__details']}>
        {t('BookingsRoute.details')}
      </h3>
    </section>
  );
};

export default BookingsRoute;
