import ui from './ui/ui.reducer.js';
import events, { eventPayloadReducer } from './events/events.reducer.js';
import auth from './auth/auth.reducer.js';
import auth_methods from './auth_methods/auth_methods.reducer.js';
import contacts from './contacts/contacts.reducer.js';
import shortlists from './shortlists/shortlists.reducer.js';
import tags from './tags/tags.slice';
import system from './system/system.reducer.js';
import groups from './groups/groups.reducer.js';
import callouts from './callouts/callouts.reducer.js';
import batch from './batch/batch.reducer.js';
import storage_providers from './storage_providers/storage_providers.reducer.js';
import rates from './rates/rates.reducer.js';
import productions from './productions/productions.reducer.js';
import production_types from './production_types/production_types.reducer.js';
import resource_types from './resource_types/resource_types.reducer.js';
import contact_imports from './contact_imports/contact_imports.reducer.js';
import bookings from './bookings/bookings.reducer.js';
import booking_types from './booking_types/booking_types.reducer.js';
import expense_types from './expense_types/expense_types.reducer.js';
import statuses from './statuses/statuses.reducer.js';
import production_templates from './production_templates/production_templates.reducer.js';
import arrivals from './arrivals/arrivals.reducer.js';
import checkins from './checkins/checkins.reducer.js';
import expenses from './expenses/expenses.reducer.js';
import slot_categories from './slot_categories/slot_categories.reducer.js';
import data_export_templates from './data_export_templates/data_export_templates.reducer.js';
import availability_requests from './availability_requests/availability_requests.reducer.js';
import public_settings from './public_settings/public_settings.reducer.js';

const v1Reducer = {
  arrivals,
  auth,
  auth_methods,
  availability_requests,
  batch,
  booking_types,
  bookings,
  callouts,
  checkins,
  contact_imports,
  contacts,
  data_export_templates,
  events,
  eventPayload: eventPayloadReducer,
  expense_types,
  expenses,
  groups,
  production_templates,
  production_types,
  productions,
  public_settings,
  rates,
  resource_types,
  shortlists,
  slot_categories,
  statuses,
  storage_providers,
  system,
  tags,
  ui
};

export default v1Reducer;
