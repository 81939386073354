import { useSelector } from 'react-redux';
import { selectActiveFeatureFlags } from 'store/teams/teams.selectors';
import { createReleaseFlags } from './_private/releaseFlags';
import { FeatureService } from './FeatureService';

export function createFeatureService(): FeatureService {
  return {
    useFeatureFlags: () => {
      return useSelector(selectActiveFeatureFlags);
    },
    releaseFlags: createReleaseFlags()
  };
}
