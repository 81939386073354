import React from 'react';

import { Icon } from 'shared';
import { Transition } from '@headlessui/react'

type SidebarAccordionProps = {
  children: React.ReactNode;
  icon: React.ReactNode;
  label: React.ReactNode;
  isExpanded: boolean;
  onToggle: () => void;
}

const SidebarAccordion = ({ children, label, icon, isExpanded, onToggle }: SidebarAccordionProps) => {
  return (
    <>
      <button onClick={onToggle} className="rounded-md w-full flex items-center text-left p-2 block hover:bg-eggplant-lighter/30 block focus:outline-none hover:text-white/100 focus:ring-2 focus:ring-eggplant-lightest/60">
        <span className="flex-0 mr-3">{icon}</span>
        <span className="flex-1">{label}</span>
        <span className="flex-0">{isExpanded ? <Icon name="chevronDown" size="m" /> : <Icon name="chevronRight" size="s" />}</span>
      </button>
      <Transition
        show={isExpanded}
        enter="transition-all duration-300"
        enterFrom="opacity-0 max-h-0"
        enterTo="opacity-100 max-h-full"
        leave="transition-all duration-300"
        leaveFrom="opacity-100 max-h-full"
        leaveTo="opacity-0 max-h-0"
        className="pl-8 pt-1"
      >
        {children}
      </Transition>

    </>
  );
};

export default SidebarAccordion;
