import { Control } from 'react-hook-form';
import { Checkbox } from '../../../../design-sytem/Checkbox';
import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage
} from '../../../../design-sytem/Form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '../../../../design-sytem/Select';

export interface MeasurementFieldConfig {
  control: Control;
}

const units = [
  { type: 'ft', label: 'Imperial (ft)' },
  { type: 'in', label: 'Imperial (in)' },
  { type: 'm', label: 'Metric (m)' },
  { type: 'cm', label: 'Metric (cm)' }
];

export function MeasurementFieldConfig(props: MeasurementFieldConfig) {
  return (
    <>
      <FormField
        control={props.control}
        name="measurement.defaultUnit"
        rules={{
          required: 'Field type is required'
        }}
        render={({ field }) => (
          <FormItem>
            <FormLabel>Default unit</FormLabel>
            <Select onValueChange={field.onChange} defaultValue={field.value}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select a unit"></SelectValue>
                </SelectTrigger>
              </FormControl>
              <SelectContent position="popper">
                {units.map(unit => {
                  return (
                    <SelectItem
                      key={unit.type}
                      value={unit.type}
                      className="max-w-(--radix-select-trigger-width)"
                    >
                      {unit.label}
                    </SelectItem>
                  );
                })}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={props.control}
        name="measurement.lockUnit"
        render={({ field }) => (
          <FormItem className="flex flex-row items-start space-x-3 space-y-0 rounded-md">
            <FormControl>
              <Checkbox
                checked={field.value}
                onCheckedChange={field.onChange}
              />
            </FormControl>
            <div className="space-y-1">
              <FormLabel>Lock default unit</FormLabel>
              <FormDescription>
                Users will not be able to change this within the application
              </FormDescription>
            </div>
          </FormItem>
        )}
      />
    </>
  );
}
