export const focusRings = {
  focus: {
    normal:
      'ring-offset-white transition-all focus:outline-hidden focus:ring-2 focus:ring-bg-emphasis/70 focus:ring-offset-2',
    visible:
      'ring-offset-white transition-all focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-bg-emphasis/70 focus-visible:ring-offset-2'
  },
  focusWithin: {
    normal:
      'ring-offset-white transition-all focus-within:outline-hidden focus-within:ring-2 focus-within:ring-bg-emphasis/70 focus-within:ring-offset-2',
    visible:
      'ring-offset-white transition-all [&:has(:focus-visible)]:outline-hidden [&:has(:focus-visible)]:ring-2 [&:has(:focus-visible)]:ring-bg-emphasis/70 [&:has(:focus-visible)]:ring-offset-2'
  }
};
