import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { get } from 'lodash';
import { withTranslation } from 'react-i18next';

import {
  TextInputField,
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  ModalNavigation
} from 'v1/components/shared';
import { Form } from 'react-form';
import { displayAlert } from 'store/v1/ui/ui.actions.js';
import { EVENT_ACTIONS } from 'store/v1/events/events.constants.js';
import { initiateGuestSession, updateGuest } from 'lib/auth/guestSystem';
import { PressStud } from 'v1/components/shared';

import './SetGuestNameModal.scss';

class SetGuestNameModal extends Component {
  constructor(props) {
    super(props);

    this.formApi = {};

    this.state = {
      query: { order_by: { field: 'created_at', direction: 'desc' } }
    };
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const currStatus = get(this.props.events, this.state.event);
    const nextStatus = get(nextProps.events, this.state.event);

    if (currStatus === EVENT_ACTIONS.PROCESSING) {
      if (nextStatus === EVENT_ACTIONS.SUCCESS) {
        this.props.onRequestClose();
        this.props.displayAlert(
          'success',
          this.props.t('SetGuestNameModal.successfullyCreated')
        );
      } else if (nextStatus !== EVENT_ACTIONS.PROCESSING) {
        this.props.displayAlert('error', nextStatus.message);
      }
    }
  }
  handleCreate = values => {
    let guest = initiateGuestSession();

    guest.full_name = values.full_name;

    updateGuest(guest);

    this.props.displayAlert(
      'success',
      this.props.t('SetGuestNameModal.guestNameSet')
    );
    setTimeout(() => {
      this.props.onRequestClose();
    }, 100);
  };
  render() {
    const { t } = this.props;
    return (
      <ModalWrapper size="XS">
        <ModalContent>
          <ModalScrollable padding="L">
            <img src="/images/icon_colour_wave.svg" width="40px" alt="" />
            <h2>{t('SetGuestNameModal.title')}</h2>
            <p>{t('SetGuestNameModal.description')}</p>
            <Form
              onSubmit={this.handleCreate}
              getApi={api => (this.formApi = api)}
              validateOnSubmit
            >
              {formApi => (
                <form
                  onSubmit={formApi.submitForm}
                  className="SetGuestNameModal"
                >
                  <TextInputField
                    label={t('SetGuestNameModal.yourName')}
                    field="full_name"
                    validate="required"
                    initialFocus
                  />
                </form>
              )}
            </Form>
          </ModalScrollable>
          <ModalNavigation>
            <PressStud
              label={t('SetGuestNameModal.saveName')}
              appearance="primary"
              action={() => this.formApi.submitForm()}
            />
          </ModalNavigation>
        </ModalContent>
      </ModalWrapper>
    );
  }
}

function mapStateToProps({ events }) {
  return { events };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ displayAlert }, dispatch);
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(withTranslation('shortlists')(SetGuestNameModal))
);
