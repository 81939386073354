import { cf } from '@easle/component-factory';
import classNames from 'classnames';

export const SearchPrimitive = cf.input({
  className: classNames(
    'w-full border border-gray-500 rounded text-base text-gray-900 placeholder-gray-500',
    'focus:outline-none focus:ring-0 focus:border-gray-500',
    'p-lg'
  )
});
