import {
  type CSSProperties,
  type ReactNode,
  type RefObject,
  useEffect,
  useRef,
  useState,
  type WheelEvent
} from 'react';
import { cn } from '../platform/dom/cn';

export interface ScrollShadowWrapperProps {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
}

export function ScrollShadowWrapper(props: ScrollShadowWrapperProps) {
  const { children, className = '', style = {} } = props;

  const [scrollTop, setScrollTop] = useState(0);
  const [scrollHeight, setScrollHeight] = useState(0);
  const [clientHeight, setClientHeight] = useState(0);

  const onScrollHandler = (event: WheelEvent<HTMLDivElement>) => {
    setScrollTop(event.currentTarget.scrollTop);
    setScrollHeight(event.currentTarget.scrollHeight);
    setClientHeight(event.currentTarget.clientHeight);
  };

  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const resetRefSizes = (ref: RefObject<HTMLDivElement>) => {
      if (!ref.current) return;

      setScrollTop(ref.current.scrollTop);
      setScrollHeight(ref.current.scrollHeight);
      setClientHeight(ref.current.clientHeight);
    };

    resetRefSizes(wrapperRef);
  }, [wrapperRef?.current?.clientHeight]);

  const getVisibleSides = (): { top: boolean; bottom: boolean } => {
    const isBottom = clientHeight === scrollHeight - scrollTop;
    const isTop = scrollTop === 0;
    const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

    return {
      top: !isTop,
      bottom: (isTop || isBetween) && !(isTop && isBottom)
    };
  };

  return (
    <div
      ref={wrapperRef}
      style={style}
      className={cn('relative overflow-y-auto', className)}
      onScroll={onScrollHandler}
    >
      <div
        className={cn(
          'pointer-events-none sticky top-0 -mb-1.5 h-1.5 w-full transition-opacity duration-100 ',
          'border-t border-border-default',
          'bg-gradient-to-b from-black/5 to-transparent',
          getVisibleSides().top ? 'opacity-100' : 'opacity-0'
        )}
      />
      {children}
      <div
        className={cn(
          'pointer-events-none sticky bottom-0 -mt-1.5 h-1.5 w-full transition-opacity duration-100 ',
          'border-b border-border-default',
          'bg-gradient-to-t from-black/5 to-transparent',
          getVisibleSides().bottom ? 'opacity-100' : 'opacity-0'
        )}
      />
    </div>
  );
}
