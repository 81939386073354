import { ExclamationTriangleIcon } from '@heroicons/react/24/outline';
import React from 'react';

interface SandboxIndicatorProps {
  onClick: () => void;
}

export function SandboxIndicator({ onClick }: SandboxIndicatorProps) {
  return (
    <button
      className="flex w-full focus:ring-white/20 focus:ring-2 flex-row items-center gap-1.5 bg-orange-400 hover:bg-orange-500  text-gray-900 py-2 px-1 rounded-md text-md cursor-pointer"
      onClick={onClick}
    >
      <ExclamationTriangleIcon className="h-6 w-6" />
      Sandbox environment
    </button>
  );
}
