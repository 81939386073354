import { get } from 'lodash';
import moment from 'moment-timezone';
import { z } from 'zod';
import { ApiClient } from '../../../lib/api/ApiClient.types';
import store from '../../../store';
import { FrontDeskApi, FrontDeskEntry, FrontDeskQuery } from './front-desk.api';
import { convertToFrontDeskEntry } from './front-desk.api.http.adapter';
import { booking$ } from './front-desk.api.http.schema';

export function createFrontDeskApi(client: ApiClient): FrontDeskApi {
  return {
    async getEntries(query, signal) {
      const response = await client.get('/custom_nike/frontdesk', {
        signal,
        params: { date: moment(query.date).format('YYYY-MM-DD') },
        token: true // todo this shuold be responsibility of injected client
      });
      const typedResponse = z.array(booking$).parse(response);
      const state = store.getState();
      const customFieldDefinitions =
        get(state.auth, 'settings.custom_field_definitions') ?? [];
      return typedResponse.map(booking =>
        convertToFrontDeskEntry(booking, customFieldDefinitions)
      );
    }
  };
}
