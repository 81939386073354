import { Group, ResourceType } from '__types__/index';
import React from 'react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { ListQuery } from 'v4/entities/common/common.crud.types';
import { TodoType } from 'v4/entities/common/TodoType';

import SelectResourceType from './SelectResourceType/SelectResourceType';
import {
  createSelectResourceGroup,
  UseGroups
} from './SelectResourceGroup/SelectResourceGroup';
import { SearchAvailabilityInput } from 'v1/components/shared';
import { get, set, omit, filter, chain } from 'lodash';

interface ResourceMetaFiltersProps {
  className?: string;
  query: ListQuery<TodoType>;
  resourceTypes: Array<ResourceType>;
  activeResourceType: ResourceType;
  setActiveResourceType?: (resourceType: ResourceType) => void;
  onChange: (query: ListQuery<TodoType>) => void;
}

export function createResourceMetaFilters(useGroups: UseGroups) {
  const SelectResourceGroup = createSelectResourceGroup(useGroups);

  return function ResourceMetaFilters({
    className,
    query = {},
    resourceTypes,
    activeResourceType,
    setActiveResourceType,
    onChange
  }: ResourceMetaFiltersProps) {
    const { t } = useTranslation('v1_shared');
    const onTypeChange = (resourceType: ResourceType) => {
      let newQuery: ListQuery<TodoType> = {};
      if (!resourceType) {
        newQuery = omit(query, 'filters.resource_type_id');
      } else {
        newQuery = {
          query: get(query, 'query'),
          order_by: get(query, 'order_by'),
          filters: {
            resource_type_id: { eq: resourceType.id }
          }
        };
      }
      // TODO: mutating lodash function
      get(query, 'filters.availability') &&
        // @ts-expect-error todo after migration, some confusing code has to be cleaned up
        set(newQuery.filters.availability, get(query, 'filters.availability'));
      onChange(newQuery);
      setActiveResourceType && setActiveResourceType(resourceType);
    };

    const onGroupChange = (id: Group['id']) => {
      let newQuery: ListQuery<TodoType> = omit(query, 'p');
      let groups = get(query, 'filters.groups.eq') || [];
      if (id == null) {
        // TODO: mutating lodash function
        newQuery = set(newQuery, 'filters', omit(newQuery.filters, 'groups'));
      } else if (groups.includes(id)) {
        groups = filter(groups, g => g != id);
        // TODO: mutating lodash function
        newQuery = set(newQuery, 'filters', {
          ...newQuery.filters,
          groups: { eq: groups }
        });
      } else {
        groups.push(id);
        // TODO: mutating lodash function
        newQuery = set(newQuery, 'filters', {
          ...newQuery.filters,
          groups: { eq: groups }
        });
      }
      onChange(newQuery);
    };

    const onAvailabilityChange = (dates: TodoType) => {
      if (!dates) {
        delete query.filters?.availability;
        onChange({
          ...query,
          order_by: { field: 'full_name', direction: 'asc' }
        });
      } else {
        const filters = get(query, 'filters', {});
        onChange({
          ...query,
          filters: { ...filters, availability: dates },
          order_by: { field: 'availability', direction: 'asc' }
        });
      }
    };

    return (
      <div className={classnames(['ResourceMetaFilters', className])}>
        <SelectResourceType
          resourceTypes={resourceTypes}
          activeResourceType={activeResourceType}
          onSelect={onTypeChange}
        />
        <SelectResourceGroup
          activeGroups={get(query, 'filters.groups.eq', [])}
          activeResourceType={activeResourceType}
          onSelect={onGroupChange}
        />
        <SearchAvailabilityInput
          label={t('ResourceMetaFilters.available')}
          dates={get(query, 'filters.availability', {})}
          onChange={onAvailabilityChange}
        />
      </div>
    );
  };
}
