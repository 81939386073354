import React from 'react';
import PropTypes from 'prop-types';
import exact from 'prop-types-exact';
import classnames from 'classnames';
import { isNil } from 'lodash';

import { SystemLink } from 'v1/components/shared';

import './PressStud.scss';

// Using these lil maps for the time-being until we sort icons out properly
// exported for Storybook
export const ICONS = {
  add: '/images/icon_add.svg',
  addBlack: '/images/icon_add_black.svg',
  addWhite: '/images/icon_add_white.svg',
  tick: '/images/icon_white_tick.svg',
  edit: '/images/icon_edit.svg',
  delete: '/images/icon_delete.svg', // The red one
  trash: '/images/icon_trash.svg', // The grey one
  show: '/images/icon_show.svg',
  arrowLeft: '/images/arrow_page_right.svg',
  arrowRight: '/images/arrow_page_right.svg',
  arrowTopRight: '/images/icon_arrow_top_right.svg',
  arrowLeftGray: '/images/icon_arrow_right_gray.svg',
  arrowRightGray: '/images/icon_arrow_right_gray.svg',
  arrowDownGray: '/images/icon_arrow_down_gray.svg',
  arrowUpGray: '/images/icon_arrow_down_gray.svg',
  search: '/images/icon_search.svg',
  comments: '/images/icon_comments.svg',
  options: '/images/icon_options.svg', // Old ellipsis (...)
  ellipsis: '/images/icon_ellipsis.svg', // New ellipsis (...)
  pinsExpanded: '/images/icon_pins_expanded.svg',
  pinsCollapsed: '/images/icon_pins_collapsed.svg',
  filePdf: '/images/icon_file_pdf.svg',
  fileDownload: '/images/icon_file_download.svg', // TODO: need an eggplant version
  newWindow: '/images/icon_new_window.svg',
  callout: '/images/icon_type_callout.svg',
  metaPrivate: '/images/icon_meta_private.svg',
  gift: '/images/icon_gift.svg',
  calculate: '/images/icon_calculate.svg',
  calculateDisabled: '/images/icon_calculate_disabled.svg',
  helpBook: '/images/icon_help_book.svg',
  integration: '/images/icon_integration.svg',
  thumbsUp: `/images/icon_thumbs_up.svg`,
  thumbsDown: `/images/icon_thumbs_down.svg`,
  reviewWhite: `/images/icon_review_white.svg`,
  duplicate: `/images/icon_duplicate.svg`,
  send: `/images/icon_send.svg`,
  sendWhite: `/images/icon_send_white.svg`,
  timeRemove: `/images/icon_time_remove.svg`,
  timeAdd: `/images/icon_time_add.svg`,
  eye: `/images/icon_eye.svg`,
  eyeHide: `/images/icon_eye_hide.svg`,
  note: `/images/icon_note.svg`,
  expand: `/images/icon_expand_arrows.svg`,
  lightning: '/images/icon_lightning.svg',
  star: '/images/icon_resource_star_black.svg',
  starred: '/images/icon_star_orange.svg',
  order: '/images/icon_order.svg'
};

const ICON_WIDTHS = {
  add: '10px',
  addBlack: '12px',
  addWhite: '12px',
  tick: '',
  edit: '',
  delete: '12px',
  trash: '',
  show: '',
  arrowLeft: '7px',
  arrowRight: '7px',
  arrowTopRight: '',
  arrowLeftGray: '',
  arrowRightGray: '',
  arrowDownGray: '12px',
  arrowUpGray: '12px',
  search: '12px',
  comments: '',
  options: '12px',
  ellipsis: '12px',
  pinsExpanded: '',
  pinsCollapsed: '',
  filePdf: '10px',
  fileDownload: '',
  newWindow: '12px',
  callout: '12px',
  metaPrivate: '12px',
  gift: '',
  calculate: '12px',
  calculateDisabled: '14px',
  thumbsUp: '15px',
  thumbsDown: '15px',
  reviewWhite: '15px',
  send: '15px',
  duplicate: '12px',
  sendWhite: '15px',
  timeRemove: '15px',
  timeAdd: '15px',
  eye: '15px',
  eyeHide: '15px',
  note: '15px',
  expand: '',
  lightning: '',
  star: '13px',
  starred: '13px',
  order: '15px'
};

// TODO: icons : update 'flip' logic to be better - just add new icons that are already flipped
const FLIPPED_ICONS = ['arrowLeft', 'arrowLeftGray', 'arrowUpGray'];
const ICON_LIST = Object.keys(ICONS);

/**
 * Button-shaped component that has a click action
 */
const PressStud = ({
  // Display
  label,
  tooltip,
  ariaLabel,
  icon,
  arrow,
  appearance = 'outline',
  size,
  hasMarginLeft,
  hasMarginRight,
  // State
  isDisabled,
  isLoading,
  // Metadata
  dataCy,
  // Action
  target,
  stopPropagation,
  action,
  className
}) => {
  const isDarkMode = ['primary', 'secondary', 'danger', 'dark'].includes(
    appearance
  );
  const buttonIsDisabled = isDisabled || isLoading;
  const buttonProps = {
    className: classnames(
      'PressStud',
      'btn',
      {
        'btn-link': appearance === 'silent',
        'btn-clear': appearance === 'transparent',
        'btn-default': appearance === 'outline' || !appearance,
        'btn-primary': appearance === 'primary',
        'btn-secondary': appearance === 'secondary',
        'btn-danger': appearance === 'danger',
        'btn-dark': appearance === 'dark',
        'btn-dark-silent': appearance === 'dark-silent',
        'btn-small': !isNil(label) && size === 'S',
        'btn-lg': !isNil(label) && size === 'L',
        'btn-fill': !isNil(label) && size === 'full',
        'btn-square btn-small': icon && isNil(label),
        PressStud_loading: !isDisabled && isLoading,
        'pad-left': hasMarginLeft,
        'pad-right': hasMarginRight
      },
      className
    ),
    'aria-label': ariaLabel,
    disabled: buttonIsDisabled,
    tabIndex: 0,
    'data-cy': dataCy // TODO: Cypress: migrate to use data-id
  };

  const getComponentType = () => {
    if (!action || action === 'submit' || typeof action === 'function') {
      return 'button';
    }
    if (action.startsWith('/')) {
      return 'systemLink';
    }
    return 'externalLink';
  };

  const componentType = getComponentType();

  const renderInner = () => (
    <>
      <div className="PressStud_content">
        {icon && (
          <img
            className={classnames('PressStud-icon', {
              'PressStud-icon_prefix': label,
              flip: FLIPPED_ICONS.includes(icon)
            })}
            src={ICONS[icon]}
            alt=""
            width={ICON_WIDTHS[icon]}
          />
        )}
        <div className="PressStud-label">{label}</div>
        {arrow && label && (
          <img
            className={classnames('PressStud-icon', {
              'PressStud-icon_suffix': label
            })}
            src={
              isDarkMode
                ? '/images/icon-arrow-right.svg'
                : '/images/icon_arrow_right_gray.svg'
            }
            width="10px"
            alt=""
          />
        )}
      </div>
      {!isDisabled && isLoading && (
        <span
          className={classnames(['loading-circle', { dark: !isDarkMode }])}
        />
      )}
    </>
  );

  /**
   * Handle the link click.
   * @param {SyntheticEvent} event
   */
  const handleLinkClick = event => {
    stopPropagation && event.stopPropagation();
  };

  /**
   * Handle the button click.
   * @param {SyntheticEvent} event
   */
  const handleButtonClick = event => {
    stopPropagation && event.stopPropagation();
    if (!buttonIsDisabled) {
      action && typeof action === 'function' && action();
    }
  };

  // To properly disable the button it should be a button and not an anchor
  if (componentType === 'systemLink' && !buttonIsDisabled) {
    return (
      <SystemLink
        to={action}
        data-tip={tooltip || null}
        target={target}
        onClick={handleLinkClick}
        {...buttonProps}
      >
        {renderInner()}
      </SystemLink>
    );
  }

  // To properly disable the button it should be a button and not an anchor
  if (componentType === 'externalLink' && !buttonIsDisabled) {
    return (
      <a
        href={action}
        data-tip={tooltip || null}
        rel="noopener noreferrer"
        target={target}
        onClick={handleLinkClick}
        {...buttonProps}
      >
        {renderInner()}
      </a>
    );
  }

  return (
    <button
      type={action === 'submit' ? 'submit' : 'button'}
      data-tip={tooltip || null}
      onClick={handleButtonClick}
      {...buttonProps}
    >
      {renderInner()}
    </button>
  );
};

PressStud.propTypes = exact({
  // Labels
  label: PropTypes.string, // Label text
  tooltip: PropTypes.string, // Tooltip
  ariaLabel: PropTypes.string, // Accessibility - use when there's no label
  // Icons
  icon: PropTypes.oneOf(ICON_LIST), // Eg. 'plus'
  arrow: PropTypes.bool, // Shows arrow on the right of the label (if the label exists)
  // Styling
  appearance: PropTypes.oneOf([
    'silent',
    'transparent',
    'outline',
    'primary',
    'secondary',
    'dark',
    'danger'
  ]),
  size: PropTypes.oneOf(['S', 'M', 'L', 'full']),
  // Contextual styling
  // These are easy helpers to add 5px margins, since we do that a lot
  hasMarginLeft: PropTypes.any, // Used as a boolean - adds 5px left margin
  hasMarginRight: PropTypes.any, // Used as a boolean - adds 5px right margin

  // State
  isDisabled: PropTypes.any, // Used as a boolean
  isLoading: PropTypes.any, // Used as a boolean

  // Metadata
  dataCy: PropTypes.string, // TODO: Cypress: migrate to use data-id

  // Action
  target: PropTypes.string, // Same as <a> target
  className: PropTypes.string,
  stopPropagation: PropTypes.bool,
  action: PropTypes.oneOfType([
    PropTypes.oneOf(['submit']), // Use 'submit' for type="submit"
    PropTypes.string, // Links
    PropTypes.func // onClick action
  ]) // Fine to have no action, for PopoverTrigger etc
});

export default PressStud;

/*

TODO: Future

- Old .btn styles need cleaning up and bringing up to date
- Hover/active states need cleaning up
- Disabled styles need cleaning up
- Icon placement is slightly off on some larger icons, and needs reviewing
- Buttons that aren't btn-default have 36px height instead of 38px - need same-coloured border
- Loading icon can probs be extracted out
- Should the arrow icon be shown automatically depending on the action type? We have it on some internal links, some submits, and some onClicks
- Icons could show light/dark variations based on `isDarkMode`
- Improve handling of internal links which are outside the current workspace
- Could default ariaLabel to tooltip where tooltip is set & label isn't

*/
