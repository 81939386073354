import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { updateContact } from 'store/v1/contacts/contacts.actions.js';
import { displayAlert } from 'store/v1/ui/ui.actions.js';

import { Capability } from '__types__';
import { renderName } from 'v1/helpers/contactHelpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {
  Grid,
  GridCell,
  PressStud,
  ProfileImageUploader,
  ResourceMeta,
  ResourceOptions,
  ResourceTypeSelectorDisplay,
  SystemLink
} from 'v1/components/shared';

import styles from './ResourcePaneHeader.module.scss';

const ResourcePaneHeader = ({
  resource = {},
  hideTabs,
  hideOptions,
  hideFullLink
}) => {
  const dispatch = useDispatch();
  const [codeCopyState, setCodeCopyState] = useState();

  const handleChange = (field, value) => {
    dispatch(updateContact(resource.id, { [field]: value }));
  };

  useEffect(() => {
    if (codeCopyState) {
      dispatch(displayAlert('success', 'Resource code copied'));
      setTimeout(() => {
        setCodeCopyState(false);
      }, 500);
    }
  }, [codeCopyState]);

  return (
    <div
      className={classnames([
        'inset-M',
        styles.ResourcePaneHeader,
        { [styles.ResourcePaneHeaderNoTabs]: hideTabs }
      ])}
    >
      <Grid gutters="S">
        <GridCell width="auto">
          <ProfileImageUploader
            className={styles.ResourcePaneHeaderProfileImage}
            field="profile_picture"
            capability={Capability.RESOURCE_UPDATE}
            contact={resource}
            src={resource.profile_picture}
            onRemoveImage={handleChange}
            onUpload={handleChange}
            label="Add Image"
          />
        </GridCell>
        <GridCell>
          <Grid gutters="XS" valign="center">
            <GridCell width="auto">
              <CopyToClipboard
                text={resource.id}
                onCopy={() => setCodeCopyState(true)}
              >
                <span
                  data-tip="Click to copy code"
                  className={styles.ResourcePaneHeaderCode}
                >
                  {resource.id}
                </span>
              </CopyToClipboard>
            </GridCell>
            <GridCell width="auto">·</GridCell>
            <GridCell>
              <ResourceTypeSelectorDisplay
                size="S"
                resourceTypeId={resource.resource_type_id}
                onChange={value => handleChange('resource_type_id', value)}
                initialOnChange={false}
              />
            </GridCell>
            <GridCell width="auto">
              {!hideFullLink && (
                <PressStud
                  icon="arrowTopRight" // TODO: better new window icon
                  tooltip="View Profile"
                  hasMarginRight
                  action={`/contacts/${resource.id}`}
                />
              )}
              {!hideOptions && <ResourceOptions resourceId={resource.id} />}
            </GridCell>
          </Grid>
          <SystemLink to={`/contacts/${resource.id}`}>
            <h2
              className={classnames(['bold', styles.ResourcePaneHeaderTitle])}
            >
              {renderName(resource)}
            </h2>
          </SystemLink>
          <ResourceMeta size="L" resource={resource} hideRepresentation />
        </GridCell>
      </Grid>
    </div>
  );
};

ResourcePaneHeader.props = {
  resource: PropTypes.object,
  hideTabs: PropTypes.bool,
  hideOptions: PropTypes.bool,
  hideFullLink: PropTypes.bool
};

export default ResourcePaneHeader;
