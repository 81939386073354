import { EntityType } from '__types__/entities.d';
import {
  BaseModelV1,
  Archivable,
  Ownable,
  Timestamped,
  Mechanism
} from './baseTypes';

export const EntityTypesWithCustomFields = [
  EntityType.BOOKING,
  EntityType.CONTACT,
  EntityType.EXPENSE,
  EntityType.PRODUCTION,
  EntityType.SHORTLIST
] as const;

type EntityTypeWithCustomFields = typeof EntityTypesWithCustomFields[number];

export type CustomField = BaseModelV1 &
  Archivable &
  Ownable &
  Timestamped &
  Mechanism & {
    custom_field_definition_id: EntityId;
    entity_type: EntityTypeWithCustomFields;
    entity_id: EntityId;
    boolean_value: boolean | null;
    string_value: string | null; // use for measurement's unit (m, cm, ft, in)
    integer_value: number | null;
    float_value: number | null; // use for measurement's amount
    date_value: Date | null;
    option_id: EntityId | null;
    multi_select_ids: EntityId[] | null;
  };
