import moment from 'moment-timezone';

const titleBuilder = (extendedProps, defaultTitle, t) => {
  const {
    dateType,
    estimatedDailyHours,
    estimatedStartTime,
    estimatedEndTime,
    hasAssignments
  } = extendedProps;

  const formattedEstimatedStartDate = moment(
    estimatedStartTime,
    'HH:mm'
  ).format('h:mma');
  const formattedEstimatedEndDate = moment(estimatedEndTime, 'HH:mm').format(
    'h:mma'
  );
  const actionText =
    defaultTitle === 'Confirmed'
      ? t('CalendarRoute.tippy.actionView')
      : t('CalendarRoute.tippy.actionEdit');

  if (hasAssignments) {
    return `<div><div>${defaultTitle}</div><div class="editable">${t(
      'CalendarRoute.tippy.notEditable'
    )}</div></div>`;
  }

  switch (dateType) {
    case 'DEFAULT_RECURRING':
      return `<div><div>${t(
        'CalendarRoute.tippy.availableAllDay'
      )}</div><div class="editable">${t(
        'CalendarRoute.tippy.actionEdit'
      )}</div></div>`;
    case 'RECURRING_HOURS':
      return `<div><div>${t('CalendarRoute.tippy.availableForHours', {
        hours: estimatedDailyHours
      })}</div><div class="editable">${t(
        'CalendarRoute.tippy.actionEdit'
      )}</div></div>`;
    case 'RECURRING_SPECIFIC_HOURS':
      return `<div><div>${t('CalendarRoute.tippy.availableSpecificHours', {
        start: formattedEstimatedStartDate,
        end: formattedEstimatedEndDate
      })}</div><div class="editable">${t(
        'CalendarRoute.tippy.actionEdit'
      )}</div></div>`;
    default:
      return `<div><div>${defaultTitle}</div><div class="editable">${actionText}</div></div>`;
  }
};

export default titleBuilder;
