import { apiClient } from '../../../v4/core/apiClient';
import { queryClient } from '../../../v4/migration/queryClientSingleton';
import { createGlobalSearchApi } from '../../api/global-search/global-search.api.http';
import { globalSearch$ } from '../../api/global-search/global-search.api.ls.schema';
import { createLocalStorageApi } from '../../platform/ts/localStorageApi';
import { createGlobalSearchService } from './GlobalSearchService.impl';

export const myGlobalSearchService = createGlobalSearchService(
  createGlobalSearchApi(apiClient),
  createLocalStorageApi('global-search-recents', globalSearch$.parse, []),
  queryClient
);
