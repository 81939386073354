import { ReadWriteApi } from './readWriteApi';

/**
 * A local-storage version of createLocalStorageApi that does not require the initial data to be passed in.
 * It also assumes that parsing the data will always succeed.
 * That is useful with zod.catch() where the error is handled by the zod schema.
 *
 * @param key The key to use for storing the data in local storage.
 * @param parser The parser to use for parsing the data.
 * @param initialData The initial data to use if the key does not exist or is corrupted in local storage.
 */
export function createLocalStorageApi<T>(
  key: string,
  parser: (data: unknown) => T,
  initialData: T
): ReadWriteApi<T> {
  return {
    load: () => {
      const data = localStorage.getItem(key);
      try {
        return parser(data ? JSON.parse(data) : null);
      } catch (e) {
        console.warn(
          `LocalStorageApi: Failed to parse data for key ${key}. Resetting to initial data.`,
          e
        );
        localStorage.setItem(key, JSON.stringify(initialData));
        return initialData;
      }
    },
    unsafeRawLoad: () => {
      const data = localStorage.getItem(key);
      return parser(data ? JSON.parse(data) : null);
    },
    save: data => {
      localStorage.setItem(key, JSON.stringify(data));
    },
    reset: () => {
      localStorage.setItem(key, JSON.stringify(initialData));
    }
  };
}
