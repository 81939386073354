import { motion } from 'motion/react';
import { Img } from 'react-image';
import { useInView } from 'react-intersection-observer';
import { LoadingSpinner } from '../../../../../v5/design-sytem/LoadingSpinner';

type LazyImageProps = {
  width?: string | number;
  height?: string | number;
  src: string | string[];
  onLoad?: () => void;
  isDarkMode?: boolean;
};

export const LazyImage: React.FC<LazyImageProps> = ({
  width,
  height,
  src,
  onLoad,
  isDarkMode,
  ...rest
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true // this is not necessary, but just found out that we don't need to hide image once loaded
  });

  const loaderContainer = (children: React.ReactNode) => (
    <motion.div
      key={`loader-${src}`}
      className="w-full h-full flex items-center justify-center"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.3 }}
    >
      {children}
    </motion.div>
  );

  const container = (children: React.ReactNode) => (
    <motion.div
      key={`container-${src}`}
      className="lazy-image__container"
      style={{
        // todo v5 should have cleaned version
        height: '100%'
      }}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      {children}
    </motion.div>
  );

  return (
    <div // todo v5 should have cleaned version
      ref={ref}
      className="lazy-image" // be careful, tailwind-root will change image layout defaults!
      style={{
        position: 'relative',
        // @ts-expect-error not migrating js code right now
        paddingBottom: `${(height / width) * 100}%`,
        height: '100%'
      }}
    >
      <Img
        {...rest}
        // @ts-expect-error see old working behavior to disable image loading useInView
        src={inView && src}
        width={width}
        height={height}
        onLoad={onLoad}
        loader={<LoadingSpinner color="white" />}
        loaderContainer={loaderContainer}
        container={container}
      />
    </div>
  );
};

export default LazyImage;
