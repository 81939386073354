import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Label } from 'v1/components/shared';

import moment from 'moment-timezone';
import DatePicker, { registerLocale } from 'react-datepicker';
import en from 'date-fns/locale/en-US';
import pl from 'date-fns/locale/pl';
import zh from 'date-fns/locale/zh-CN';
import './SingleDateInput.scss';
import { withTranslation } from 'react-i18next';

class SingleDateInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focused: false
    };
  }
  componentDidMount() {
    const { i18n } = this.props;
    registerLocale(i18n.language, { pl, zh, en }[i18n.language] ?? en);
    this.setState({ loaded: true }); // Required for server side rendering as DatePicker needs .window
  }

  // Formatting javascript date object as moment() offsets the time based on the local machines timezone and the system timezone.
  // We don't want this. We just want to grab the pure date.
  formatDate = d => {
    var month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };

  onChange = value => {
    if (!value) {
      this.props.onChange({ date: null });
      return;
    }

    let date = this.props.showTimeSelect
      ? moment(value).format()
      : this.formatDate(value);
    this.props.onChange({ date });
  };

  // DatePicker is using JS dates which doesn't take timezones into account.
  // We need to manually offset the date before sending it through
  getOffsettedDate = date => {
    const mo1 = moment(date).tz(moment.tz.guess()); // in browser default time zone, e.g. America/Denver
    const mo2 = moment(date); // in current state time zone e.g. America/LosAngeles
    const modifiedDate = moment(date);
    modifiedDate.subtract(mo1.utcOffset() - mo2.utcOffset(), 'minutes');

    return modifiedDate.valueOf();
  };

  convertUTCToLocalDate(value) {
    const date = new Date(value);
    return new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate()
    );
  }

  renderDatePicker = () => {
    const {
      date,
      dateFormat,
      placeholder,
      disabled,
      className,
      startDate,
      endDate,
      selectsStart,
      selectsEnd,
      showTimeSelect,
      minDate,
      maxDate,
      highlightDates,
      filterDate
    } = this.props;

    if (!this.state.loaded) return null;

    return (
      <DatePicker
        selected={date ? this.convertUTCToLocalDate(date) : null}
        placeholderText={placeholder}
        onChange={this.onChange}
        disabled={disabled}
        dateFormat={dateFormat ? dateFormat : 'MM-dd-yyyy'}
        startDate={startDate ? this.getOffsettedDate(startDate) : null}
        endDate={endDate ? this.getOffsettedDate(endDate) : null}
        selectsStart={selectsStart}
        selectsEnd={selectsEnd}
        className={className}
        showTimeSelect={showTimeSelect}
        popperModifiers={{
          preventOverflow: {
            enabled: true
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        highlightDates={highlightDates}
        filterDate={filterDate}
        locale={this.props.i18n.language}
      >
        {this.props.children && (
          <div className="react-datepicker-children">{this.props.children}</div>
        )}
      </DatePicker>
    );
  };

  render() {
    return this.props.label ? ( // NOTE: conditional here for backwards-compatibility so as not to alter any styling
      <div
        className={classnames('SingleDateInput', {
          [`SingleDateInput_${this.props.size}`]: this.props.size
        })}
      >
        <Label className={this.props.labelClassName}>{this.props.label}</Label>
        {this.renderDatePicker()}
      </div>
    ) : (
      this.renderDatePicker()
    );
  }
}

// NOTE: wrapper for react-datepicker (https://reactdatepicker.com)
// NOTE: date ranges use two pickers, both supplied with both startDate & endDate, and one each of selectsStart/selectsEnd
SingleDateInput.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  labelClassName: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  dateFormat: PropTypes.string, // E.g. 'dd-MM-yyyy'
  placeholder: PropTypes.string,
  showTimeSelect: PropTypes.bool,
  date: PropTypes.any, // Used for selected date // TODO: type
  startDate: PropTypes.any, // Used for date ranges, with two pickers // TODO: type
  endDate: PropTypes.any, // Used for date ranges, with two pickers // TODO: type
  selectsStart: PropTypes.bool, // Indicates start-of-range picker
  selectsEnd: PropTypes.bool, // Indicates end-of-range picker
  children: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default withTranslation('v1_shared')(SingleDateInput);
