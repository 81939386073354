import { formatDate } from '@fullcalendar/react';
import { maybe } from '@passionware/monads';
import moment from 'moment-timezone';
import { z } from 'zod';
import { getEventDates } from '../../../v1/helpers/byModel/EventHelper';
import { parseInitials } from '../../../v1/helpers/misc';
import { production$ } from '../production-calendar-event/production-calendar-event.api.http.schema';
import { GlobalSearchEntry } from './global-search.api';
import { convertImageFileWithCloudinary } from 'lib/media/imageHelper';

export const globalSearch$ = z.object({
  callouts: z.array(
    z
      .object({
        id: z.number(),
        title: z.string(),
        description: z.string().nullish().optional()
      })
      .transform<GlobalSearchEntry>(x => ({
        id: x.id,
        name: x.title,
        description: maybe.getOrUndefined(x.description),
        url: `/callouts/${x.id}`,
        group: 'callout'
      }))
  ),
  callsheets: z.array(
    z
      .object({
        id: z.number(),
        title: z.string(),
        from_date: z.coerce.date()
      })
      .transform<GlobalSearchEntry>(x => ({
        id: x.id,
        name: x.title,
        url: `/callsheets/${x.id}`,
        badge: {
          text: moment(x.from_date).format('MM/DD/YYYY')
        },
        group: 'callsheet'
      }))
  ),
  contacts: z.array(
    z
      .object({
        id: z.number(),
        full_name: z.string().nullish().optional(),
        first_name: z.string().nullish().optional(),
        last_name: z.string().nullish().optional(),
        profile_picture: z.string().nullish().optional(),
        groups: z
          .array(
            z.object({
              name: z.string()
            })
          )
          .optional()
      })
      .transform<GlobalSearchEntry>(x => ({
        id: x.id,
        name: x.full_name ?? `${x.first_name} ${x.last_name}`,
        description: x.groups?.[0]?.name,
        url: `/contacts/${x.id}`,
        avatar: {
          url: maybe.mapOrUndefined(x.profile_picture, x =>
            convertImageFileWithCloudinary(x, { width: 40, quality: 80 })
          ),
          fallback: parseInitials(x.first_name, x.last_name, x.full_name)
        },
        group: 'contact'
      }))
  ),
  productions: z.array(
    production$.transform<GlobalSearchEntry>(x => ({
      id: x.id,
      name: x.title ?? '',
      url: `/productions/${x.id}`,
      avatar: {
        ascii: maybe.getOrUndefined(x.icon)
      },
      badge: {
        text: moment(getEventDates(x.production_date)[0]).format('MM/DD/YYYY')
      },
      group: 'production'
    }))
  ),
  shortlists: z.array(
    z
      .object({
        id: z.number(),
        title: z.string()
      })
      .transform<GlobalSearchEntry>(x => ({
        id: x.id,
        name: x.title,
        url: `/shortlists/${x.id}`,
        group: 'shortlist'
      }))
  )
});
