import {
  QueryClient,
  useMutation,
  useMutationState,
  useQueries,
  useQuery,
  useQueryClient
} from '@tanstack/react-query';
import { keyResolver } from '@yornaath/batshit';
import { Resource, Shortlist } from '__types__/index';
import { isShortlistBlockWithLegacyContactField } from '__types__/v1/models/Shortlist.helpers';
import { useIsGuest } from 'v4/entities/common/hooks/useIsGuest';
import { resourceEffectReducer } from 'v4/entities/resource/resource.effect.reducer';
import {
  ResourceEffect,
  ResourceEffects
} from 'v4/entities/resource/resource.effect.types';
import { shortlistInternals } from 'v4/entities/shortlist/shortlist.hooks';
import { createBatcher } from '../common/batcher';
import { ListQuery, ListResponse } from '../common/common.crud.types';
import { ResourcesApi } from './resource.http';

type MaybeResourceId = Resource['id'] | undefined; // hooks should support pages that are not associated with any resource at given moment
const resourceKeys = {
  all: () => ['resources'] as const,
  lists: () => [...resourceKeys.all(), 'list'] as const,
  list: (query: unknown) => [...resourceKeys.lists(), { query }] as const,
  details: () => [...resourceKeys.all(), 'detail'] as const,
  detail: (id: MaybeResourceId) =>
    [...resourceKeys.details(), id === undefined ? id : String(id)] as const
};

type MutationPayload<Payload> = {
  id: Resource['id'];
} & Omit<Payload, 'type'>;

const invalidateLists = (client: QueryClient) => {
  client.invalidateQueries({
    queryKey: resourceKeys.lists(),
    type: 'active'
  });
  client.removeQueries({
    queryKey: resourceKeys.lists(),
    type: 'inactive'
  });
  // todo create generic mechanism to precisely updaate all queries: lists, and all details that are relevant
};

const invalidateDetails = (client: QueryClient, id: Resource['id']) => {
  client.invalidateQueries({
    queryKey: resourceKeys.detail(id),
    type: 'active'
  });
  client.removeQueries({
    queryKey: resourceKeys.detail(id),
    type: 'inactive'
  });
  // todo create generic mechanism to precisely updaate all queries: lists, and all details that are relevant
};

const updateCacheWithItem = (client: QueryClient, item: Resource) => {
  client.setQueriesData<ListResponse<Resource, ListQuery<Resource>>>(
    {
      queryKey: resourceKeys.lists()
    },
    input => {
      return (
        input && {
          ...input,
          results: input.results.map(resource =>
            resource.id === item.id ? item : resource
          )
        }
      );
    }
  );
  client.invalidateQueries({
    // we need to invalidate because lists can be filtered by changed fields
    type: 'active',
    queryKey: resourceKeys.lists()
  });
  client.removeQueries({
    type: 'inactive',
    queryKey: resourceKeys.lists()
  });
  client.setQueryData(resourceKeys.detail(item.id), item);
};

export const resourceInternals = {
  keys: resourceKeys,
  invalidateLists,
  invalidateDetails,
  updateCacheWithItem
};

export const createResourcesHooks = (api: ResourcesApi) => {
  const batcher = createBatcher<Resource['id'], Resource[], Resource>(
    async (payload, signal) =>
      (await api.batchDetail({ ids: payload }, signal)).data,
    keyResolver('id')
  );
  // todo create a structure that will register per-query a function that updates own items based on any other list query response

  const useList = (query: ListQuery<Resource>) =>
    useQuery({
      queryKey: resourceKeys.list(query),
      queryFn: () => api.list(query)
    });

  const useItem = (id: MaybeResourceId) => {
    const client = useQueryClient();
    const isGuest = useIsGuest();
    return useQuery({
      enabled: id !== undefined,
      queryKey: resourceKeys.detail(id),
      queryFn: ({ signal }) => batcher.fetch({ payload: id!, signal }),
      initialData: () => {
        function findInListQueries() {
          const queriesData = client.getQueriesData<
            ListResponse<Resource, ListQuery<Resource>>
          >({ queryKey: resourceKeys.lists() });
          return queriesData
            .find(
              ([key, list]) => list?.results.find(item => item.id === id)
            )?.[1]
            ?.results.find(item => item.id === id);
        }

        /**
         * This function is a workaround for the fact that this query is not available for guests.
         * The only way to get this data is to find it in public shortlists.
         */
        function findInPublicShortlists() {
          const queriesData = client.getQueriesData<Shortlist>({
            queryKey: shortlistInternals.keys.details()
          });

          for (const [key, shortlist] of queriesData) {
            if (shortlist?.shortlist_blocks) {
              for (const block of shortlist.shortlist_blocks) {
                if (isShortlistBlockWithLegacyContactField(block)) {
                  if (block.contact.id === id) {
                    return block.contact;
                  }
                }
              }
            }
          }
        }
        return findInListQueries() || findInPublicShortlists();
      }
    });
  };

  const useItemMany = (ids: Resource['id'][]) =>
    useQueries({
      queries: ids.map(id => ({
        enabled: id !== undefined,
        queryKey: resourceKeys.detail(id),
        queryFn: ({ signal }) => batcher.fetch({ payload: id!, signal })
        // todo extract query config from single item, and simply reuse it here
      })),
      combine: results => {
        const atLeastOneError = results.find(result => result.isError);
        const atLeastOnePending = results.find(result => result.isPending);

        // as per docs, this is going to be referentially stable as much as possible
        return {
          status: atLeastOneError
            ? 'error'
            : atLeastOnePending
            ? 'pending'
            : 'success',
          data: results.map(result => result.data)
        };
      }
    });

  const useItemUpdateMutation = () => {
    const client = useQueryClient();

    return useMutation({
      mutationFn: (payload: MutationPayload<ResourceEffects['update']>) =>
        api.detailUpdate(payload.id, payload.payload),
      mutationKey: resourceKeys.details(),
      onSuccess: (response, { id }) => {
        updateCacheWithItem(client, response);
      },
      onMutate: effect => ({
        reducer: (oldData: Resource) =>
          resourceEffectReducer(oldData, { ...effect, type: 'update' })
      })
    });
  };

  const useItemMutationState = (id: Resource['id'] | undefined) => {
    return useMutationState<MutationPayload<ResourceEffect>>({
      filters: {
        mutationKey: resourceKeys.details(),
        status: 'pending',
        // @ts-expect-error todo check if this is correct against newer version of Tanstack
        predicate: mutation => mutation.state.variables?.id === id
      }
    });
  };

  return {
    useList,
    useItem,
    useItemMany,
    useItemUpdateMutation,
    useItemMutationState
  };
};

export type ResourceHooks = ReturnType<typeof createResourcesHooks>;
