import { AUTH_ACTIONS } from 'store/v1/auth/auth.constants.js';
import Account from 'lib/auth/Account';
import Env from 'lib/env/Env';
import Api from 'lib/auth/authentication';

const token = true;

export function loadAuth(token, tokenString, event_id) {
  const workspace = Account.resolveFirstWorkspace();
  if (!workspace) {
    console.error('expected non-null workspace');
  }

  const dbSchema = workspace ? workspace.db_schema : '';
  const url = `${Env.workspacesApiURL}/${dbSchema}/settings`;

  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.LOAD_AUTH,
        AUTH_ACTIONS.LOAD_AUTH_SUCCESS,
        AUTH_ACTIONS.LOAD_AUTH_FAILURE
      ],
      promise: client => client.get(url, { token: tokenString }),
      token: token,
      event_id
    });
  };
}

export function updateSettings({ field_key }, data, event_id = null) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.UPDATE_SETTINGS,
        AUTH_ACTIONS.UPDATE_SETTINGS_SUCCESS,
        AUTH_ACTIONS.UPDATE_SETTINGS_FAILURE
      ],
      promise: client => client.put(`/settings/${field_key}`, { data, token }),
      event_id,
      field_key
    });
  };
}

export function createFieldGroup({
  name,
  custom_field_definitions,
  is_array,
  options
}) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.CREATE_FIELDGROUP,
        AUTH_ACTIONS.CREATE_FIELDGROUP_SUCCESS,
        AUTH_ACTIONS.CREATE_FIELDGROUP_FAILURE
      ],
      promise: client =>
        client.post(`/settings/custom_fieldgroup_definitions`, {
          data: {
            name,
            custom_field_definitions,
            is_array,
            // required: false,
            archived: false,
            options
          },
          token
        })
    });
  };
}

export function updateFieldGroup(
  id,
  { archived, name, custom_field_definitions, is_array, options }
) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.UPDATE_FIELDGROUP,
        AUTH_ACTIONS.UPDATE_FIELDGROUP_SUCCESS,
        AUTH_ACTIONS.UPDATE_FIELDGROUP_FAILURE
      ],
      promise: client =>
        client.put(`/settings/custom_fieldgroup_definitions/${id}`, {
          data: {
            id,
            archived,
            name,
            custom_field_definitions,
            is_array,
            options
          },
          token
        }),
      id
    });
  };
}

export function deleteFieldGroup(id, event_id = null) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.DELETE_FIELDGROUP,
        AUTH_ACTIONS.DELETE_FIELDGROUP_SUCCESS,
        AUTH_ACTIONS.DELETE_FIELDGROUP_FAILURE
      ],
      promise: client =>
        client.del(`/settings/custom_fieldgroup_definitions/${id}`, {
          token
        }),
      event_id,
      id
    });
  };
}

export function createField(
  {
    name,
    data_type,
    required,
    filterable,
    options,
    measurement_unit_lock,
    measurement_default_unit
  },
  event_id = null
) {
  return {
    types: [
      AUTH_ACTIONS.CREATE_FIELD,
      AUTH_ACTIONS.CREATE_FIELD_SUCCESS,
      AUTH_ACTIONS.CREATE_FIELD_FAILURE
    ],
    promise: client =>
      client.post(`/settings/custom_field_definitions`, {
        data: {
          name,
          data_type,
          required,
          filterable,
          options,
          measurement_unit_lock,
          measurement_default_unit
        },
        token
      }),
    event_id
  };
}

export function updateField(
  id,
  {
    archived,
    name,
    data_type,
    required,
    filterable,
    options,
    measurement_unit_lock,
    measurement_default_unit
  },
  event_id = null
) {
  return {
    types: [
      AUTH_ACTIONS.UPDATE_FIELD,
      AUTH_ACTIONS.UPDATE_FIELD_SUCCESS,
      AUTH_ACTIONS.UPDATE_FIELD_FAILURE
    ],
    promise: client =>
      client.put(`/settings/custom_field_definitions/${id}`, {
        data: {
          archived,
          name,
          data_type,
          required,
          filterable,
          options,
          measurement_unit_lock,
          measurement_default_unit
        },
        token
      }),
    event_id,
    id
  };
}

export function deleteField(id, event_id = null) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.DELETE_FIELD,
        AUTH_ACTIONS.DELETE_FIELD_SUCCESS,
        AUTH_ACTIONS.DELETE_FIELD_FAILURE
      ],
      promise: client =>
        client.del(`/settings/custom_field_definitions/${id}`, { token }),
      event_id,
      id
    });
  };
}

/**
 * Request all authentication methods
 * @returns {function(...[*]=)}
 */
export function requestAuthMethods() {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.GET_AUTH_METHODS_REQUEST,
        AUTH_ACTIONS.GET_AUTH_METHODS_SUCCESS,
        AUTH_ACTIONS.GET_AUTH_METHODS_FAILURE
      ],
      promise: () => Api.getTenantAuthMethods()
    });
  };
}

/**
 * Update an existing authentication method
 * @param {'local'|'saml'|'google'} methodId
 * @param {Object} method
 * @returns {function(...[*]=)}
 */
export function updateAuthMethod(methodId, method) {
  return function (dispatch) {
    dispatch({
      types: [
        AUTH_ACTIONS.UPDATE_AUTH_METHOD_REQUEST,
        AUTH_ACTIONS.UPDATE_AUTH_METHOD_SUCCESS,
        AUTH_ACTIONS.UPDATE_AUTH_METHOD_FAILURE
      ],
      promise: () => Api.updateAuthMethod(methodId, method),
      payload: {
        id: methodId,
        ...method
      }
    });
  };
}
