import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { SystemLink } from 'v1/components/shared';
import ReactTooltip from 'react-tooltip';
import './Button.scss';

class Button extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      isActive: false,
      tooltip: this.props.dataTip
    };
  }

  componentDidMount = () => {
    ReactTooltip.rebuild();
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!this.props.isLoading && nextProps.isLoading) {
      this.setState({ isLoading: true });
    }
    if (this.props.isLoading && !nextProps.isLoading) {
      this.setState({ isLoading: false, isActive: false });
    }
  }

  onClick = e => {
    if (this.props.preventDefault) {
      e.preventDefault();
    }
    if (this.props.stopPropagation) {
      e.stopPropagation();
    }

    if (!this.props.isLoading && this.props.onClick) {
      this.setState({ isActive: true }, () => {
        this.props.onClick();
      });
    }
  };

  renderContent() {
    return (
      <span className="Button-labelWrapper">
        {this.props.children}
        {this.props.arrowRight ? (
          <img
            src={
              this.props.dark
                ? '/images/arrow_right.svg'
                : '/images/icon-arrow-right.svg'
            }
            className="btn-arrow"
            alt=""
          />
        ) : null}
      </span>
    );
  }

  renderLoading() {
    return (
      <span className={`loading-circle ${this.props.dark ? 'dark' : ''}`} />
    );
  }

  render() {
    if (this.props.externalLink) {
      return (
        <a
          href={this.props.externalLink}
          className={this.props.className}
          role="button"
          disabled={this.props.disabled}
          tabIndex={0}
          data-tip={this.state.tooltip}
          type={this.props.type}
          id={this.props.id}
          target={this.props.target}
        >
          {this.props.children}
          {this.props.arrowRight ? (
            <img
              src={
                this.props.dark
                  ? '/images/arrow_right.svg'
                  : '/images/icon-arrow-right.svg'
              }
              alt=""
              className="btn-arrow"
              width="12px"
            />
          ) : null}
        </a>
      );
    }
    if (this.props.href) {
      return (
        <Link
          to={this.props.href}
          onClick={this.onClick}
          className={`${this.props.className}`}
          role="button"
          disabled={this.props.disabled}
          tabIndex={0}
          data-tip={this.state.tooltip}
          type={this.props.type}
          id={this.props.id}
          target={this.props.target}
        >
          {this.props.children}
          {this.props.arrowRight ? (
            <img
              src={
                this.props.dark
                  ? '/images/arrow_right.svg'
                  : '/images/icon-arrow-right.svg'
              }
              alt=""
              className="btn-arrow"
              width="12px"
            />
          ) : null}
        </Link>
      );
    }
    if (this.props.systemLink) {
      return (
        <SystemLink
          to={this.props.systemLink}
          onClick={this.onClick}
          className={`${this.props.className}`}
          role="button"
          disabled={this.props.disabled}
          tabIndex={0}
          data-tip={this.state.tooltip}
          type={this.props.type}
          id={this.props.id}
          target={this.props.target}
        >
          {this.props.children}
          {this.props.arrowRight ? (
            <img
              src={
                this.props.dark
                  ? '/images/arrow_right.svg'
                  : '/images/icon-arrow-right.svg'
              }
              alt=""
              className="btn-arrow"
              width="12px"
            />
          ) : null}
        </SystemLink>
      );
    }
    if (this.props.onClick) {
      return (
        <button
          className={`${this.props.className} ${
            this.state.isLoading &&
            (this.props.enterEnabled ? true : this.state.isActive)
              ? 'loading'
              : ''
          }`}
          onClick={this.onClick}
          disabled={this.props.disabled}
          tabIndex={0}
          data-tip={this.state.tooltip}
          data-place={this.props.dataPlace}
          data-cy={this.props.dataCy}
          type={this.props.type}
          id={this.props.id}
        >
          {this.state.isLoading &&
          (this.props.enterEnabled ? true : this.state.isActive)
            ? this.renderLoading()
            : this.renderContent()}
        </button>
      );
    }
    return <span />;
  }
}

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  arrowRight: PropTypes.bool,
  dark: PropTypes.bool,

  children: PropTypes.any,
  dataTip: PropTypes.string,
  dataCy: PropTypes.string,

  disabled: PropTypes.bool,
  isLoading: PropTypes.any,
  type: PropTypes.string,
  enterEnabled: PropTypes.bool,
  preventDefault: PropTypes.bool,
  stopPropagation: PropTypes.bool,

  target: PropTypes.string,
  href: PropTypes.string,
  externalLink: PropTypes.string,
  systemLink: PropTypes.string,
  onClick: PropTypes.func
};

export default withRouter(Button);
