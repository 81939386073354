import React, { useState } from 'react';
import { ModalContent } from '../../../v1/components/modals/layout';
import { useSelector } from 'react-redux';
import { PDFViewer } from '@react-pdf/renderer';
import { generateShortlistsFieldMapper, getAllResourceTypeFields } from 'v1/helpers/shortlistFieldHelper';
import { getResourceTypes } from 'store/v1/contacts/contacts.selectors.js';

import { filter, find, get } from 'lodash';
import { Grid, GridCell, Heading, Text } from 'shared';
import ShortlistFieldManager from 'v1/components/feature/ShortlistComponents/ShortlistPresentView/ShortlistFieldManager/ShortlistFieldManager';
import Modal from 'modals/layout/Modal';
import ResourcePDFDocument from './ResourcePDFDocument';
import { isImageFromType } from 'lib/media/files';
import ModalHeader from 'modals/layout/ModalHeader/ModalHeader';

const DEFAULT_FIELD_LIST = ["locations", "emails", "phone_numbers", "websites"];

const GenerateResourcePDFModal = ({ resourceId }) => {
  const resource = useSelector((state: any) => state.contacts.data[resourceId]);
  const associations = useSelector((state: any) => state.contacts.associations_data[resourceId]);
  const [selectedFields, setSelectedFields] = useState(DEFAULT_FIELD_LIST);

  // Get field mappings 
  const auth = useSelector((state: any) => state.auth);
  const customfieldDefinitions = get(auth, [
    'settings',
    'custom_field_definitions'
  ]);

  const resourceTypes = useSelector(state => find(getResourceTypes(state), r => r.id === resource.resource_type_id))

  const resourceTypeFields = getAllResourceTypeFields(
    filter([resourceTypes], Boolean)
  );

  const fieldMappings = generateShortlistsFieldMapper(resourceTypeFields, customfieldDefinitions);

  // Get Pinboard images
  const pins = get(associations, 'pins', []);
  const pinImages = pins.filter((pin) => {
    if (pin.archived) return false;
    if (pin.pin_type === 'file') {
      return isImageFromType(pin.file.mime_type)
    } else {
      return isImageFromType(pin.pin_type)
    }
  })

  return (
    <Modal size='l'>
      <ModalHeader
        title="Generate resource PDF"
      />
      <ModalContent>
        <Grid fullHeight>
          <GridCell width="1/4">
            <Grid direction='column' fullHeight overflow="auto">
              <GridCell padding="m">
                <ShortlistFieldManager
                  onSubmit={({ default_fields }) => setSelectedFields(default_fields)}
                  // @ts-expect-error ts migration needed
                  default_fields={selectedFields}
                  shortlist_field_mapper={fieldMappings}
                  query={{}}
                />
              </GridCell>
            </Grid>
          </GridCell>
          <GridCell width="3/4">
            <PDFViewer width="100%" height="100%" style={{ border: 0 }}>
              <ResourcePDFDocument resource={resource} fieldMappings={fieldMappings} gallery={pinImages} selectedFields={selectedFields} />
            </PDFViewer>
          </GridCell>
        </Grid>
      </ModalContent>
    </Modal>
  )
};

export default GenerateResourcePDFModal;
