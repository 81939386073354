import { cva, VariantProps } from 'class-variance-authority';
import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import { cn } from 'v5/platform/dom/cn';
import { focusRings } from './_common/focusRing';

// Define the base styles, variants, and default variants using CVA
export const buttonVariants = cva(
  [
    focusRings.focus.visible,
    'flex items-center justify-center font-semibold whitespace-nowrap',
    '[&_svg]:shrink-0'
  ],
  {
    variants: {
      variant: {
        primary:
          'bg-brand-500 not-aria-disabled:hocus:bg-brand-600 text-white aria-disabled:bg-brand-600/50',
        secondary: [
          'bg-bg-default text-fg-default aria-disabled:bg-bg-default/50 aria-disabled:text-fg-default/50',
          'border border-border-default not-aria-disabled:hocus:border-bg-inset not-aria-disabled:hocus:bg-bg-subtle/30 '
        ],
        primaryAlt: 'bg-brand-900 text-white aria-disabled:bg-brand-900/50',
        danger:
          'bg-bg-danger-emphasis not-aria-disabled:hocus:bg-bg-danger-emphasis text-white aria-disabled:bg-bg-danger-emphasis/50',
        success:
          'bg-bg-success-emphasis not-aria-disabled:hocus:bg-bg-success-emphasis text-white aria-disabled:bg-bg-success-emphasis/50',
        invert:
          'bg-white/10 not-aria-disabled:hocus:bg-white/20 text-white aria-disabled:bg-white/50 aria-disabled:text-white/50 border border-transparent',
        tertiary:
          'not-aria-disabled:hocus:bg-grey-light border border-border-default text-fg-default aria-disabled:text-fg-default/50',
        tertiarySelected:
          'bg-grey not-aria-disabled:hocus:bg-grey-light border border-grey text-fg-default aria-disabled:text-fg-default/50',
        ghost:
          'border border-transparent not-aria-disabled:hocus:border-bg-inset not-aria-disabled:hocus:bg-bg-subtle/30 text-fg-default aria-disabled:text-fg-default/50 not-aria-disabled:hocus:border-charcoal-dark/10'
      },
      size: {
        xs: 'text-xs gap-1 leading-xs px-2 h-7 py-3 [&_svg]:w-3 [&_svg]:h-3',
        sm: 'text-sm gap-2 leading-xs px-3 h-9 py-1.5 [&_svg]:w-4 [&_svg]:h-4',
        md: 'text-base gap-2.5 leading-tight h-11 px-4 py-1.5 [&_svg]:w-5 [&_svg]:h-5'
      },
      layout: {
        normal: '',
        icon: ''
      },
      shape: {
        normal: 'rounded',
        rounded: 'rounded-full',
        tab: 'rounded-t'
      },
      disabled: {
        true: 'cursor-not-allowed',
        false: ''
      }
    },
    compoundVariants: [
      {
        layout: 'icon',
        size: 'xs',
        className: 'size-7 shrink-0 p-0'
      },
      {
        layout: 'icon',
        size: 'sm',
        className: 'size-9 shrink-0 p-0'
      },
      {
        layout: 'icon',
        size: 'md',
        className: 'size-11 shrink-0 p-0'
      }
    ],
    defaultVariants: {
      variant: 'primary',
      size: 'md',
      layout: 'normal',
      shape: 'normal'
    }
  }
);

export type ButtonVariant = VariantProps<typeof buttonVariants>['variant'];
export type ButtonSize = VariantProps<typeof buttonVariants>['size'];
export type ButtonLayout = VariantProps<typeof buttonVariants>['layout'];
export type ButtonShape = VariantProps<typeof buttonVariants>['shape'];

export type ButtonProps = {
  visuallyDisabled?: boolean;
} & VariantProps<typeof buttonVariants> &
  DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      variant,
      type = 'button',
      size,
      layout,
      shape,
      visuallyDisabled,
      disabled,
      className,
      ...props
    },
    ref
  ) => {
    return (
      <button
        type={type}
        className={cn(
          buttonVariants({
            variant,
            size,
            layout,
            shape,
            disabled: disabled || visuallyDisabled,
            className
          })
        )}
        aria-disabled={disabled || visuallyDisabled}
        disabled={disabled}
        {...props}
        ref={ref}
      />
    );
  }
);
