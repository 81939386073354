import { maybe, Maybe } from '@passionware/monads';
import { CustomFieldType } from '../../../../__types__';

type BasicFieldDefinition = {
  name: string;
  isRequired: boolean;
  isFilterable: boolean;
};

export type FieldDefinition = BasicFieldDefinition &
  (
    | {
        type: Exclude<
          CustomFieldType,
          | CustomFieldType.MEASUREMENT_NEW
          | CustomFieldType.SINGLE_SELECT
          | CustomFieldType.MULTI_SELECT
        >;
      }
    | {
        type: CustomFieldType.MULTI_SELECT | CustomFieldType.SINGLE_SELECT;
        options: string[];
      }
    | {
        type: CustomFieldType.MEASUREMENT_NEW;
        defaultUnit: string;
        lockUnit: boolean;
      }
  );

export type FieldFormModel = {
  type: Maybe<CustomFieldType>;
  name: string;
  isRequired: boolean;
  isFilterable: boolean;
  select?: {
    options: { value: string }[];
  };
  measurement?: {
    defaultUnit: string;
    lockUnit: boolean;
  };
};

export const formFieldModel = {
  fromDefinition: (definition: Maybe<FieldDefinition>): FieldFormModel => {
    if (maybe.isAbsent(definition)) {
      // we need to return a default model for new fields
      return {
        type: null,
        name: '',
        isRequired: false,
        isFilterable: false,
        select: {
          options: [
            { value: 'Option 1' },
            { value: 'Option 2' },
            { value: 'Option 3' }
          ]
        },
        measurement: {
          defaultUnit: 'cm',
          lockUnit: false
        }
      };
    }
    switch (definition.type) {
      case CustomFieldType.SINGLE_SELECT:
      case CustomFieldType.MULTI_SELECT:
        return {
          type: definition.type,
          name: definition.name,
          isRequired: definition.isRequired,
          isFilterable: definition.isFilterable,
          select: {
            options: definition.options.map(value => ({ value }))
          }
        };
      case CustomFieldType.MEASUREMENT_NEW:
        return {
          type: definition.type,
          name: definition.name,
          isRequired: definition.isRequired,
          isFilterable: definition.isFilterable,
          measurement: {
            defaultUnit: definition.defaultUnit,
            lockUnit: definition.lockUnit
          }
        };
      default:
        return {
          type: definition.type,
          name: definition.name,
          isRequired: definition.isRequired,
          isFilterable: definition.isFilterable
        };
    }
  },
  toDefinition: (model: FieldFormModel): FieldDefinition | null => {
    switch (model.type) {
      case CustomFieldType.SINGLE_SELECT:
      case CustomFieldType.MULTI_SELECT:
        return {
          type: model.type,
          name: model.name,
          isRequired: model.isRequired,
          isFilterable: model.isFilterable,
          options: model.select?.options.map(option => option.value) ?? []
        };
      case CustomFieldType.MEASUREMENT_NEW:
        return {
          type: model.type,
          name: model.name,
          isRequired: model.isRequired,
          isFilterable: model.isFilterable,
          defaultUnit: model.measurement?.defaultUnit ?? '',
          lockUnit: model.measurement?.lockUnit ?? false
        };
      default:
        if (maybe.isAbsent(model.type)) {
          return null;
        }
        return {
          type: model.type,
          name: model.name,
          isRequired: model.isRequired,
          isFilterable: model.isFilterable
        };
    }
  }
};
