import React from 'react';

const SidebarSectionLabel = ({ children }) => {
  return (
    <div className="text-eggplant-lightest text-base p-2 truncate">
      {children}
    </div>
  );
};

export default SidebarSectionLabel;
