import { Icon } from 'shared';
import KeyboardShortcut from 'v4/shared/components/KeyboardShortcut/KeyboardShortcut';
import { myServices } from 'v5/services/services.connected.app';

const CommandbarButton = () => {
  return (
      <div className="relative mb-2.5">
      <button
        onClick={() => myServices.globalSearchService.openSearchEvent.emit()}
        className="text-left flex w-full text-white/80 items-center block rounded-md border-eggplant-lightest p-2 pr-14 text-eggplant-lightest ring-1 hover:ring-eggplant-lightest/60 focus:ring-2 ring-eggplant-lightest/40"

      >
          <div className="mr-2">
          <Icon name="v4_search" />
        </div>
        Search
      </button>
        <div className="absolute inset-y-0 right-0 py-2 pr-2 pointer-events-none">
        <KeyboardShortcut shortcut="⌘ K" />
      </div>
    </div>
  );
};

export default CommandbarButton;
