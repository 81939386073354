import { Group, ResourceType } from '__types__/index';
import { isEmpty, size } from 'lodash';
import React from 'react';
import { Dropdown, MenuItem } from 'v1/components/shared';
import { useTranslation } from 'react-i18next';

import './SelectResourceGroup.scss';

interface SelectResourceGroupProps {
  activeGroups: Group['id'][];
  activeResourceType: ResourceType;
  onSelect: (groupId: Group['id']) => void;
}

export type UseGroups = (resourceType: ResourceType) => Group[] | null;
export const createSelectResourceGroup = (useGroups: UseGroups) => {
  return function SelectResourceGroup({
    activeGroups,
    activeResourceType,
    onSelect
  }: SelectResourceGroupProps) {
    const { t } = useTranslation('v1_shared');
    const groupsList = useGroups(activeResourceType);

    return groupsList && groupsList.length ? (
      <div className="stack-S">
        <div className="text-13-600 stack-XS">
          {t('SelectResourceGroup.groups')}
        </div>
        <Dropdown
          buttonLabel={t('SelectResourceGroup.groupSelected', {
            count: size(activeGroups),
            context: isEmpty(activeGroups) ? 'all' : undefined
          })}
          buttonClass="btn btn-default btn-small btn-fill"
        >
          {groupsList.map(group => (
            <MenuItem
              key={`${group.id}- ${group.name}`}
              onSelect={() => onSelect(group.id)}
            >
              <span className="MenuItem-icon">
                {activeGroups.includes(group.id) && (
                  <img src="/images/icon_tick_black.svg" alt="" />
                )}
              </span>
              <span className="MenuItem-label">
                <span
                  className="SelectResourceGroup-item-colour"
                  style={{ backgroundColor: group.colour }}
                />
                {group.name}
              </span>
            </MenuItem>
          ))}
        </Dropdown>
      </div>
    ) : null;
  };
};
