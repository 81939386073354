import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ModalWrapper,
  ModalContent,
  ModalScrollable,
  PressStud
} from 'v1/components/shared';

import './ApplicantSubmittedModal.scss';

const ApplicantSubmittedModal = ({ onRequestClose }) => {
  const message = useSelector(state => state.ui.data.message);
  const { t } = useTranslation('callouts');

  return (
    <ModalWrapper size="XS">
      <ModalContent>
        <ModalScrollable padding="L" className="text-center">
          <img src="/images/icon_colour_send.svg" width="50px" alt="" />
          <h2>{t('ApplicantSubmittedModal.title')}</h2>
          <h3 className="stack-S">
            {message || t('ApplicantSubmittedModal.thanksMessage')}
          </h3>
          <PressStud
            icon="send"
            label={t('ApplicantSubmittedModal.submitAnother')}
            size="L"
            action={onRequestClose}
          />
        </ModalScrollable>
      </ModalContent>
    </ModalWrapper>
  );
};

ApplicantSubmittedModal.props = {};

export default ApplicantSubmittedModal;
