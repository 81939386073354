import { z } from 'zod';

export const order$ = z.object({
  group_order: z.number(),
  resource_slots: z.array(
    z.object({
      resource_slot_id: z.number(),
      resource_slot_order: z.number().catch(() => 0)
    })
  )
});
export type Order$ = z.infer<typeof order$>;

export const productionGroup$ = z.object({
  group_id: z.number(),
  group_name: z.string()
});
export type ProductionGroup$ = z.infer<typeof productionGroup$>;

export const productionGroups$ = z.object({
  production_id: z.number(),
  groups: z.array(productionGroup$)
});
export type ProductionGroups$ = z.infer<typeof productionGroups$>;

export const productionDate$ = z.object({
  id: z.number(),
  event_type: z.enum([
    'BOOKING',
    'RESOURCE_SLOT',
    'RESOURCE_SLOT_ASSIGNMENT',
    'PRODUCTION',
    'PRODUCTION_MILESTONE',
    'PRODUCTION_SCHEDULE',
    'SHORTLIST_DATE'
  ]),
  note: z.string().nullable(),
  timezone: z.string().nullable(),
  start_date: z.string(), // zakładamy format ISO (YYYY-MM-DD)
  start_timestamp: z.number().nullable(),
  end_date: z.string(),
  end_timestamp: z.number().nullable(),
  recurrence: z.any().nullable(),
  estimated_daily_hours: z.number().nullable(),
  status: z.string().nullable(),
  legacy_user_id: z.number().nullable(),
  created_mechanism: z.string().nullable(),
  created_at: z.coerce.date(), // ISO datetime
  updated_at: z.coerce.date(), // ISO datetime
  entity_type: z.any(),
  entity_id: z.number(),
  all_day: z.boolean().nullable(),
  estimated_start_time: z.string().nullable(),
  estimated_end_time: z.string().nullable(),
  include_weekends: z.boolean().nullable(),
  estimated_daily_minutes: z.number().nullable(),
  date_type: z.enum([
    'RECURRING_DEFAULT',
    'RECURRING_HOURS',
    'RECURRING_SPECIFIC_HOURS',
    'INCLUSIVE_HOURS'
  ]),
  status_id: z.number().nullable(),
  user_id: z.coerce.string().nullable()
});
export type ProductionDate$ = z.infer<typeof productionDate$>;

export const productionStatus$ = z.object({
  id: z.number(),
  entity_type: z.number(),
  name: z.string(),
  colour: z.string(),
  status_type: z.string(),
  order: z.number()
});
export type ProductionStatus$ = z.infer<typeof productionStatus$>;

const customField$ = z.object({
  id: z.number(),
  custom_field_definition_id: z.number(),
  boolean_value: z.boolean().nullable(),
  string_value: z.string().nullable(),
  integer_value: z.number().nullable(),
  float_value: z.number().nullable(),
  option_id: z.number().nullable(),
  multi_select_ids: z.array(z.number()).nullable(),
  date_value: z.coerce.date().nullable(),
  archived: z.boolean(),
  created_mechanism: z.string().nullable(),
  created_at: z.coerce.date(),
  updated_at: z.coerce.date(),
  user_id: z.string().nullable(),
  entity_id: z.number(),
  entity_type: z.number()
});

export type CustomField$ = z.infer<typeof customField$>;

export const production$ = z.object({
  id: z.number(),
  title: z.string().nullable(),
  description: z.string().nullable(),
  production_type: z.string().nullable(),
  icon: z.string().nullable(),
  timezone: z.string().nullable(),
  color: z.string().nullable(),
  code: z.coerce.string().nullable(),
  budget_currency: z.string(),
  budget_amount: z.any().nullable(), // Może być liczbą lub null
  archived: z.boolean(),
  legacy_user_id: z.any().nullable(),
  created_mechanism: z.any().nullable(),
  created_at: z.string(),
  updated_at: z.string(),
  production_type_id: z.number(),
  production_template_id: z.number().nullable(),
  status_id: z.number(),
  resourcing_at: z.string().nullable(),
  ready_at: z.string().nullable(),
  completed_at: z.string().nullable(),
  cancelled_at: z.string().nullable(),
  guid: z.string(),
  user_id: z.string(),
  tasklist_id: z.any().nullable(),
  production_date: productionDate$,
  custom_fields: z.array(customField$)
});
export type Production$ = z.infer<typeof production$>;

export const productions$ = z.array(production$);
export type Productions$ = z.infer<typeof productions$>;

export const resourceSlot$ = z.object({
  resource_slot_id: z.number(),
  resource_slot_name: z.string().nullable(),
  resource_slot_order: z.number().catch(() => 0),
  resource_slot_note: z.string().nullable(),
  resource_slot_is_location: z.boolean(),
  resource_slot_category_id: z.number().nullable()
});
export type ResourceSlot$ = z.infer<typeof resourceSlot$>;

export const resourceSlotAssignment$ = z.object({
  contact_id: z.number(),
  contact_first_name: z.string(),
  contact_last_name: z.string().nullable(),
  booking_status_id: z.number()
});
export type ResourceSlotAssignment$ = z.infer<typeof resourceSlotAssignment$>;

export const root$ = z.object({
  mapProductionIdToOrders: z
    .record(z.record(order$))
    .nullable()
    .transform(x => x ?? {}),
  productions: productions$,
  mapProductionIdToResourceSlots: z
    .record(z.array(resourceSlot$))
    .nullable()
    .transform(x => x ?? {}),
  mapResourceSlotIdToAssignments: z
    .record(z.array(resourceSlotAssignment$))
    .nullable()
    .transform(x => x ?? {})
});
export type Root$ = z.infer<typeof root$>;
