import React from 'react';

import {
  displayDimension,
  displayHeight,
  displayMeasurement
} from 'v1/helpers/measurements/measurementsHelper';
import { TagGroup } from 'v1/components/shared';
import ApplicantCarousel from 'v1/components/feature/CalloutComponents/Callout/ApplicantCarousel/ApplicantCarousel';
import {
  convertToWholeUnit,
  currencyFormatter
} from 'v1/helpers/currencyHelper';
import { htmlStringParser } from 'v1/helpers/misc';
import customHelper from 'v1/helpers/customFieldHelper';
import { displayDateRange } from 'v1/helpers/byType/dateHelper';

import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';
import omit from 'lodash/omit';
import moment from 'moment-timezone';
import { isImageFromType } from 'lib/media/files';
import { unitFieldUtils } from '../../../v4/shared/components/forms/input/unit-field-utils.js';

const DEFAULT_RATE_LABEL = 'Default';

export const applicantsFilters = {
  NEW_APPLICANTS: applicant => !applicant.contact_id && !applicant.archived,
  APPROVED_APPLICANTS: applicant => applicant.contact_id && !applicant.archived,
  ARCHIVED_APPLICANTS: applicant => applicant.archived
};

export function getApplicantName(applicantData) {
  const fullNameField = find(applicantData, field => field.key === 'full_name');
  return fullNameField ? fullNameField.value : 'N/A';
}

export function getApplicantFiles(applicantData) {
  const files = find(applicantData, field => field.key === 'files');
  return files ? files.value : null;
}

/**
 * @function renderApplicantFieldValue
 * Maps the available applicant data into a new resource ready object
 *
 * @param {String} type - The type of field so we can render appropriately
 * @param {Any} value - The value itself can be a string, object or array
 */
export const renderApplicantFieldValue = (type, field = {}) => {
  const value = field.value;

  if (
    ((typeof value === 'object' || Array.isArray(value)) && isEmpty(value)) ||
    (type !== 'BOOLEAN' && !value)
  )
    return <span className="text-11-600-eggplant">N/A</span>;

  switch (type) {
    case 'SOCIAL':
      const key = field.key;
      const socialProvider = key.split('social_');
      const socialUrl = `http://${socialProvider[1]}.com/${value}`;
      return (
        <a
          rel="noopener noreferrer"
          className="genericLink trunc"
          href={socialUrl}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {value}
        </a>
      );
    case 'EMAIL':
      return (
        <a
          rel="noopener noreferrer"
          className="genericLink trunc"
          href={`mailto:${value}`}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {value}
        </a>
      );
    case 'URL':
      return (
        <a
          rel="noopener noreferrer"
          className="genericLink trunc"
          href={htmlStringParser(value)}
          target="_blank"
          onClick={e => {
            e.stopPropagation();
          }}
        >
          {value}
        </a>
      );
    case 'SHORT_TEXT':
    case 'LONG_TEXT':
    case 'SINGLE_SELECT':
    case 'NUMBER':
      return value;
    case 'PERCENTAGE':
      return value; // Removing '%' temporarily while we have customers using this field for decimal places
    case 'CURRENCY':
      let customRateAmount = value ? convertToWholeUnit(value.amount) : null;
      return `${currencyFormatter(null, get(value, 'currency')).format(
        customRateAmount
      )} ${get(value, 'rate_interval') || ''}`;
    case 'LOCATION':
      return value;
    case 'BOOLEAN':
      return value ? 'Yes' : 'No';
    case 'GROUP':
      return '';
    case 'MULTI_SELECT':
      return (
        <TagGroup className="fill" tags={value} tagType="name" limit={4} />
      );
    case 'DIMENSION_2D':
      return displayDimension(value);
    case 'DIMENSION_3D':
      return displayDimension(value);
    case 'MEASUREMENT':
      return displayMeasurement(value);
    case 'MEASUREMENT_NEW':
      return unitFieldUtils.formatDisplay(value);
    case 'DATE':
      return moment(value).format('Do MMM YYYY');
    case 'DATE_RANGE':
      return displayDateRange(value);
    case 'HEIGHT':
      return displayHeight(value);
    case 'FILES':
      return (
        <div>
          <ApplicantCarousel files={value} appearance="inline" height={250} />
        </div>
      );
    default:
      return typeof value === 'string' ? value : '--';
  }
};

function selectProfilePictureFromFiles(files = []) {
  let imageFiles = [];

  find(files, (file, index) => {
    if (isImageFromType(file.file.mime_type)) {
      imageFiles.push(file);
    }
  });
  return imageFiles.length ? imageFiles[0]?.url : null;
}

/**
 * @function generateResourceFromApplicant
 * Maps the available applicant data into a new resource ready object
 *
 * @param {Object} applicant - The applicant submitted data
 * @param {Object} callout - The callout object
 * @param {Object} callout_field_mapper - The callout field mapper that allows us grab appropriate field data
 */
export const generateResourceFromApplicant = (
  applicant,
  callout,
  callout_field_mapper
) => {
  const { mappings, resource_type_id } = callout;

  return mappings.reduce(
    (resource, mapping) => {
      if (!mapping.maps_to) return resource;

      const field = applicant.data[mapping.id];
      if (!field) return resource;

      switch (mapping.maps_to) {
        case 'profile_picture':
        case 'role':
          return { ...resource, [mapping.maps_to]: field.value };
        case 'full_name':
          if (field.value && field.value.length) {
            const full_name = field.value.split(' ');
            const first_name = full_name.shift();
            const last_name = full_name.join(' ');
            return { ...resource, first_name, last_name };
          }
          break;
        case 'rates':
          const rate = {
            name: DEFAULT_RATE_LABEL,
            amount: get(field.value, 'amount'),
            interval: get(field.value, 'rate_interval'),
            currency: get(field.value, 'currency')
          };
          const rates = [rate];
          return { ...resource, rates };
        case 'files':
          return {
            ...resource,
            pins: field.value.map(pin => omit(pin, ['id', 'file'])),
            profile_picture: selectProfilePictureFromFiles(field.value)
          };
        case 'tags':
          return { ...resource, tags: field.value.map(tag => ({ tag })) };
        case 'emails':
        case 'websites':
        case 'phone_numbers':
          return {
            ...resource,
            [mapping.maps_to]: (resource[mapping.maps_to] || []).concat({
              value_1: field.value
            })
          };
        case 'locations':
          if (mapping.maps_to === 'locations') {
            return {
              ...resource,
              locations: (resource.locations || []).concat({
                name: field.value
              })
            };
          }
          break;
        default:
      }

      // SOCIAL HANDLING
      if (mapping.maps_to.includes('social_')) {
        const value_1 = field.value;
        const value_2 = mapping.maps_to.split('_')[1];
        return {
          ...resource,
          socials: (resource.socials || []).concat({ value_1, value_2 })
        };
      }

      // CUSTOM FIELD HANDLING
      if (mapping.maps_to.includes('custom_')) {
        if (mapping.type === 'MULTI_SELECT') {
          if (field.value.length) {
            field.value = field.value.map(v => v.id);
          } else return resource;
        }

        if (mapping.type === 'SINGLE_SELECT') {
          const options = callout_field_mapper[mapping.maps_to].options;
          const option = options && options.find(o => o.value === field.value);
          if (option) field.value = option.id;
          else return resource;
        }

        const custom_field_definition_id = parseInt(
          mapping.maps_to.substr(
            mapping.maps_to.indexOf('_') + 1,
            mapping.maps_to.length
          )
        );

        if (mapping.type === 'MEASUREMENT_NEW') {
          const value = field.value;
          return {
            ...resource,
            custom_fields: (resource.custom_fields || []).concat({
              float_value: unitFieldUtils.getImperialValueFromUnitValue(value),
              string_value: value.unit,
              custom_field_definition_id
            })
          };
        }

        const custom = customHelper.getCustomObject(field.value, mapping.type);
        return {
          ...resource,
          custom_fields: (resource.custom_fields || []).concat({
            ...custom,
            custom_field_definition_id
          })
        };
      }

      return resource;
    },
    { applicants: [{ id: applicant.id }], resource_type_id }
  );
};
