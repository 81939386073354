import { CustomFieldType } from '__types__/v1';

export const DATA_TYPE_FIELDS = {
  [CustomFieldType.SHORT_TEXT]: 'string_value',
  [CustomFieldType.LONG_TEXT]: 'string_value',
  [CustomFieldType.URL]: 'string_value',
  [CustomFieldType.NUMBER]: 'integer_value',
  [CustomFieldType.PERCENTAGE]: 'float_value',
  [CustomFieldType.BOOLEAN]: 'boolean_value',
  [CustomFieldType.DATE]: 'date_value',
  [CustomFieldType.DATE_RANGE]: 'string_value',
  [CustomFieldType.SINGLE_SELECT]: 'option_id',
  [CustomFieldType.MULTI_SELECT]: 'multi_select_ids',
  [CustomFieldType.CURRENCY]: [
    { custom_key: 'integer_value', value_key: 'amount' },
    { custom_key: 'string_value', value_key: 'currency' }
  ],
  [CustomFieldType.MEASUREMENT]: [
    { custom_key: 'integer_value', value_key: 'amount' },
    { custom_key: 'string_value', value_key: 'unit' }
  ],
  [CustomFieldType.MEASUREMENT_NEW]: [
    { custom_key: 'float_value', value_key: 'amount' },
    { custom_key: 'string_value', value_key: 'unit' }
  ],
  [CustomFieldType.HEIGHT]: 'integer_value',
  [CustomFieldType.DIMENSION_2D]: 'string_value',
  [CustomFieldType.DIMENSION_3D]: 'string_value',
  [CustomFieldType.NETSUITE_MASTER_PROJECT]: 'integer_value',
  [CustomFieldType.NETSUITE_SUBCLASS]: 'integer_value',
  [CustomFieldType.NETSUITE_PO]: 'string_value',
  [CustomFieldType.NETSUITE_VENDOR]: 'string_value'
};

/**
Examples

Input:  ({ string_value: "Hello" }, "TEXT")
Output:  "Hello"

Input: ({ integer_value: 100, string_value: "USD" }, "CURRENCY")
Output: { amount: 100, currency: "USD" }
**/
const getValue = (custom, data_type) => {
  const field_key = DATA_TYPE_FIELDS[data_type];
  if (Array.isArray(field_key)) {
    return field_key.reduce((result, f) => {
      return { ...result, [f.value_key]: custom[f.custom_key] };
    }, {});
  }
  return custom[field_key];
};

/**
Examples

Input:  ("Hello", "TEXT_TYPE")
Output:  { string_value: "Hello" }

Input: ({ amount: 100, currency: "USD" }, "CURRENCY")
Output: { integer_value: 100, string_value: "USD" }
**/
const getCustomObject = (value, data_type) => {
  const field_key = DATA_TYPE_FIELDS[data_type];
  if (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    data_type !== 'NUMBER' // @todo: band-aid fix sometimes value of number = null which means it incorrectly passes this conditional
  ) {
    return field_key.reduce((result, f) => {
      return { ...result, [f.custom_key]: value[f.value_key] };
    }, {});
  }
  return { [field_key]: value };
};

export default { getValue, getCustomObject };
