import { lazy } from 'react';

const BOOLEAN = lazy(() => import('./CustomFields/BOOLEAN'));
const CURRENCY = lazy(() => import('./CustomFields/CURRENCY'));
const DATE = lazy(() => import('./CustomFields/DATE'));
const DATE_RANGE = lazy(() => import('./CustomFields/DATE_RANGE'));
const DIMENSION_2D = lazy(() => import('./CustomFields/DIMENSION_2D'));
const DIMENSION_3D = lazy(() => import('./CustomFields/DIMENSION_3D'));
const HEIGHT = lazy(() => import('./CustomFields/HEIGHT'));
const LONG_TEXT = lazy(() => import('./CustomFields/LONG_TEXT'));
const MEASUREMENT = lazy(() => import('./CustomFields/MEASUREMENT'));
const MULTI_SELECT = lazy(() => import('./CustomFields/MULTI_SELECT'));
const NUMBER = lazy(() => import('./CustomFields/NUMBER'));
const MEASUREMENT_NEW = lazy(() => import('./CustomFields/MEASUREMENT_NEW'));
const PERCENTAGE = lazy(() => import('./CustomFields/PERCENTAGE'));
const SHORT_TEXT = lazy(() => import('./CustomFields/SHORT_TEXT'));
const SINGLE_SELECT = lazy(() => import('./CustomFields/SINGLE_SELECT'));
const URL = lazy(() => import('./CustomFields/URL'));
const NETSUITE_MASTER_PROJECT = lazy(
  () => import('./CustomFields/SplashlightNetsuiteMasterProjectSelector')
);

export default {
  BOOLEAN,
  CURRENCY,
  DATE,
  DATE_RANGE,
  DIMENSION_2D,
  DIMENSION_3D,
  HEIGHT,
  LONG_TEXT,
  MEASUREMENT,
  MEASUREMENT_NEW,
  MULTI_SELECT,
  NUMBER,
  PERCENTAGE,
  SHORT_TEXT,
  SINGLE_SELECT,
  URL,
  NETSUITE_MASTER_PROJECT,
  NETSUITE_SUBCLASS: SINGLE_SELECT,
  NETSUITE_PO: SHORT_TEXT,
  NETSUITE_VENDOR: SHORT_TEXT
};
