import { FeatureFlag } from '__types__';
import { CustomFieldType } from '__types__/v1';
import { FeatureFlagEnum } from 'dtos/constants/feature-flag.enum';

type CustomFieldTypeData = {
  label: string;
  description: string;
  value: CustomFieldType;
  icon: string;
  featureFlag?: FeatureFlagEnum;
};

const CUSTOM_FIELD_TYPES: CustomFieldTypeData[] = [
  {
    label: 'Short Text',
    description: 'Good for short text values, like pet name or star sign',
    value: CustomFieldType.SHORT_TEXT,
    icon: '/images/icon_field_short_text.svg'
  },
  {
    label: 'Long Text',
    description: 'Good for long form writing like the meaning of life',
    value: CustomFieldType.LONG_TEXT,
    icon: '/images/icon_field_short_text.svg'
  },
  {
    label: 'Url',
    description: 'Good for clickable links and URL formatting',
    value: CustomFieldType.URL,
    icon: '/images/icon_field_url.svg'
  },
  {
    label: 'Number',
    description: 'Restricts input to whole numbers only',
    value: CustomFieldType.NUMBER,
    icon: '/images/icon_field_number.svg'
  },
  {
    label: 'Percentage',
    description: 'Allow percentages and decimals in this input',
    value: CustomFieldType.PERCENTAGE,
    icon: '/images/icon_field_percentage.svg'
  },
  {
    label: 'Boolean (Yes / No)',
    description: 'Checkbox to store simple yes / no data',
    value: CustomFieldType.BOOLEAN,
    icon: '/images/icon_field_boolean.svg'
  },
  {
    label: 'Date',
    description: 'Select dates within a calendar',
    value: CustomFieldType.DATE,
    icon: '/images/icon_field_date.svg'
  },
  {
    label: 'Date Range',
    description: 'Select a start and end date from a calendar',
    value: CustomFieldType.DATE_RANGE,
    icon: '/images/icon_field_date.svg'
  },
  {
    label: 'Dropdown (Single Choice)',
    description: "Good for one choice of defined values like 'Hair Colour'",
    value: CustomFieldType.SINGLE_SELECT,
    icon: '/images/icon_field_single_select.svg'
  },
  {
    label: 'Dropdown (Multiple Choice)',
    description: 'Good for multi selection of defined values',
    value: CustomFieldType.MULTI_SELECT,
    icon: '/images/icon_field_multi_select.svg'
  },
  {
    label: 'Currency',
    description: 'Good for storing currency, amount and unit',
    value: CustomFieldType.CURRENCY,
    icon: '/images/icon_field_currency.svg'
  },
  {
    label: 'Measurement (Deprecated)',
    description: 'A distance measurement',
    value: CustomFieldType.MEASUREMENT,
    icon: '/images/icon_field_measurement.svg'
  },
  {
    label: 'Measurement with unit',
    description: 'A distance measurement',
    value: CustomFieldType.MEASUREMENT_NEW,
    icon: '/images/icon_field_measurement.svg'
  },
  {
    label: '2D Dimension',
    description: 'Allows you to capture 2D measurements such as length x width',
    value: CustomFieldType.DIMENSION_2D,
    icon: '/images/icon_field_dimension_2d.svg'
  },
  {
    label: '3D Dimension',
    description:
      'Allows you to capture 3D measurements such as width x height x depth',
    value: CustomFieldType.DIMENSION_3D,
    icon: '/images/icon_field_dimension_3d.svg'
  },
  {
    label: 'Height',
    description: 'Allows you to capture something or someones height',
    value: CustomFieldType.HEIGHT,
    icon: '/images/icon_field_height.svg'
  },
  {
    label: 'NetSuite Master Project',
    description: 'Allows you to capture a Netsuite Master Project',
    value: CustomFieldType.NETSUITE_MASTER_PROJECT,
    icon: '/images/icon_field_dimension_3d.svg',
    featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
  },
  {
    label: 'NetSuite Project Subclass',
    description: 'Allows you to capture a Netsuite Project subclass',
    value: CustomFieldType.NETSUITE_SUBCLASS,
    icon: '/images/icon_field_dimension_3d.svg',
    featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
  },
  {
    label: 'NetSuite Purchase Order',
    description: 'Allows you to capture a Netsuite Purchase Order number',
    value: CustomFieldType.NETSUITE_PO,
    icon: '/images/icon_field_dimension_3d.svg',
    featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
  },
  {
    label: 'NetSuite Vendor',
    description: 'Allows you to capture a Netsuite Vendor ID',
    value: CustomFieldType.NETSUITE_VENDOR,
    icon: '/images/icon_field_dimension_3d.svg',
    featureFlag: FeatureFlag.CUSTOM_SPLASHLIGHT
  }
];

export default CUSTOM_FIELD_TYPES;
