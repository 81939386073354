import { Command } from 'lucide-react';
import { getSystem } from '../platform/dom/system';

export function ModifierKey() {
  return getSystem() === 'win' ? <span>Ctrl</span> : <Command />;
}

export function ControlKey() {
  return getSystem() === 'win' ? <span>Ctrl</span> : <span>^</span>;
}
