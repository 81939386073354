import i18n from 'i18next';
import { get } from 'lodash';
import { initReactI18next } from 'react-i18next';
import store from '../store';
import en_shared from '../v1/components/shared/i18n/en.json';

import pl_shared from '../v1/components/shared/i18n/pl.json';
import zh_shared from '../v1/components/shared/i18n/zh.json';
import { myV1ActionEvent } from '../v4/migration/actionAsEventMiddleware.connected';
import en_v4_shared from '../v4/shared/i18n/en.json';

import pl_v4_shared from '../v4/shared/i18n/pl.json';
import zh_v4_shared from '../v4/shared/i18n/zh.json';
import en_callouts from './features/callouts/i18n/en.json';

import pl_callouts from './features/callouts/i18n/pl.json';
import zh_callouts from './features/callouts/i18n/zh.json';
import en_callsheets from './features/callsheets/i18n/en.json';

import pl_callsheets from './features/callsheets/i18n/pl.json';
import zh_callsheets from './features/callsheets/i18n/zh.json';
import en_core from './features/core/i18n/en.json';

import pl_core from './features/core/i18n/pl.json';
import zh_core from './features/core/i18n/zh.json';
import en_productions from './features/productions/i18n/en.json';

import pl_productions from './features/productions/i18n/pl.json';
import zh_productions from './features/productions/i18n/zh.json';
import en_resource_portal from './features/resource-portal/i18n/en.json';

import pl_resource_portal from './features/resource-portal/i18n/pl.json';
import zh_resource_portal from './features/resource-portal/i18n/zh.json';
import en_shortlists from './features/shortlists/i18n/en.json';

import pl_shortlists from './features/shortlists/i18n/pl.json';
import zh_shortlists from './features/shortlists/i18n/zh.json';
import { initializeMoment } from './i18n.moment';

// Define language resources
export const resources = {
  en: {
    shortlists: en_shortlists,
    core: en_core,
    v1_shared: en_shared,
    v4_shared: en_v4_shared,
    'resource-portal': en_resource_portal,
    callouts: en_callouts,
    callsheets: en_callsheets,
    productions: en_productions
  },
  pl: {
    shortlists: pl_shortlists,
    core: pl_core,
    v1_shared: pl_shared,
    v4_shared: pl_v4_shared,
    'resource-portal': pl_resource_portal,
    callouts: pl_callouts,
    callsheets: pl_callsheets,
    productions: pl_productions
  },
  zh: {
    shortlists: zh_shortlists,
    core: zh_core,
    v1_shared: zh_shared,
    v4_shared: zh_v4_shared,
    'resource-portal': zh_resource_portal,
    callouts: zh_callouts,
    callsheets: zh_callsheets,
    productions: zh_productions
  }
} as const;

// Retrieve the server language setting
const getServerLanguage = () =>
  get(store.getState(), 'auth.settings.settings.defaults_locale.setting');

// Retrieve the user's preferred language
const getLanguage = (): string => {
  const serverLanguage = getServerLanguage();
  const localStorageLanguage =
    typeof localStorage !== 'undefined'
      ? localStorage.getItem('atl.language')
      : null;
  return localStorageLanguage || serverLanguage || 'en';
};

const initialLanguage = getLanguage();
initializeMoment(initialLanguage);

// Handle language change on authentication success event so server language can be taken into account
myV1ActionEvent.addListener(async event => {
  if (event.type === 'LOAD_AUTH_SUCCESS') {
    await Promise.resolve(); // Wait for store update before applying the language change
    void i18n.changeLanguage(getLanguage());
  }
});

// Initialize i18n with necessary configurations
i18n.use(initReactI18next).init({
  ns: ['shortlists'],
  resources,
  lng: initialLanguage,
  fallbackLng: 'en'
});
