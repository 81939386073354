import i18n from 'i18next';
import moment from 'moment-timezone';
import 'moment/dist/locale/pl';
import 'moment/dist/locale/zh-cn';

export function initializeMoment(initialLanguage: string) {
  moment.locale(initialLanguage);

  i18n.on('languageChanged', lng => {
    moment.locale(lng);
  });
}
