import { cva, VariantProps } from 'class-variance-authority';
import * as React from 'react';
import { cn } from 'v5/platform/dom/cn';
import { findFocusableElement } from '../platform/dom/isFocusable';
import { focusRings } from './_common/focusRing';

export const inputGroupVariants = cva(
  [
    'group',
    'cursor-text',
    'flex w-full',
    'file:border-0 file:bg-transparent file:text-base file:font-medium file:text-fg-default',
    'placeholder:text-fg-muted',
    'disabled:cursor-not-allowed disabled:opacity-50',
    '[&_svg]:w-4 [&_svg]:h-4 [&_svg]',
    '[&_[data-element=input-group-slot]]:px-3'
  ],
  {
    variants: {
      size: {
        md: 'text-base h-11 py-2 ',
        sm: 'text-base h-9 py-1.5'
      },
      variant: {
        regular: [
          'rounded',
          'border border-border-default bg-bg-default text-fg-default',
          '[&_input]:first:pl-2 [&_input]:last:pr-2',
          focusRings.focusWithin.visible
        ],
        inline: [
          'transition-all',
          'bg-bg-default text-fg-default',
          'focus-within:shadow-[inset_0_-2px_0_0_]',
          'shadow-[inset_0_-1px_0_0_]',
          'shadow-border-default',
          'hover:shadow-bg-inset',
          'has-focus:shadow-brand-300',
          'hover:focus-within:shadow-brand-400'
        ]
      }
    },
    defaultVariants: {
      variant: 'regular',
      size: 'md'
    }
  }
);

export type InputGroupProps = React.PropsWithChildren<
  VariantProps<typeof inputGroupVariants> & {
    leftSlot?: React.ReactNode;
    rightSlot?: React.ReactNode;
    className?: string;
  }
>;

export const InputGroup = React.forwardRef<HTMLDivElement, InputGroupProps>(
  (
    { leftSlot, rightSlot, children, className, variant, size, ...props },
    ref
  ) => {
    return (
      <div
        className={cn(inputGroupVariants({ variant, size }), className)}
        ref={ref}
        onMouseDownCapture={e => {
          if (!(e.target instanceof HTMLInputElement)) {
            // we want to simulate the behavior of a native input element focus, we just delegate the focus to the first focusable element
            findFocusableElement(e.currentTarget)?.focus();
            e.stopPropagation();
            e.preventDefault();
          }
        }}
        {...props}
      >
        {leftSlot}
        {children}
        {rightSlot}
      </div>
    );
  }
);
InputGroup.displayName = 'InputGroup';

export type InputGroupInputProps = React.ComponentProps<'input'> & {
  highlightOnFocus?: boolean;
};

export const InputGroupInput = React.forwardRef<
  HTMLInputElement,
  InputGroupInputProps
>(({ className, onFocus, highlightOnFocus, ...props }, ref) => {
  return (
    <input
      data-element="input-group-input"
      className={cn(
        'w-full border-none bg-transparent outline-none',
        className
      )}
      ref={ref}
      {...props}
      onFocus={e => {
        if (highlightOnFocus) {
          e.target.select();
        }
        onFocus?.(e);
      }}
    />
  );
});
InputGroupInput.displayName = 'InputGroupInput';

export const InputGroupSlot = React.forwardRef<
  HTMLDivElement,
  React.ComponentProps<'div'>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      data-element="input-group-slot"
      className={cn('flex items-center', className)}
      ref={ref}
      {...props}
    >
      {children}
    </div>
  );
});
