import { Maybe, maybe, rd } from '@passionware/monads';
import { useSimpleEventSubscription } from '@passionware/simple-event';
import { CommandLoading } from 'cmdk';
import { groupBy } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import {
  CommandDialog,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
  CommandSeparator,
  CommandShortcut
} from 'v5/design-sytem/Command';
import { cn } from 'v5/platform/dom/cn';
import { Icon } from '../../../shared';
import { selectAppId } from '../../../store/v1/auth/auth.selectors';
import { openModal } from '../../../store/v1/ui/ui.actions';
import SYSTEM_COLOURS from '../../../v1/helpers/consts/SYSTEM_COLOURS';
import { withinApp } from '../../../v4/core/appRoutes';
import Avatar from '../../../v4/shared/components/Avatar/Avatar';
import { GlobalSearchEntry } from '../../api/global-search/global-search.api';
import { Kbd } from '../../design-sytem/Kbd';
import { LoadingSpinner } from '../../design-sytem/LoadingSpinner';
import { ControlKey } from '../../primitives/ModifierKey';
import { GlobalSearchService } from '../../services/GlobalSearchService/GlobalSearchService';

export interface CommandBarWidgetProps {
  services: {
    globalSearchService: GlobalSearchService;
  };
}

// A helper component to register a hotkey without rendering anything.
function Hotkey({ keys, onKey }: { keys: string; onKey: () => void }) {
  useHotkeys(
    keys,
    event => {
      event.preventDefault();
      onKey();
    },
    { enableOnFormTags: true }
  );
  return null;
}

export function CommandBarWidget(props: CommandBarWidgetProps) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [debouncedSearch, debounced] = useDebounce(search, 200);

  const recents = props.services.globalSearchService.useRecentSearches();
  const rawSearchResults = props.services.globalSearchService.useSearch(
    maybe.fromTruthy(search === '' ? search : debouncedSearch)
  );
  const _searchResults = rd.useLastWithPlaceholder(rawSearchResults);
  const searchResults = rd.isIdle(rawSearchResults)
    ? rawSearchResults
    : _searchResults;

  const resultsByGroup = rd.map(searchResults, results =>
    Object.entries(groupBy(results, 'group')).map(([group, results]) => ({
      group,
      results
    }))
  );
  const groupOrder = [
    'production',
    'callout',
    'contact',
    'callsheet',
    'shortlist'
  ];
  const labelByGroup = {
    production: 'Productions',
    callout: 'Callouts',
    contact: 'Contacts',
    callsheet: 'Callsheets',
    shortlist: 'Shortlists'
  };

  const sortedResultsByGroup = rd.map(resultsByGroup, groups =>
    groups.sort(
      (a, b) => groupOrder.indexOf(a.group) - groupOrder.indexOf(b.group)
    )
  );

  useEffect(() => {
    if (open) {
      debounced.flush();
      debounced.cancel();
      setSearch('');
    }
  }, [open]);

  useSimpleEventSubscription(
    props.services.globalSearchService.openSearchEvent.addListener,
    () => {
      setOpen(true);
    }
  );

  const dispatch = useDispatch();
  const commandListRef = useRef<HTMLDivElement>(null);
  rd.useEffect(sortedResultsByGroup, data => {
    if ((rd.isIdle(data) || rd.isSuccess(data)) && commandListRef.current) {
      commandListRef.current.scrollTo({
        top: 0
      });
    }
  });

  useHotkeys(`mod+k`, () => setOpen(prev => !prev), {
    enableOnFormTags: true
  });

  // Define the static "Create" items in an array.
  const createItems = [
    {
      id: 'resource',
      label: 'Create resource',
      icon: <Icon name="v4_individual" />,
      shortcut: (
        <Kbd>
          <ControlKey />R
        </Kbd>
      ),
      combination: `ctrl+r`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('ResourceCreateModal'));
      }
    },
    {
      id: 'production',
      label: 'Create production',
      icon: <Icon name="v4_project" />,
      shortcut: (
        <Kbd>
          <ControlKey />P
        </Kbd>
      ),
      combination: `ctrl+p`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('ProductionSetUpModal', { create: true }));
      }
    },
    {
      id: 'shortlist',
      label: 'Create shortlist',
      icon: <Icon name="v4_shortlist" />,
      shortcut: (
        <Kbd>
          <ControlKey />S
        </Kbd>
      ),
      combination: `ctrl+s`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('ShortlistCreateModal', { create: true }));
      }
    },
    {
      id: 'booking',
      label: 'Create booking',
      icon: <Icon name="v4_booking" />,
      shortcut: (
        <Kbd>
          <ControlKey />B
        </Kbd>
      ),
      combination: `ctrl+b`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('ResourceEventsModal'));
      }
    },
    {
      id: 'callsheet',
      label: 'Create callsheet',
      icon: <Icon name="v4_callsheet" />,
      shortcut: (
        <Kbd>
          <ControlKey />C
        </Kbd>
      ),
      combination: `ctrl+c`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('CallsheetCreateModal'));
      }
    },
    {
      id: 'callout',
      label: 'Create callout',
      icon: <Icon name="v4_callout" />,
      shortcut: (
        <Kbd>
          <ControlKey />O
        </Kbd>
      ),
      combination: `ctrl+o`,
      onSelect: () => {
        setOpen(false);
        dispatch(openModal('CalloutCreateModal', { create: true }));
      }
    }
  ];

  // Register hotkeys for each "Create" item.
  // These hotkeys will work regardless of the command dialog being open.
  const createHotkeys = createItems.map(item => (
    <Hotkey key={item.id} keys={item.combination} onKey={item.onSelect} />
  ));

  return (
    <>
      {createHotkeys}
      <CommandDialog open={open} onOpenChange={setOpen}>
        <CommandInput
          placeholder="Search or create something..."
          value={search}
          onValueChange={setSearch}
          className="pr-10"
          extra={
            <div
              className={cn(
                'transition-opacity duration-200 opacity-0 delay-100',
                {
                  'opacity-100': rd.isFetching(rawSearchResults)
                }
              )}
            >
              <LoadingSpinner
                type="spin"
                color={SYSTEM_COLOURS.BLACK}
                height={15}
                width={15}
              />
            </div>
          }
        />
        <CommandList ref={commandListRef}>
          <CommandEmpty>No results found.</CommandEmpty>
          {rd
            .fullJourney(sortedResultsByGroup)
            .initially(
              <>
                {recents.length > 0 && (
                  <CommandGroup heading="Recent">
                    {recents.map(result => (
                      <EntryCommandItem
                        key={result.id}
                        entry={result}
                        group={result.group}
                        services={props.services}
                        onSelect={() => setOpen(false)}
                      />
                    ))}
                  </CommandGroup>
                )}
                <CommandGroup heading="Create">
                  {createItems.map(item => (
                    <CommandItem key={item.id} onSelect={item.onSelect}>
                      <div className="w-10 h-10 flex flex-col justify-center items-center">
                        {item.icon}
                      </div>
                      <span>{item.label}</span>
                      <CommandShortcut className="[&_kbd]:bg-brand-500/20">
                        {item.shortcut}
                      </CommandShortcut>
                    </CommandItem>
                  ))}
                </CommandGroup>
              </>
            )
            .wait(<CommandLoading />)
            .catch(() => <CommandEmpty>Error searching</CommandEmpty>)
            .map(groups => (
              <>
                {groups.map(group => (
                  <CommandGroup
                    key={group.group}
                    heading={labelByGroup[group.group] ?? group.group}
                  >
                    {group.results.map(result => (
                      <EntryCommandItem
                        key={result.id}
                        entry={result}
                        group={group.group}
                        services={props.services}
                        onSelect={() => setOpen(false)}
                      />
                    ))}
                  </CommandGroup>
                ))}
                <CommandSeparator />
              </>
            ))}
        </CommandList>
      </CommandDialog>
    </>
  );
}

const iconByGroup = {
  production: <Icon name="v4_project" />,
  callout: <Icon name="v4_callout" />,
  contact: <Icon name="v4_individual" />,
  callsheet: <Icon name="v4_callsheet" />,
  shortlist: <Icon name="v4_shortlist" />
};

function EntryCommandItem(props: {
  entry: GlobalSearchEntry;
  group: Maybe<string>;
  services: {
    globalSearchService: GlobalSearchService;
  };
  onSelect: () => void;
}) {
  const { entry: result } = props;
  const { avatar } = result;
  const appId = useSelector(selectAppId);
  const history = useHistory();
  const to = withinApp(appId);

  return (
    <CommandItem
      key={`${result.group}.${result.id}`}
      value={`${result.group}.${result.id}`}
      onSelect={() => {
        props.services.globalSearchService.storeRecentSearchItem(result);
        props.onSelect();
        history.push(`${to.teamRoot()}${result.url}`);
      }}
    >
      {maybe.map(true, () => {
        if (!avatar?.url && !avatar?.ascii && !avatar?.fallback) {
          return (
            <div className="w-10 h-10 flex flex-col justify-center items-center">
              {iconByGroup[props.group ?? '']}
            </div>
          );
        }
        return (
          <Avatar
            src={avatar.url}
            fallback={avatar.fallback ?? avatar.ascii}
            className={cn('', avatar.ascii ? '[&_*]:text-3xl' : 'bg-bg-inset ')}
          />
        );
      })}
      <div className="flex flex-col flex-1">
        <span>{result.name}</span>
        <span className="text-xs">{result.description}</span>
        {maybe.map(result.badge, badge => (
          <div className="self-start text-sm bg-bg-subtle border border-border-default rounded-md px-1 mt-1">
            {badge.text}
          </div>
        ))}
      </div>
    </CommandItem>
  );
}
