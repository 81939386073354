import {
  BaseModelV1,
  Archivable,
  Orderable,
  Ownable,
  Timestamped,
  Mechanism
} from './baseTypes';

export enum CustomFieldType {
  SHORT_TEXT = 'SHORT_TEXT',
  LONG_TEXT = 'LONG_TEXT',
  URL = 'URL',
  NUMBER = 'NUMBER',
  PERCENTAGE = 'PERCENTAGE',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  DATE_RANGE = 'DATE_RANGE',
  CURRENCY = 'CURRENCY',
  MEASUREMENT = 'MEASUREMENT',
  MEASUREMENT_NEW = 'MEASUREMENT_NEW',
  DIMENSION_2D = 'DIMENSION_2D',
  DIMENSION_3D = 'DIMENSION_3D',
  HEIGHT = 'HEIGHT',
  SINGLE_SELECT = 'SINGLE_SELECT',
  MULTI_SELECT = 'MULTI_SELECT',
  NETSUITE_MASTER_PROJECT = 'NETSUITE_MASTER_PROJECT',
  NETSUITE_PO = 'NETSUITE_PO',
  NETSUITE_VENDOR = 'NETSUITE_VENDOR',
  NETSUITE_SUBCLASS = 'NETSUITE_SUBCLASS'
}

type CustomFieldDefinitionOption = {
  custom_field_definition_id: EntityId;
  value: string;
  id: EntityId;
};

export type CustomFieldDefinition = BaseModelV1 &
  Archivable &
  Orderable &
  Ownable &
  Timestamped &
  Mechanism & {
    entity_type: EntityId | null;
    name: string;
    data_type: CustomFieldType;
    filterable: boolean;
    required: boolean;
    multi_select_fixed: boolean;
    measurement_unit_lock: boolean;
    measurement_default_unit: string | null;
    options: CustomFieldDefinitionOption[];
  };
