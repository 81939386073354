import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { get, keys, isEmpty, uniq } from 'lodash';

import { getBatchBookings } from 'store/v1/bookings/bookings.actions.js';
import { getBookingExpenses } from 'store/v1/expenses/expenses.actions.js';
import { PRODUCTIONS_ACTIONS } from 'store/v1/productions/productions.constants.js';
import useEvent from 'v1/helpers/hooks/useEvent';
import { getBatchContacts } from 'store/v1/contacts/contacts.actions';

const OPTIONS = {
  FETCH_BOOKINGS: true,
  FETCH_CONTACTS: false,
  FETCH_BOOKING_EXPENSES: false,
  FETCH_ON_MOUNT: false,
  PRODUCTION_IDS: null
};

// Listen to redux production changes and fetches  associated data
function useProductionRelations({
  fetchBookings = OPTIONS.FETCH_BOOKINGS, // Should fetch bookings?
  fetchContacts = OPTIONS.FETCH_CONTACTS, // Should fetch bookings?
  fetchBookingExpenses = OPTIONS.FETCH_BOOKING_EXPENSES, // Should fetch booking expenses?
  fetchOnMount = OPTIONS.FETCH_ON_MOUNT, // Should fetch associations on mount or after api call?
  productionIds = OPTIONS.PRODUCTION_IDS // Productions ids to monitor, if null all productions in reducer are used
} = OPTIONS) {
  const productions = useSelector(state => state.productions);
  const bookings = useSelector(state => state.bookings);
  const contacts = useSelector(state => state.contacts);
  const dispatch = useDispatch();

  const getProductionsPayload = useSelector(
    state => state.eventPayload[PRODUCTIONS_ACTIONS.GET_PRODUCTIONS]
  );

  useEffect(() => {
    if (fetchOnMount) {
      callProductionRelationsIds();
    }
  }, []);

  useEvent(
    [
      PRODUCTIONS_ACTIONS.GET_PRODUCTION,
      PRODUCTIONS_ACTIONS.UPDATE_PRODUCTION,
      PRODUCTIONS_ACTIONS.CREATE_PRODUCTION
    ],
    {
      onSuccess: () => {
        callProductionRelationsIds();
      }
    }
  );

  useEvent([PRODUCTIONS_ACTIONS.GET_PRODUCTIONS], {
    onSuccess: () => {
      callProductionRelationsIds(
        getProductionsPayload.result.results.map(x => x.id)
      );
    }
  });

  const callProductionRelationsIds = () => {
    const { booking_ids, contact_ids } = getProductionsRelationsIds();
    fetchRelations(booking_ids, contact_ids);
  };

  const fetchRelations = (booking_ids = [], contact_ids = []) => {
    if (fetchBookings && !isEmpty(booking_ids)) {
      dispatch(getBatchBookings(uniq(booking_ids), true));
    }
    if (fetchContacts && !isEmpty(contact_ids)) {
      dispatch(getBatchContacts(uniq(contact_ids)));
    }
    if (fetchBookingExpenses && !isEmpty(booking_ids)) {
      dispatch(getBookingExpenses(uniq(booking_ids)));
    }
  };

  const getProductionsRelationsIds = (productionsToProcess = productions) => {
    return keys(productionsToProcess.data).reduce(
      (result, id) => {
        if (
          Array.isArray(productionIds) &&
          !productionIds.includes(parseInt(id))
        )
          return result;

        const { booking_ids, contact_ids } =
          getSingleProductionRelationsIds(id);
        return {
          booking_ids: result.booking_ids.concat(booking_ids),
          contact_ids: result.contact_ids.concat(contact_ids)
        };
      },
      { booking_ids: [], contact_ids: [] }
    );
  };

  const getSingleProductionRelationsIds = id => {
    const assignments = get(
      productions,
      ['data', id, 'resource_slots'],
      []
    ).reduce(
      (result, slot) => result.concat(slot.resource_slot_assignments),
      []
    );
    return assignments.reduce(
      (result, assignment) => {
        const { booking_id, contact_id } = assignment;
        return {
          booking_ids: result.booking_ids.concat(
            booking_id && (!bookings[booking_id] || fetchBookingExpenses)
              ? booking_id
              : []
          ),
          contact_ids: result.contact_ids.concat(
            !contacts.data[contact_id] && contact_id ? contact_id : []
          )
        };
      },
      { booking_ids: [], contact_ids: [] }
    );
  };
}

export default useProductionRelations;
